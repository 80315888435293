import styled, { keyframes } from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const LoadingContainer = styled(Flex)`
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
		131deg,
		#bed3ff 5.15%,
		#fafafa 54.57%,
		#f1ffc7 123.63%
	);
`;

const rippleAnimation = keyframes`
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
`;

export const RippleRing = styled.div<{
	$rippledelay: string;
	size: number;
	$visible: string;
}>`
	position: absolute;
	top: 50%;
	left: 50%;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	opacity: ${(props) => (props.$visible === "true" ? 1 : 0)};
	transition: opacity 0.3s ease-in-out;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		border: 2px solid transparent;
		background: linear-gradient(180deg, #bed3ff 0%, #f1ffc7 100%) border-box;
		-webkit-mask:
			linear-gradient(#fff 0 0) padding-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
		animation: ${rippleAnimation} 5s infinite ease-out;
		animation-delay: ${(props) => props.$rippledelay};
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	}
`;

export const LogoContainer = styled.div`
	position: relative;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 32px;
		height: 32px;
		object-fit: contain;
		z-index: 1;
		filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
	}
`;
