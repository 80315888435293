import React, { useEffect, useState, useRef } from "react";
import {
	StyledBox,
	StyledTextField,
	PastSearchesContainer,
	PastSearchItem,
	SearchQueryText,
	ResultCount,
} from "./styled";
import { Icon, TextField, IconButton, Separator } from "@mightybot/web-ui";
import { useNavigate } from "react-router-dom";
import {
	Search,
	useSearch,
	useSearchPastSearches,
	SearchFilters as SearchFiltersType,
} from "@mightybot/core";
import debounce from "lodash/debounce";

interface PlugNPlaySearchBarProps {
	presetFilters?: SearchFiltersType;
	placeholder?: string;
}

const PlugNPlaySearchBar: React.FC<PlugNPlaySearchBarProps> = ({
	presetFilters,
	placeholder = "Search Files, Docs, Emails, Slack, Meetings, Linear",
}) => {
	const navigate = useNavigate();
	const { createSearch } = useSearch(null);
	const [searchQuery, setSearchQuery] = useState("");
	const { pastSearches, searchPastSearches } = useSearchPastSearches();
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);

	const debouncedSearch = useRef(
		debounce((query: string) => {
			searchPastSearches(query);
		}, 300),
	).current;

	const searchContainerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (searchQuery) {
			debouncedSearch(searchQuery);
		}
	}, [searchQuery, debouncedSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(event.target as Node)
			) {
				setIsResultsVisible(false);
				setIsFocused(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSearch = async (query: string) => {
		try {
			const response = await createSearch({
				query,
				source: "web",
				filters: presetFilters,
			});

			if (response?.id) {
				navigate(`/search?searchId=${response.id}&q=${query}`);
			}
		} catch (error) {
			console.error("Error creating search:", error);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsResultsVisible(true);
		setSearchQuery(e.target.value);
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && pastSearches && pastSearches[selectedIndex]) {
				await handleSearch(pastSearches[selectedIndex].query);
			} else {
				await handleSearch(searchQuery);
			}
		} else if (e.key === "ArrowDown") {
			e.preventDefault();
			if (!isResultsVisible) {
				setIsResultsVisible(true);
				setIsFocused(true);
				if (!searchQuery) {
					searchPastSearches("");
				}
				setSelectedIndex(-1);
				return;
			}

			if (visiblePastSearches?.length) {
				setSelectedIndex((prev) =>
					prev < visiblePastSearches.length - 1 ? prev + 1 : prev,
				);
			}
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			if (isResultsVisible && visiblePastSearches?.length) {
				setSelectedIndex((prev) => (prev > -1 ? prev - 1 : -1));
				if (selectedIndex === 0) {
					setIsResultsVisible(false);
					setIsFocused(false);
				}
			}
		} else if (e.key === "Escape") {
			e.preventDefault();
			setIsResultsVisible(false);
			setIsFocused(false);
			setSelectedIndex(-1);
		}
	};

	const handlePastSearchClick = (pastSearch: Search) => {
		navigate(`/search?searchId=${pastSearch.id}&q=${pastSearch.query}`);
	};

	const handleFocus = () => {
		setIsFocused(true);
		if (!searchQuery) {
			searchPastSearches("");
		}
	};

	const handleClear = () => {
		setSearchQuery("");
		setIsResultsVisible(false);
		setSelectedIndex(-1);
	};

	const visiblePastSearches = pastSearches?.slice(0, 5) || [];

	const isPastSearchesVisible =
		(isFocused || isResultsVisible) &&
		visiblePastSearches &&
		visiblePastSearches.length > 0;

	return (
		<StyledBox width="100%" position="relative" ref={searchContainerRef}>
			<StyledTextField
				ref={inputRef}
				placeholder={placeholder}
				size="3"
				value={searchQuery}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				isexpanded={isPastSearchesVisible ? "true" : "false"}
				style={{
					height: "80%",
				}}
			>
				<TextField.Slot>
					<Icon.MagnifyingGlass width="16" height="16" />
				</TextField.Slot>
				<TextField.Slot pr="3">
					{searchQuery && (
						<IconButton
							size="2"
							variant="ghost"
							onClick={handleClear}
							style={{ cursor: "pointer" }}
						>
							<Icon.X width="16" height="16" />
						</IconButton>
					)}
				</TextField.Slot>
			</StyledTextField>
			<PastSearchesContainer
				isvisible={isPastSearchesVisible ? "true" : "false"}
			>
				{isPastSearchesVisible && (
					<Separator
						style={{
							width: "calc(100% - 24px)",
							marginBottom: "10px",
							flexShrink: 0,
							alignSelf: "center",
						}}
					/>
				)}
				{isPastSearchesVisible &&
					visiblePastSearches.map((search, index) => (
						<PastSearchItem
							key={index}
							onClick={() => handlePastSearchClick(search)}
							isselected={index === selectedIndex ? "true" : "false"}
						>
							<SearchQueryText>
								<Icon.MagnifyingGlass width="16" height="16" />
								{search.query}
							</SearchQueryText>
							{search.results_count && (
								<ResultCount>
									{search.results_count ?? 0}{" "}
									{search.results_count === 1 ? "result" : "results"}
								</ResultCount>
							)}
						</PastSearchItem>
					))}
			</PastSearchesContainer>
		</StyledBox>
	);
};

export default PlugNPlaySearchBar;
