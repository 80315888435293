import React, { useState, useMemo, useEffect } from "react";
import {
	ChatReplyContainer,
	CopyIcon,
	RetryButton,
	customMarkdownStyles,
} from "./styled";
import {
	CustomReactMarkdown,
	Flex,
	Icon,
	Tooltip,
	Text,
} from "@mightybot/web-ui";
import { motion } from "framer-motion";

import {
	useBOHTaskLogs,
	BOHTaskLog,
	CHAT_BOH_KEY,
	useFeatureFlag,
} from "@mightybot/core";

type ChatReplyProps = {
	reply: string;
	isStreaming?: boolean;
	onRetry?: () => void;
	completionId?: string;
	streamingFailed?: boolean;
};

const ChatReply: React.FC<ChatReplyProps> = ({
	reply: streamingReply,
	isStreaming,
	onRetry,
	completionId,
	streamingFailed,
}) => {
	const [isCopied, setIsCopied] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [bohStarted, setBohStarted] = useState(
		localStorage.getItem("bohStarted") === "true",
	);
	const showChatBOH = useFeatureFlag(CHAT_BOH_KEY) ?? false;

	const { logs, refetch } = useBOHTaskLogs({
		completionLogId: completionId || "",
		enabled: showChatBOH && !!completionId && bohStarted,
		pollingInterval: 1000,
	});

	useEffect(() => {
		if (streamingReply?.endsWith("BOH_STARTED")) {
			localStorage.setItem("bohStarted", "true");
			setBohStarted(true);
		}
		if (completionId && bohStarted) {
			localStorage.setItem("bohStarted", "false");
		}
	}, [streamingReply, completionId]);

	useEffect(() => {
		const handleMessage = async (event: MessageEvent) => {
			if (event.data.type === "REFETCH_CHAT_DATA") {
				if (event.data.completionId === completionId) {
					await refetch();
				}
			}
		};

		navigator.serviceWorker?.addEventListener("message", handleMessage);

		return () => {
			navigator.serviceWorker?.removeEventListener("message", handleMessage);
		};
	}, [completionId, refetch]);

	const { chainOfThoughtLogs, replyLog } = useMemo(() => {
		return {
			chainOfThoughtLogs: logs.filter(
				(log: BOHTaskLog) => log.message_type === "CHAIN_OF_THOUGHT",
			),
			replyLog: logs.find((log: BOHTaskLog) => log.message_type === "REPLY"),
		};
	}, [logs]);

	const cleanedReply = useMemo(() => {
		if (!streamingReply) return "";
		return streamingReply.replaceAll("BOH_STARTED", "");
	}, [streamingReply]);

	const streamingContent = isStreaming ? `${cleanedReply}•` : cleanedReply;
	const displayContent = useMemo(() => {
		if (replyLog) {
			return replyLog.message;
		}
	}, [replyLog]);

	if ((!streamingReply && !isStreaming) || streamingFailed) {
		return (
			<ChatReplyContainer>
				<Flex align="center" gap="4px">
					<Text size="1">Failed to generate response.</Text>
					<Text
						size="1"
						onClick={onRetry}
						style={{
							cursor: "pointer",
							color: "#007bff",
							textDecoration: "underline",
							fontStyle: "italic",
						}}
					>
						Retry
					</Text>
				</Flex>
			</ChatReplyContainer>
		);
	}

	return (
		<ChatReplyContainer>
			<Flex direction="column" width="100%" gap="12px">
				<CustomReactMarkdown>{streamingContent}</CustomReactMarkdown>
				{/* Chain of Thought Messages */}
				{chainOfThoughtLogs.length > 0 && (
					<Flex
						direction="column"
						style={{
							borderRadius: "8px",
							background: "#FFF",
							boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.05)",
							width: "100%",
						}}
					>
						<Flex
							justify="between"
							style={{
								width: "100%",
								padding: "6px 8px",
								cursor: "pointer",
							}}
							align="center"
							onClick={() => setIsOpen(!isOpen)}
						>
							<Flex align="center" style={{ gap: "4px" }}>
								<Icon.CheckCircle weight="fill" size={22} color="#7DAB20" />
								<Text weight="medium">Plan Completed</Text>
							</Flex>
							{chainOfThoughtLogs.length > 0 && (
								<motion.div
									initial={{ rotate: 0 }}
									animate={{ rotate: isOpen ? 180 : 0 }}
									transition={{ duration: 0.3 }}
								>
									<Icon.CaretDown
										style={{ cursor: "pointer", marginTop: "2px" }}
									/>
								</motion.div>
							)}
						</Flex>

						<motion.div
							initial={{ height: 0, opacity: 0 }}
							animate={{
								height: isOpen ? "auto" : 0,
								opacity: isOpen ? 1 : 0,
							}}
							transition={{ duration: 0.3, opacity: { duration: 0.3 } }}
							style={{ overflow: "hidden", borderRadius: "6px" }}
						>
							<div
								style={{
									width: "100%",
									borderColor: "#E0E0E0",
									height: "1px",
									backgroundColor: "#E0E0E0",
									marginTop: "4px",
								}}
							/>
							{chainOfThoughtLogs?.map((message, index) => {
								if (!message.message) return null;
								return (
									<Flex direction="column">
										<Flex
											align="center"
											style={{ padding: "6px 8px", gap: "4px" }}
										>
											<div style={{ flexShrink: 0, height: "22px" }}>
												<Icon.CheckCircle
													weight="fill"
													size={22}
													color="#999999"
												/>
											</div>
											<Text>{message.message}</Text>
										</Flex>
										{index < chainOfThoughtLogs.length - 1 && (
											<div
												style={{
													width: "100%",
													borderColor: "#E0E0E0",
													height: "1px",
													backgroundColor: "#E0E0E0",
												}}
											/>
										)}
									</Flex>
								);
							})}
						</motion.div>
					</Flex>
				)}

				{/* Final Reply */}

				{displayContent && (
					<CustomReactMarkdown>{displayContent}</CustomReactMarkdown>
				)}

				<Flex align="center" justify="between">
					<Flex justify="center" align="center">
						<Tooltip content="Copy" side="top">
							{isCopied ? (
								<Icon.Check />
							) : (
								<CopyIcon
									onClick={() => {
										navigator.clipboard.writeText(
											streamingContent +
												logs.map((log) => log.message).join("\n"),
										);
										setIsCopied(true);
										setTimeout(() => {
											setIsCopied(false);
										}, 2000);
									}}
								/>
							)}
						</Tooltip>
					</Flex>
				</Flex>
			</Flex>
		</ChatReplyContainer>
	);
};

export default ChatReply;
