import React, { useState } from "react";
import {
	Text,
	Icon,
	Flex,
	Skeleton,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import {
	MeetingContentTitleInnerContainer,
	MeetingContentTitleOuterContainer,
} from "./styled";
import { useMeeting, useMeetingContent } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { formatDateTime, formatDuration } from "../../utils/helpers";
import GenerateDoc from "../GenerateDoc";
import ModelSelection from "../ModelSelection";
import Toast from "../Toast";
interface MeetingContentTitleProps {}

const MeetingContentTitle: React.FC<MeetingContentTitleProps> = ({}) => {
	const meetingId = useParams().meetingId;
	const { meeting, isLoading } = useMeeting(meetingId ?? "");
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const { meetingContent } = useMeetingContent(meetingId ?? "");
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error",
	});
	const duration = formatDuration(
		meeting?.start_time ?? "",
		meeting?.end_time ?? "",
	);
	const formattedDuration = duration.startsWith("0h ")
		? duration.slice(3)
		: duration;

	if (!meetingId) return null;

	const showToastMessage = (
		title: string,
		description: string,
		status: "success" | "error",
	) => {
		setToastData({
			title,
			description,
			status,
		});
		setOpenToast(true);
	};

	return (
		<>
			<MeetingContentTitleOuterContainer
				justify="between"
				align="center"
				gap="3"
				data-title={`${meetingId}-title`}
			>
				<MeetingContentTitleInnerContainer
					style={{ maxWidth: "870px", width: "100%" }}
				>
					<Flex align="center" gap="3" style={{ width: "100%" }}>
						{isLoading ? (
							<>
								<Skeleton width="300px" height="24px" />
								<Skeleton width="150px" height="16px" />
								<Skeleton width="100px" height="16px" />
							</>
						) : (
							<Flex
								align={isMobile ? "start" : "center"}
								gap="15px"
								style={{ width: "100%", flexWrap: "wrap" }}
								direction={isMobile ? "column" : "row"}
							>
								<Text
									weight="medium"
									size="4"
									style={{ flexShrink: 0, paddingRight: "10px" }}
								>
									{meeting?.title}
								</Text>
								<Flex align="center" gap="3" style={{ width: "100%" }}>
									<Flex align="center" gap="1">
										<Icon.CalendarCheck />
										<Text weight="regular" size="1">
											{formatDateTime(meeting?.start_time ?? "")}
										</Text>
									</Flex>
									<Flex align="center" gap="1" style={{ marginLeft: "1vw" }}>
										<Icon.Clock />
										<Text weight="regular" size="1">
											{formattedDuration}
										</Text>
									</Flex>
									{isMobile && (
										<Flex
											align="center"
											style={{
												marginLeft: "auto",
												gap: "5px",
											}}
										>
											<GenerateDoc
												meetingContent={meetingContent ?? []}
												showToastMessage={showToastMessage}
											/>
											<ModelSelection />
										</Flex>
									)}
								</Flex>
							</Flex>
						)}
					</Flex>
					{!isMobile && (
						<Flex align="start" justify="end" gap="2" style={{ width: "100%" }}>
							<Flex align="center" gap="2">
								{isLoading ? (
									<Skeleton width="120px" height="32px" />
								) : (
									<Flex align="center" gap="5px">
										<GenerateDoc
											meetingContent={meetingContent ?? []}
											showToastMessage={showToastMessage}
										/>

										<ModelSelection />
									</Flex>
								)}
							</Flex>
						</Flex>
					)}
				</MeetingContentTitleInnerContainer>
			</MeetingContentTitleOuterContainer>

			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</>
	);
};

export default MeetingContentTitle;
