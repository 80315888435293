import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Routes, Route } from "react-router-dom";
import {
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import {
	SettingsContainer,
	SettingsContent,
	SettingsContentContainer,
	SettingsInnerContainer,
} from "./styled";
import SettingsSidebar from "./SettingsSidebar";
import Connect from "../Connect";
import UserNotification from "../UserNotification";
import {
	Text,
	useMediaQuery,
	breakpoints,
	Icon,
	Flex,
} from "@mightybot/web-ui";
import General from "../General";
import Billing from "../Billing/Billing";
import Teams from "../Teams";
import { useMe } from "@mightybot/core";
import DataPreferencesToggle from "./DataPreferencesToggle";
import MeetingsAndSummaries from "./MeetingsAndSummaries";
import AppsConfiguration from "./AppsConfiguration/AppsConfiguration";

const SettingsTab = {
	connect: "Connect",
	"user-notifications": "User Notifications",
	general: "General",
	billing: "Billing",
	teams: "Teams",
	privacy: "Privacy",
	"privacy/data-collection": "Data Collection",
	"privacy/contacts": "Contacts",
	"privacy/slack-channels": "Slack Channels",
	"privacy/google-drive": "Google Drive",
	meetings: "Meetings & Summaries",
};

const Settings = () => {
	const { "*": settingsTab } = useParams();
	const navigate = useNavigate();
	const { data: user } = useMe();
	const showMapleBilling = user?.role === "OWNER";
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [showContent, setShowContent] = useState(!isMobile);

	useEffect(() => {
		setShowContent(!isMobile);
	}, [isMobile]);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.SETTINGS_PAGE_VIEWED, {
			[ANALYTICS_PROPERTIES.SETTINGS_TAB]: settingsTab,
		});
	}, []);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.SETTINGS_TAB_CHANGED, {
			[ANALYTICS_PROPERTIES.SETTINGS_TAB]: settingsTab,
		});
		if (isMobile && settingsTab) {
			setShowContent(true);
		}
	}, [settingsTab]);

	const handleBack = () => {
		setShowContent(false);
		navigate("/settings");
	};

	const getTitle = () => {
		if (isMobile && settingsTab) {
			return SettingsTab[settingsTab as keyof typeof SettingsTab] || "Settings";
		}
		return "Settings";
	};

	return (
		<SettingsContainer>
			<SettingsInnerContainer>
				<Flex direction="row" align="center">
					{isMobile && settingsTab && (
						<Icon.CaretLeft size={24} onClick={handleBack} />
					)}
					<Text
						size="6"
						weight="medium"
						style={{
							padding: !isMobile ? "20px 0px 10px 0px" : "10px 0px 10px 10px",
						}}
					>
						{getTitle()}
					</Text>
				</Flex>
				<SettingsContentContainer>
					{(!isMobile || !showContent) && (
						<SettingsSidebar onSelect={() => setShowContent(true)} />
					)}
					{(!isMobile || showContent) && (
						<SettingsContent>
							<Routes>
								<Route path="connect" element={<Connect />} />
								<Route
									path="user-notifications"
									element={<UserNotification />}
								/>
								<Route path="general" element={<General />} />
								{showMapleBilling && (
									<Route path="billing" element={<Billing />} />
								)}
								<Route path="teams" element={<Teams />} />
								<Route path="privacy">
									<Route
										path="data-collection"
										element={<DataPreferencesToggle />}
									/>
								</Route>
								<Route path="meetings" element={<MeetingsAndSummaries />} />
								{user?.is_admin && (
									<Route
										path="apps-configuration"
										element={<AppsConfiguration />}
									/>
								)}
							</Routes>
						</SettingsContent>
					)}
				</SettingsContentContainer>
			</SettingsInnerContainer>
		</SettingsContainer>
	);
};

export default Settings;
