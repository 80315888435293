import styled from "styled-components";
import { Dialog } from "@radix-ui/themes";
import { Input, Flex, Icon, mediaQueries } from "@mightybot/web-ui";

export const JoinMeetingDialogRoot = styled(Dialog.Root)``;

export const JoinMeetingDialogContainer = styled(Dialog.Content)`
	background-color: white;
	border-radius: 6px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 600px;
	max-height: 85vh;
	padding: 30px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	z-index: 1001;
	border-radius: 20px;

	${mediaQueries.tablet} {
		width: 95%;
		border-radius: 10px;
		padding: 10px;
	}
`;

export const JoinMeetingDialogTitle = styled(Dialog.Title)`
	display: flex;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid var(--mb-gray-5);
	padding-bottom: 10px;
	margin-bottom: 0px;

	&::before {
		content: none;
		display: none;
	}
`;

export const JoinMeetingDialogDescription = styled(Dialog.Description)<{
	iserror?: boolean;
}>`
	display: flex;
	align-items: center;
	font-size: 14px;
	background: ${({ iserror }) =>
		iserror
			? "linear-gradient(90deg, #DC3D431A 0%, #F2555A 71%, #DC3D431A 100%)"
			: "linear-gradient(90deg, #e9f1ff 0%, #b6cfff 71%, #e6eeff 100%)"};
	padding: 0px 8px;
	border-radius: 4px;
	gap: 6px;
	height: 34px;
`;

export const DescriptionIcon = styled(Icon.Sparkle)`
	flex-shrink: 0;
`;

export const JoinMeetingDialogInput = styled(Input)`
	width: 100%;
`;

export const JoinMeetingDialogActions = styled(Flex)`
	gap: 6px;
`;

export const JoinMeetingDialogContentContainer = styled(Flex)`
	gap: 10px;
	flex-direction: column;
	padding-top: 20px;
`;

// Add these keyframes at the end of the file
const keyframes = `
	@keyframes overlayShow {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes contentShow {
		from {
			opacity: 0;
			transform: translate(-50%, -48%) scale(0.96);
		}
		to {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}
	}
`;

// Apply the keyframes
import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`${keyframes}`;
