import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	MAPLE_BILLING_FEATURE,
	NOTIFICATION_TAB_FEATURE,
	useFeatureFlag,
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import {
	SettingsContainer,
	SettingsContent,
	SettingsContentContainer,
	SettingsInnerContainer,
} from "./styled";
import SettingsSidebar from "./SettingsSidebar";
import Connect from "../Connect";
import UserNotification from "../UserNotification";
import { Text } from "@mightybot/web-ui";
import General from "../General";
import Billing from "../Billing/Billing";
import Teams from "../Teams";

const Settings = () => {
	const { settingsTab } = useParams<{
		settingsTab: string;
	}>();
	const showNotificationTab = useFeatureFlag(NOTIFICATION_TAB_FEATURE);
	const showMapleBilling = useFeatureFlag(MAPLE_BILLING_FEATURE);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.SETTINGS_PAGE_VIEWED, {
			[ANALYTICS_PROPERTIES.SETTINGS_TAB]: settingsTab,
		});
	}, []);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.SETTINGS_TAB_CHANGED, {
			[ANALYTICS_PROPERTIES.SETTINGS_TAB]: settingsTab,
		});
	}, [settingsTab]);

	return (
		<SettingsContainer>
			<SettingsInnerContainer>
				<Text size="6" weight="medium" style={{ padding: "20px 0px 10px 0px" }}>
					Settings
				</Text>
				<SettingsContentContainer>
					<SettingsSidebar />
					<SettingsContent>
						{settingsTab === "connect" ? (
							<Connect />
						) : settingsTab === "user-notifications" && showNotificationTab ? (
							<UserNotification />
						) : settingsTab === "general" ? (
							<General />
						) : settingsTab === "billing" && showMapleBilling ? (
							<Billing />
						) : null}
						{settingsTab === "teams" ? <Teams /> : null}
					</SettingsContent>
				</SettingsContentContainer>
			</SettingsInnerContainer>
		</SettingsContainer>
	);
};

export default Settings;
