import React, { useEffect, useState } from "react";
import {
	GoToEndButton,
	GoToEndButtonContainer,
	MeetingContentContainer,
	MeetingContentWrapper,
} from "./styled";
import MeetingContentTitle from "./MeetingContentTitle";
import MeetingContentScroller from "./MeetingContentScroller";
import MeetingContentPrompt from "./MeetingContentPrompt";
import { Flex, Icon } from "@mightybot/web-ui";
import { MeetingChatProvider } from "../../contexts/MeetingChatContext";
import { MeetingContentHoverProvider } from "../../contexts/MeetingContentHoverContext";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { useParams } from "react-router-dom";

interface MeetingContentProps {}

const MeetingContent: React.FC<MeetingContentProps> = ({}) => {
	const { view } = useParams();

	const [showGoToEnd, setShowGoToEnd] = useState(false);
	const { containerRef, endOfContentRef, scrollerRef } =
		useMeetingContentNavigation();

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
				const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100; // 100px threshold

				setShowGoToEnd(!isAtBottom);
			}
		};

		const container = containerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			window.addEventListener("resize", handleScroll);

			return () => {
				container.removeEventListener("scroll", handleScroll);
				window.removeEventListener("resize", handleScroll);
			};
		}
	}, [containerRef]);

	const scrollToEnd = () => {
		if (endOfContentRef.current) {
			endOfContentRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<MeetingChatProvider>
			<MeetingContentHoverProvider>
				<MeetingContentWrapper>
					<MeetingContentContainer ref={containerRef}>
						<MeetingContentTitle />
						<Flex
							direction="column"
							ref={scrollerRef}
							style={{ width: "100%", flex: 1 }}
						>
							<MeetingContentScroller endOfContentRef={endOfContentRef} />
						</Flex>
						<Flex
							direction="column"
							style={{
								width: "100%",
								position: "sticky",
								bottom: 0,
								display: "none",
							}}
						>
							<GoToEndButtonContainer className={showGoToEnd ? "visible" : ""}>
								<GoToEndButton onClick={scrollToEnd}>
									<Icon.ArrowDown
										fill="#333"
										style={{ width: "20px", height: "20px", flexShrink: 0 }}
									/>
								</GoToEndButton>
							</GoToEndButtonContainer>
							{/* TODO: add back in */}
							{view === "details" && <MeetingContentPrompt />}
						</Flex>
					</MeetingContentContainer>
				</MeetingContentWrapper>
			</MeetingContentHoverProvider>
		</MeetingChatProvider>
	);
};

export default MeetingContent;
