import styled from "styled-components";

export const ModalContent = styled.div`
	display: flex;
	width: 900px;
	background: white;
`;

export const LeftPanel = styled.div`
	width: 40%;
	padding: 32px;
	border-right: 1px solid var(--mb-gray-4);
	background: var(--mb-color-light-gray-2);
`;

export const RightPanel = styled.div`
	width: 60%;
	padding: 32px;
`;

export const PaymentMethodCard = styled.div`
	padding: 16px;
	border: 1px solid var(--mb-gray-4);
	border-radius: 8px;
	background: white;
	margin-bottom: 16px;
	transition: all 0.2s ease;

	&:focus-within {
		border-color: var(--mb-blue-6);
		box-shadow: 0 0 0 1px var(--mb-blue-6);
	}

	.StripeElement {
		width: 100%;
		padding: 4px 0;
	}
`;

export const SubmitButton = styled.button`
	width: 100%;
	padding: 12px 16px;
	background: var(--mb-blue-8);
	color: white;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		filter: brightness(95%);
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
