import React, { useEffect, useRef, useState } from "react";
import {
	ChatsContainer,
	ChatsContentContainer,
	ChatsInnerContainer,
} from "./styled";
import ChatsMessages from "./ChatsMessages";
import ChatsInput from "./ChatsInput";
import ChatsSidebar from "./ChatsSidebar";
import ChatsHeader from "./ChatsHeader";
import { StreamingProvider } from "../../contexts/StreamingContext";
import { useParams } from "react-router-dom";
import {
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";

const Chats: React.FC = () => {
	const contentRef = useRef<HTMLDivElement>(null);
	const { chatId } = useParams();
	const [showGoToEnd, setShowGoToEnd] = useState(false);
	const [temporaryMessage, setTemporaryMessage] = useState<string | null>(null);
	const [streamingError, setStreamingError] = useState(false);

	const scrollToBottom = (behavior: ScrollBehavior = "auto") => {
		const container = contentRef.current;
		if (!container) return;

		const scrollHeight = container.scrollHeight;
		const height = container.clientHeight;
		const maxScrollTop = scrollHeight - height;
		container.scrollTo({
			top: maxScrollTop > 0 ? maxScrollTop : 0,
			behavior,
		});
	};

	const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
		const container = event.currentTarget;
		const { scrollTop, scrollHeight, clientHeight } = container;
		const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
		const isAtBottom = distanceFromBottom < 100;

		setShowGoToEnd(!isAtBottom);
	};

	// Handle mutations for auto-scroll
	useEffect(() => {
		const container = contentRef.current;
		if (!container) return;

		const isNearBottom = () => {
			const { scrollTop, scrollHeight, clientHeight } = container;
			const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
			return distanceFromBottom < 100;
		};

		const observer = new MutationObserver(() => {
			// Only auto-scroll if we're already near the bottom
			if (isNearBottom()) {
				scrollToBottom();
			}
		});

		observer.observe(container, {
			childList: true,
			subtree: true,
			characterData: true,
		});

		scrollToBottom();

		return () => observer.disconnect();
	}, [chatId]);

	const handleGoToEnd = () => {
		scrollToBottom("smooth");
		setShowGoToEnd(false);
	};

	useEffect(() => {
		if (chatId) {
			Analytics.trackEvent(ANALYTICS_EVENTS.CHAT_STARTED, {
				chat_id: chatId,
			});
		}
	}, [chatId]);

	const handleStreamingStart = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.CHAT_STREAMING_STARTED, {
			chat_id: chatId,
		});
	};

	const handleStreamingComplete = (duration?: number) => {
		Analytics.trackEvent(ANALYTICS_EVENTS.CHAT_STREAMING_COMPLETED, {
			chat_id: chatId,
			[ANALYTICS_PROPERTIES.STREAMING_DURATION]: duration,
		});
	};

	const handleStreamingError = (error: Error) => {
		setStreamingError(true);
		Analytics.trackEvent(ANALYTICS_EVENTS.CHAT_STREAMING_ERROR, {
			chat_id: chatId,
			[ANALYTICS_PROPERTIES.ERROR_DETAILS]: error.message,
		});
	};

	return (
		<ChatsContainer>
			<StreamingProvider>
				<ChatsSidebar />
				<ChatsContentContainer
					id="chats-content-container"
					ref={contentRef}
					data-testid="chat-scroll-container"
					onScroll={handleScroll}
					newchat={!chatId ? "true" : "false"}
				>
					<ChatsHeader />
					<ChatsInnerContainer>
						<ChatsMessages
							temporaryMessage={temporaryMessage}
							onStreamingStart={handleStreamingStart}
							onStreamingComplete={handleStreamingComplete}
							onStreamingError={handleStreamingError}
						/>
						<ChatsInput
							handleGoToEnd={handleGoToEnd}
							showGoToEnd={showGoToEnd}
							onTemporaryMessage={setTemporaryMessage}
							setStreamingError={setStreamingError}
						/>
					</ChatsInnerContainer>
				</ChatsContentContainer>
			</StreamingProvider>
		</ChatsContainer>
	);
};

export default Chats;
