import React, { useState, useMemo, useEffect } from "react";
import {
	ChatReplyContainer,
	CopyIcon,
	SpinningIcon,
	ProgressBarContainer,
	ProgressBar,
	AccordionCard,
	ReasoningContainer,
	ReasoningHeader,
	ReasoningTitle,
	ReasoningContentWrapper,
	ReasoningInnerContent,
	ReasoningBorder,
	ReasoningText,
	CaretIconWrapper,
	ChainOfThoughtContainer,
	ChainOfThoughtHeader,
	ChainOfThoughtMessage,
	IconContainer,
	ChainOfThoughtDivider,
	RetryText,
	ChainOfThoughtContent,
	ProgressBarWrapper,
	agentSpinnerContainerStyle,
	agentSpinnerStyle,
} from "./styled";
import {
	CustomReactMarkdown,
	Flex,
	Icon,
	Tooltip,
	Text,
	AgentSpinner,
} from "@mightybot/web-ui";
import { motion } from "framer-motion";

import { useBOHTaskLogs, BOHTaskLog } from "@mightybot/core";

type ChatReplyProps = {
	reply: string;
	isStreaming?: boolean;
	onRetry?: () => void;
	completionId?: string;
	streamingFailed?: boolean;
};

const ChatReply: React.FC<ChatReplyProps> = ({
	reply: streamingReply,
	isStreaming,
	onRetry,
	completionId,
	streamingFailed,
}) => {
	const [isCopied, setIsCopied] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isReasoningOpen, setIsReasoningOpen] = useState(false);
	const [hasThinkTag, setHasThinkTag] = useState(false);
	const [bohStarted, setBohStarted] = useState(
		localStorage.getItem("bohStarted") === "true",
	);

	const { logs, progress, refetch } = useBOHTaskLogs({
		completionLogId: completionId || "",
		enabled: !!completionId && bohStarted,
		pollingInterval: 3000,
	});

	useEffect(() => {
		if (streamingReply?.endsWith("BOH_STARTED")) {
			localStorage.setItem("bohStarted", "true");
			setBohStarted(true);
		}
		if (completionId && bohStarted) {
			localStorage.setItem("bohStarted", "false");
		}
	}, [streamingReply, completionId]);

	useEffect(() => {
		const handleMessage = async (event: MessageEvent) => {
			if (event.data.type === "REFETCH_CHAT_DATA") {
				if (event.data.completionId === completionId) {
					await refetch();
				}
			}
		};

		navigator.serviceWorker?.addEventListener("message", handleMessage);

		return () => {
			navigator.serviceWorker?.removeEventListener("message", handleMessage);
		};
	}, [completionId, refetch]);

	const { chainOfThoughtLogs, replyLog } = useMemo(() => {
		return {
			chainOfThoughtLogs: logs.filter(
				(log: BOHTaskLog) => log.message_type === "CHAIN_OF_THOUGHT",
			),
			replyLog: logs.find((log: BOHTaskLog) => log.message_type === "REPLY"),
		};
	}, [logs]);

	const { cleanedReply, reasoningContent } = useMemo(() => {
		if (!streamingReply) return { cleanedReply: "", reasoningContent: "" };

		let cleanedReply = streamingReply.replaceAll("BOH_STARTED", "");
		let reasoningContent = "";

		// Extract content between <think> tags
		const thinkMatch = cleanedReply.match(/<think>([\s\S]*?)(?:<\/think>|$)/);
		if (thinkMatch) {
			reasoningContent = thinkMatch[1].trim();
			cleanedReply = cleanedReply
				.replace(/<think>[\s\S]*?(?:<\/think>|$)/, "")
				.trim();
			if (!hasThinkTag) {
				setHasThinkTag(true);
			}
		}

		return {
			cleanedReply: cleanedReply.replace(/</g, "\\<").replace(/>/g, "\\>"),
			reasoningContent,
		};
	}, [streamingReply, hasThinkTag]);

	const streamingContent = cleanedReply;
	const displayContent = useMemo(() => {
		if (replyLog) {
			return replyLog.message;
		}
	}, [replyLog]);

	if ((!streamingReply && !isStreaming) || streamingFailed) {
		return (
			<ChatReplyContainer>
				<Flex align="center" gap="4px">
					<Text size="1">Failed to generate response.</Text>
					<RetryText size="1" onClick={onRetry}>
						Retry
					</RetryText>
				</Flex>
			</ChatReplyContainer>
		);
	}

	return (
		<ChatReplyContainer>
			<Flex direction="column" width="100%" gap="12px">
				{reasoningContent && (
					<ReasoningContainer>
						<ReasoningHeader
							onClick={() => setIsReasoningOpen(!isReasoningOpen)}
						>
							<ReasoningTitle>{"Reasoning"}</ReasoningTitle>
							<CaretIconWrapper isOpen={isReasoningOpen}>
								<Icon.CaretDown />
							</CaretIconWrapper>
						</ReasoningHeader>
						<ReasoningContentWrapper
							initial={{ height: 0, opacity: 0 }}
							animate={{
								height: isReasoningOpen ? "auto" : 0,
								opacity: isReasoningOpen ? 1 : 0,
							}}
							transition={{ duration: 0.3, opacity: { duration: 0.3 } }}
						>
							<ReasoningInnerContent>
								<ReasoningBorder>
									<ReasoningText>
										<CustomReactMarkdown>
											{reasoningContent}
										</CustomReactMarkdown>
									</ReasoningText>
								</ReasoningBorder>
							</ReasoningInnerContent>
						</ReasoningContentWrapper>
					</ReasoningContainer>
				)}
				<CustomReactMarkdown>{streamingContent}</CustomReactMarkdown>
				{/* Chain of Thought Messages */}
				{(bohStarted || chainOfThoughtLogs.length > 0) && (
					<ChainOfThoughtContainer direction="column">
						<ChainOfThoughtHeader
							justify="between"
							align="center"
							onClick={() => setIsOpen(!isOpen)}
						>
							<Flex align="center" style={{ gap: "4px" }}>
								{displayContent ? (
									<Icon.CheckCircle weight="fill" size={22} color="#7DAB20" />
								) : (
									<AgentSpinner
										iconWidth="12px"
										iconHeight="12px"
										containerStyle={agentSpinnerContainerStyle}
										spinnerStyle={agentSpinnerStyle}
									/>
								)}
								<Text weight="medium">
									{displayContent
										? "Plan Completed"
										: chainOfThoughtLogs.length == 0
											? "MightyBot Agent is on it! Your task is being processed."
											: ""}
								</Text>
								{!displayContent && chainOfThoughtLogs.length > 0 && (
									<Text>
										{chainOfThoughtLogs[chainOfThoughtLogs.length - 1]
											.message || "Planning"}
									</Text>
								)}
							</Flex>
							{(chainOfThoughtLogs.length > 0 || displayContent) && (
								<motion.div
									initial={{ rotate: 0 }}
									animate={{ rotate: isOpen ? 180 : 0 }}
									transition={{ duration: 0.3 }}
								>
									<Icon.CaretDown />
								</motion.div>
							)}
						</ChainOfThoughtHeader>

						{!displayContent && (
							<ProgressBarWrapper direction="column">
								<ProgressBarContainer>
									<ProgressBar
										initial={{ width: "0%" }}
										animate={{ width: `${progress ?? 0}%` }}
										transition={{ duration: 1, ease: "easeOut" }}
									/>
								</ProgressBarContainer>
							</ProgressBarWrapper>
						)}

						<ChainOfThoughtContent
							initial={{ height: 0, opacity: 0 }}
							animate={{
								height: isOpen ? "auto" : 0,
								opacity: isOpen ? 1 : 0,
							}}
							transition={{ duration: 0.3, opacity: { duration: 0.3 } }}
						>
							{chainOfThoughtLogs?.map((message, index) => {
								if (!message.message) return null;
								return (
									<Flex direction="column" key={index}>
										<ChainOfThoughtMessage align="center">
											<IconContainer>
												<Icon.CheckCircle
													weight="fill"
													size={22}
													color="#999999"
												/>
											</IconContainer>
											<Text>{message.message}</Text>
										</ChainOfThoughtMessage>
										{index < chainOfThoughtLogs.length - 1 && (
											<ChainOfThoughtDivider />
										)}
									</Flex>
								);
							})}
						</ChainOfThoughtContent>
					</ChainOfThoughtContainer>
				)}

				{/* Final Reply */}
				{displayContent && (
					<CustomReactMarkdown>{displayContent}</CustomReactMarkdown>
				)}

				<Flex align="center" justify="between">
					<Flex justify="center" align="center">
						<Tooltip content="Copy" side="top">
							{isCopied ? (
								<Icon.Check />
							) : (
								<CopyIcon
									onClick={() => {
										navigator.clipboard.writeText(
											streamingContent +
												logs.map((log) => log.message).join("\n"),
										);
										setIsCopied(true);
										setTimeout(() => {
											setIsCopied(false);
										}, 2000);
									}}
								/>
							)}
						</Tooltip>
					</Flex>
				</Flex>
			</Flex>
		</ChatReplyContainer>
	);
};

export default ChatReply;
