import { Flex } from "@mightybot/web-ui";
import styled from "styled-components";

export const InfiniteScrollContainer = styled(Flex)`
	overflow-y: auto;
	height: 100vh;

	/* Webkit browsers (Chrome, Safari, etc.) */
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: #888 transparent;
`;
