import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Dialog, Text, Flex } from "@mightybot/web-ui";

import { PaymentForm } from "./PaymentForm";
import { LeftPanel, ModalContent, RightPanel } from "./styled";

// Ensure you have the Stripe publishable key in your environment variables
const stripePromise = loadStripe(
	process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY!,
);

interface PaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
	planId: string;
	planName: string;
	billingCycle: "monthly" | "yearly";
	amount: number;
	clientSecret: string; // Add clientSecret as a prop
	seats: number;
	onSuccess: () => void;
	onFailure: () => void;
}

export const PaymentModal = ({
	isOpen,
	onClose,
	planId,
	planName,
	billingCycle,
	amount,
	clientSecret,
	seats,
	onSuccess,
	onFailure,
}: PaymentModalProps) => {
	const options = {
		clientSecret,
		appearance: {
			theme: "stripe",
			variables: {
				colorPrimary: "#0055DE",
			},
		},
	};
	return (
		<Dialog.Root open={isOpen} onOpenChange={onClose}>
			<Dialog.Content
				style={{
					maxWidth: "900px",
					padding: 0,
					overflow: "hidden",
					width: "100%",
				}}
			>
				<ModalContent>
					<LeftPanel>
						<Flex direction="column" gap="4" mb="6">
							<Text size="3" weight="medium">
								Order Summary
							</Text>
							<Flex justify="between">
								<Text size="2" color="gray">
									Plan
								</Text>
								<Text size="2" weight="medium">
									{planName}
								</Text>
							</Flex>
							<Flex justify="between">
								<Text size="2" color="gray">
									Seats
								</Text>
								<Text size="2" weight="medium">
									{seats}
								</Text>
							</Flex>
							<Flex justify="between">
								<Text size="2" color="gray">
									Price per seat
								</Text>
								<Text size="2" weight="medium">
									${amount}
								</Text>
							</Flex>
							<Flex justify="between">
								<Text size="2" color="gray">
									Billing
								</Text>
								<Text size="2" weight="medium">
									{billingCycle}
								</Text>
							</Flex>
							<Flex
								justify="between"
								style={{
									borderTop: "1px solid var(--mb-gray-4)",
									paddingTop: "16px",
								}}
							>
								<Text size="2" weight="medium">
									Total
								</Text>
								<Text size="2" weight="medium">
									${amount * seats}/{billingCycle}
								</Text>
							</Flex>
						</Flex>
					</LeftPanel>

					<RightPanel>
						<Elements stripe={stripePromise} options={options}>
							<PaymentForm
								planId={planId}
								billingCycle={billingCycle}
								amount={amount}
								onSuccess={onSuccess}
								onClose={onClose}
								onFailure={onFailure}
								clientSecret={clientSecret}
								seats={seats}
							/>
						</Elements>
					</RightPanel>
				</ModalContent>
			</Dialog.Content>
		</Dialog.Root>
	);
};
