import styled from "styled-components";
import { Dialog, Flex, Text, Button, Select } from "@mightybot/web-ui";

export const Container = styled(Dialog.Content)`
	width: 100%;
	max-width: 758px;
	padding: 0px 20px;
	padding-top: 16px;
	background: var(--mb-color-light-gray-1);
	border-radius: 6px;
	border: 2px solid rgba(0, 0, 0, 0.1);
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const TemplateSelectContainer = styled(Flex)`
	position: relative;
	width: 251px;

	&::before {
		content: "Apply Template";
		position: absolute;
		top: -8px;
		left: 10px;
		padding: 0 4px;
		background: var(--mb-color-light-gray-1);
		color: var(--mb-color-light-gray-9);
		font-size: 10px;
		font-weight: 400;
		z-index: 1;
	}
`;

export const StyledSelect = styled(Select.Root)`
	width: 100%;
	border: 1px solid var(--mb-color-light-gray-4);
	border-radius: 6px;
	padding: 8px 10px;

	&:focus-within {
		border-color: var(--mb-color-light-blue-8);
	}
`;
export const GenerateDocContainer = styled(Dialog.Content)`
	background-color: var(--mb-color-light-gray-1);
	max-width: 760px;
	width: 100%;
	padding: 16px 20px;
	border-radius: 6px;
	border: 2px solid rgba(0, 0, 0, 0.1);
	position: relative;
`;

export const TabContainer = styled(Flex)`
	width: 100%;
	border-bottom: 1px solid var(--mb-color-light-gray-4);
`;

export const Tab = styled(Flex)<{ active?: boolean }>`
	flex: 1;
	height: 56px;
	padding: 0 6px 12px;
	border-bottom: ${(props) =>
		props.active ? `3px solid var(--mb-color-light-blue-8)` : "none"};
	cursor: pointer;
	gap: 12px;
	align-items: center;
`;

export const TabContent = styled(Flex)`
	flex: 1;
	flex-direction: column;
	gap: 3px;
`;

export const TabTitle = styled(Text)<{ active?: boolean }>`
	color: ${(props) =>
		props.active
			? `var(--mb-color-light-blue-8)`
			: "var(--mb-color-light-gray-11)"};
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
`;

export const TabDescription = styled(Text)`
	color: var(--mb-color-light-gray-9);
	font-size: 13px;
	font-weight: 400;
	line-height: 17px;
`;

export const DocumentNameInput = styled.input`
	width: 100%;
	height: 32px;
	padding: 0 15px;
	background: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-4);
	border-radius: 4px;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: var(--mb-color-light-gray-11);

	&::placeholder {
		color: var(--mb-color-light-gray-9);
	}
`;

export const OutlinesContainer = styled(Flex)`
	flex-direction: column;
	gap: 8px;
	max-height: 500px;
	overflow-y: auto;
	padding-right: 10px;

	/* Add styling for scrollbar */
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: var(--mb-color-light-gray-3);
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--mb-color-light-gray-6);
		border-radius: 4px;
	}

	/* Add padding bottom to account for footer shadow */
	padding-bottom: 16px;
	margin-bottom: 15px;
`;

export const OutlineItem = styled(Flex)`
	gap: 8px;
	align-items: flex-start;
	padding: 4px 0;
`;

export const OutlineContent = styled(Flex)`
	flex: 1;
	flex-direction: column;
`;

export const FooterContainer = styled(Flex)`
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	margin-top: 16px;
	padding: 16px 20px;
	background: var(--mb-color-light-gray-1);
	position: sticky;
	bottom: 0;
	margin: 0 -20px -16px;
	box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.6);
`;

export const ButtonGroup = styled(Flex)`
	gap: 8px;
	align-items: center;
`;

export const TemplateSelect = styled(Flex)`
	width: 271px;
	height: 34px;
	padding: 8px 10px;
	background: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-4);
	border-radius: 6px;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
		height: 3px;
		background: var(--mb-color-light-blue-8);
		border-radius: 6px 6px 0 0;
	}
`;

export const CancelButton = styled(Button)`
	width: 75px;
	padding: 8px 14px;
	color: var(--mb-color-light-gray-11);
	font-size: 14px;
	font-weight: 500;
	background: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-6);
	border-radius: 4px;
`;

export const GenerateButton = styled(Button)`
	width: 145px;
	padding: 8px 14px;
	background: var(--mb-color-light-blue-8);
	color: var(--mb-color-light-gray-1);
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 6px;

	&:disabled {
		opacity: 0.7;
	}
`;

export const CloseButton = styled(Button)`
	position: absolute;
	top: 16px;
	right: 16px;
	padding: 4px;
	background: transparent;
	border: none;
	cursor: pointer;
	color: var(--mb-color-light-gray-9);

	&:hover {
		color: var(--mb-color-light-gray-11);
	}
`;
