import React from "react";
import { Flex, Text, Radio } from "@mightybot/web-ui";

import { AccessType } from "@mightybot/core";

interface GeneralAccessProps {
	access: AccessType;
	onRadioChange: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const GeneralAccess = ({
	access,
	onRadioChange,
}: GeneralAccessProps) => {
	return (
		<Flex direction="column" gap="10px">
			<Text weight="medium">General Access</Text>
			<Flex gap="20px" align="center">
				<Flex gap="6px" align="center">
					<Radio
						name="access"
						value="private"
						onClick={onRadioChange}
						checked={access === "private"}
					/>
					<Text>Private</Text>
				</Flex>
				<Flex gap="4px" align="center">
					<Radio
						name="access"
						value="organisation"
						onClick={onRadioChange}
						checked={access === "organisation"}
					/>
					<Text>Organisation</Text>
				</Flex>
				<Flex gap="4px" align="center">
					<Radio
						name="access"
						value="public"
						onClick={onRadioChange}
						checked={access === "public"}
					/>
					<Text>Public</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
