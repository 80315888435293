import React, { useState, useEffect } from "react";
import {
	Button,
	Icon,
	Text,
	Spinner,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import { Dialog } from "@radix-ui/themes";
import {
	JoinMeetingDialogContainer,
	JoinMeetingDialogTitle,
	JoinMeetingDialogDescription,
	JoinMeetingDialogInput,
	JoinMeetingDialogActions,
	JoinMeetingDialogContentContainer,
	JoinMeetingDialogRoot,
	DescriptionIcon,
} from "./styled";
import { useJoinMeeting, isValidMeetingUrl } from "@mightybot/core";

export const JoinMeetingDialog: React.FC<{
	isSidebarOpen: boolean;
	triggerStyles?: React.CSSProperties;
	radius?: "full" | "medium" | "small";
}> = ({ isSidebarOpen, triggerStyles, radius = "full" }) => {
	const {
		joinMeeting,
		isLoading: isJoining,
		error: joinError,
		joinedMeeting,
	} = useJoinMeeting();
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [open, setOpen] = useState(false);
	const [meetingUrl, setMeetingUrl] = useState("");
	const [description, setDescription] = useState("");
	const [isUrlValid, setIsUrlValid] = useState(false);
	const [hasJoined, setHasJoined] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		setIsUrlValid(isValidMeetingUrl(meetingUrl));
		if (hasJoined) {
			setHasJoined(false);
		}
	}, [meetingUrl]);

	const handleConfirm = async () => {
		if (isUrlValid) {
			try {
				const joinMeetingResponse = await joinMeeting({
					meeting_url: new URL(meetingUrl),
				});

				if (joinMeetingResponse.already_joined) {
					setDescription(
						"MightyBot is already in this meeting. You can close this dialog.",
					);
					setIsError(false);
				} else if (joinMeetingResponse.success) {
					setDescription(
						"MightyBot has successfully joined your meeting. It will appear in your meeting prep list.",
					);
					setIsError(false);
				} else if (joinMeetingResponse.message) {
					setDescription(joinMeetingResponse.message);
					setIsError(true);
				}
				setHasJoined(!joinMeetingResponse.error);
			} catch (error: any) {
				console.error("Failed to add MightyBot to meeting:", error);
				setDescription(
					error.response?.data?.message ||
						"Failed to add MightyBot to the meeting. Please try again.",
				);
				setIsError(true);
				setHasJoined(false);
			}
		}
	};

	return (
		<JoinMeetingDialogRoot open={open} onOpenChange={setOpen}>
			<Dialog.Trigger>
				<Button
					radius={isMobile ? "full" : radius}
					size={isMobile ? "1" : "2"}
					style={{
						fontWeight: 500,
						padding: "16px",
						height: 38,
						cursor: "pointer",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						...triggerStyles,
					}}
				>
					<Icon.Plus weight="bold" />
					{isSidebarOpen && !isMobile && "Add MightyBot to Meeting"}
				</Button>
			</Dialog.Trigger>
			<JoinMeetingDialogContainer>
				<JoinMeetingDialogTitle>
					<Icon.Plus size="18" weight="bold" />
					<Text weight="medium" style={{ fontSize: "18px" }}>
						{isMobile ? "Add" : "Add MightyBot to Meeting"}
					</Text>
				</JoinMeetingDialogTitle>
				<JoinMeetingDialogContentContainer>
					{description && (
						<JoinMeetingDialogDescription iserror={isError}>
							<DescriptionIcon size="20" weight="fill" />
							{description}
						</JoinMeetingDialogDescription>
					)}
					<Text size="1" weight="medium">
						Your meeting's join URL (Meet or Zoom URL)
					</Text>
					<JoinMeetingDialogInput
						placeholder="eg. meet.google.com/xxx-xxxx-xxx"
						value={meetingUrl}
						onChange={(e) => setMeetingUrl(e.target.value)}
						style={{
							border: !isUrlValid && meetingUrl ? "1px solid #DC3D43" : "none",
						}}
					/>
					{!isUrlValid && meetingUrl && (
						<Text size="1" style={{ color: "#DC3D43" }}>
							Please enter a valid Google Meet or Zoom URL
						</Text>
					)}
					<JoinMeetingDialogActions>
						<Button
							onClick={handleConfirm}
							disabled={isJoining || !isUrlValid || (hasJoined && !joinError)}
							style={{ cursor: "pointer" }}
						>
							{isJoining ? "Joining..." : "Confirm"}
							{isJoining && <Spinner size="16" />}
						</Button>
						<Dialog.Close>
							<Button variant="white-bg" style={{ cursor: "pointer" }}>
								Close
							</Button>
						</Dialog.Close>
					</JoinMeetingDialogActions>
				</JoinMeetingDialogContentContainer>
			</JoinMeetingDialogContainer>
		</JoinMeetingDialogRoot>
	);
};
