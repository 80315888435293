import React from "react";
import { TranscriptSegment } from "@mightybot/core";

export function useTranscriptSync(
	transcript: TranscriptSegment[],
	currentTime: number,
) {
	const [activeSegmentIndex, setActiveSegmentIndex] =
		React.useState<number>(-1);

	React.useEffect(() => {
		const index = transcript.findIndex((segment) => {
			const word = segment.words[0];
			return (
				currentTime >= word.start_timestamp && currentTime <= word.end_timestamp
			);
		});

		setActiveSegmentIndex(index);
	}, [currentTime, transcript]);

	return {
		activeSegmentIndex,
	};
}
