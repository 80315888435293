import React from "react";
import { Dialog, Flex, Text, Button } from "@mightybot/web-ui";
import { Log } from "@mightybot/core";

type LogsOutputDialogProps = {
	open: boolean;
	onClose: () => void;
	log: Log | null | undefined;
	compareLog?: Log | null;
};

const LogsOutputDialog: React.FC<LogsOutputDialogProps> = ({
	open,
	onClose,
	log,
	compareLog,
}) => {
	if (!log) {
		return null;
	}

	const renderLogContent = (logData: Log, title?: string) => (
		<Flex
			direction="column"
			style={{
				padding: "12px 26px",
				backgroundColor: "var(--mb-blue-1)",
				flex: 1,
			}}
		>
			{title && (
				<Text weight="bold" style={{ marginBottom: "12px" }}>
					{title}
				</Text>
			)}
			<Text>
				<strong>Input:</strong> {logData.input}
			</Text>
			<Text>
				<strong>Output:</strong> {logData.output}
			</Text>
			<Text>
				<strong>Enterprise:</strong> {logData.enterprise_name}
			</Text>
			<Text>
				<strong>Is Test:</strong> {logData.is_test ? "Yes" : "No"}
			</Text>
			<Text>
				<strong>Trace ID:</strong> {logData.trace_id || "N/A"}
			</Text>
			<Text>
				<strong>Trace URL:</strong> {logData.trace_url || "N/A"}
			</Text>
			<Text>
				<strong>Extracted Data:</strong>
			</Text>
			{logData.data && (
				<Flex direction="column" style={{ gap: "8px" }}>
					{Object.entries(logData.data).map(([key, value]) => {
						const formattedKey = key
							.replace(/_/g, " ")
							.replace(/\b\w/g, (l) => l.toUpperCase());
						return (
							<Flex key={key} direction="column">
								<Text weight="medium">{formattedKey}:</Text>
								<Text
									style={{
										backgroundColor: "var(--mb-gray-1)",
										padding: "8px",
										borderRadius: "4px",
										overflowX: "auto",
										maxHeight: "100px",
										fontSize: "12px",
										whiteSpace: "pre-wrap",
										wordBreak: "break-word",
									}}
								>
									{typeof value === "object"
										? JSON.stringify(value, null, 2)
										: String(value)}
								</Text>
							</Flex>
						);
					})}
				</Flex>
			)}
		</Flex>
	);

	return (
		<Dialog.Root open={open} onOpenChange={onClose}>
			<Dialog.Content
				style={{ maxWidth: compareLog ? 1000 : 500, padding: 20 }}
			>
				<Dialog.Title>Output Details</Dialog.Title>
				<Flex direction="column" style={{ gap: "16px" }}>
					<Flex style={{ gap: "16px" }}>
						{compareLog ? (
							<>
								{renderLogContent(compareLog, "Log 1")}
								{renderLogContent(log, "Log 2")}
							</>
						) : (
							renderLogContent(log)
						)}
					</Flex>
					<Flex justify="end" gap="3">
						<Button
							variant="soft"
							color="gray"
							onClick={onClose}
							style={{ cursor: "pointer" }}
						>
							Close
						</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default LogsOutputDialog;
