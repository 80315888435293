import React, { useState } from "react";
import { Text, Icon, Skeleton } from "@mightybot/web-ui";
import { useMeeting, useMeetingContent } from "@mightybot/core";
import { useParams, Link } from "react-router-dom";
import { formatDateTime, formatDuration } from "../../utils/helpers";
import GenerateDoc from "../GenerateDoc";
import ModelSelection from "../ModelSelection";
import { Button } from "@mightybot/web-ui";
import Toast from "../Toast";

interface MeetingContentTitleProps {}

const MeetingContentTitle: React.FC<MeetingContentTitleProps> = ({}) => {
	const meetingId = useParams().meetingId;
	const { meeting, isLoading } = useMeeting(meetingId ?? "");
	const { meetingContent } = useMeetingContent(meetingId ?? "");

	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error",
	});

	const duration = formatDuration(
		meeting?.start_time ?? "",
		meeting?.end_time ?? "",
	);
	const formattedDuration = duration.startsWith("0h ")
		? duration.slice(3)
		: duration;

	if (!meetingId) return null;

	const showToastMessage = (
		title: string,
		description: string,
		status: "success" | "error",
	) => {
		setToastData({
			title,
			description,
			status,
		});
		setOpenToast(true);
	};

	return (
		<div
			className="flex justify-between items-center gap-3 border-b border-border-primary border-x-0 border-t-0 border-solid px-4 py-5"
			data-title={`${meetingId}-title`}
		>
			<div className="flex w-full gap-5">
				<div>
					<Link to={`/meetings/${meetingId}`}>
						<Button
							size="2"
							className="bg-blue-one text-text-primary rounded-full border border-border-primary border-solid cursor-pointer h-[34px]"
						>
							{" "}
							<Icon.ArrowLeft className="h-5 w-5" /> Back
						</Button>
					</Link>
				</div>
				<div className="flex items-center gap-3 w-full">
					{isLoading ? (
						<>
							<Skeleton width="300px" height="24px" />
							<Skeleton width="150px" height="16px" />
							<Skeleton width="100px" height="16px" />
						</>
					) : (
						<div className="flex flex-col">
							<div className="flex items-center flex-wrap gap-5 text-text-secondary">
								<div className="font-medium text-lg p-0 text-text-primary">
									{meeting?.title}
								</div>
								<div className="flex items-center gap-1 min-w-fit">
									<Icon.CalendarBlank />
									<Text weight="regular" size="1">
										{formatDateTime(meeting?.start_time ?? "")}
									</Text>
								</div>
								<div className="flex items-center gap-1 min-w-fit">
									<Icon.Clock />
									<Text weight="regular" size="1">
										{formattedDuration}
									</Text>
								</div>
							</div>
							<div className="md:hidden flex items-center ml-auto gap-[5px]">
								<GenerateDoc
									meetingContent={meetingContent ?? []}
									showToastMessage={showToastMessage}
								/>
								<ModelSelection />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="hidden md:flex items-start justify-end gap-2 w-full">
				<div className="flex items-center gap-2">
					{isLoading ? (
						<Skeleton width="120px" height="32px" />
					) : (
						<div className="flex items-center gap-[5px]">
							<GenerateDoc
								meetingContent={meetingContent ?? []}
								showToastMessage={showToastMessage}
							/>
							<ModelSelection />
						</div>
					)}
				</div>
			</div>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</div>
	);
};

export default MeetingContentTitle;
