import React, { useRef, useState, useEffect } from "react";
import { NLSearchResult } from "@mightybot/core";
import {
	Flex,
	Text,
	CustomReactMarkdown,
	Checkbox,
	Icon,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import {
	ResultContainer,
	IconContainer,
	ContentContainer,
	TitleLink,
	customMarkdownStyles,
	SnippetContent,
	CheckboxContainer,
	NavigationContainer,
} from "./styled";
import { MBLogoGray, useIntegrationLogos } from "@mightybot/core-ui";
import { useSearchSelection } from "@mightybot/core";
import Widget, { WidgetIcon } from "../Widget/Widget";
import { formatMarkdown } from "../../utils/helpers";

const formatTime = (timeString: string) => {
	const date = new Date(timeString);
	const now = new Date();
	const isToday = date.toDateString() === now.toDateString();

	if (isToday) {
		return `Today, ${date.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true })}`;
	} else {
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	}
};

interface SearchResultProps {
	result: NLSearchResult;
	index: number;
	isLast: boolean;
	isDialog: boolean;
	handlePrevious: (e: React.MouseEvent) => void;
	handleNext: (e: React.MouseEvent) => void;
	hasMultipleResults: boolean;
	count: number;
	currentIndex: number;
	slideDirection: "left" | "right" | null;
	groupResults: NLSearchResult[];
	setCurrentIndex: (index: number) => void;
	showSelectedOnly: boolean;
}

const SearchResult: React.FC<SearchResultProps> = ({
	result,
	index,
	isLast,
	isDialog,
	handlePrevious,
	handleNext,
	hasMultipleResults,
	count,
	currentIndex,
	slideDirection,
	groupResults,
	setCurrentIndex,
	showSelectedOnly,
}) => {
	const resultRef = useRef<HTMLDivElement>(null);
	const [isHovered, setIsHovered] = useState(false);
	const [widgetPosition, setWidgetPosition] = useState({ top: 0, left: 0 });
	const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);

	const { getIntegrationLogoRegex } = useIntegrationLogos();
	const icon = getIntegrationLogoRegex(
		result?.app?.toLowerCase().replace(" ", "_") ?? "",
	);
	const {
		isSelectionMode,
		selectedResults,
		toggleResultSelection,
		toggleSelectionMode,
	} = useSearchSelection();
	useEffect(() => {
		if (isSelectionMode) {
			// Slight delay to allow the checkbox container to render before animating
			setTimeout(() => setIsCheckboxVisible(true), 50);
		} else {
			setIsCheckboxVisible(false);
		}
	}, [isSelectionMode]);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const formatSnippet = (snippet: string) => {
		return formatMarkdown(snippet);
	};

	const calculateWidgetPosition = () => {
		if (resultRef.current) {
			const rect = resultRef.current.getBoundingClientRect();
			setWidgetPosition({
				top: rect.top + rect.height / 2 - 20, // Center vertically
				left: rect.right - 100, // 5px offset from the right border
			});
		}
	};

	const handleMouseEnter = () => {
		if (isDialog) return;
		calculateWidgetPosition();
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		if (isDialog) return;
		setIsHovered(false);
	};

	const icons: WidgetIcon[] = [
		{
			content: "Chat with this",
			icon: Icon.ChatTeardropText,
			onClick: () => toggleSelectionMode(),
		},
	];

	const onSelectResult = () => {
		toggleResultSelection(result);
	};

	const handleLinkClick = (e: React.MouseEvent) => {
		if (isSelectionMode) {
			e.preventDefault();
		}
		e.stopPropagation();
	};

	const isChecked = selectedResults.some(
		(selectedResult) => selectedResult.id === result.id,
	);

	const handleNavigation = (e: React.MouseEvent, action: () => void) => {
		e.stopPropagation();
		action();
	};

	const shouldShowWidget =
		!isMobile && isHovered && !isSelectionMode && !isDialog;

	return (
		<ResultContainer
			ref={resultRef}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={onSelectResult}
			islast={isLast.toString()}
			index={index.toString()}
			isselectionmode={isSelectionMode.toString()}
			ischecked={isChecked.toString()}
			hasmultipleresults={hasMultipleResults.toString()}
		>
			{shouldShowWidget && (
				<Widget widgetPosition={widgetPosition} icons={icons} />
			)}
			{isSelectionMode && (
				<CheckboxContainer $isVisible={isCheckboxVisible}>
					<Checkbox checked={isChecked} />
				</CheckboxContainer>
			)}
			<ContentContainer>
				<IconContainer>
					<img
						src={icon ?? MBLogoGray}
						alt={result.app ?? ""}
						style={{ width: "30px", height: "30px" }}
					/>
				</IconContainer>
				<Flex
					direction="column"
					gap="2px"
					justify="between"
					style={{ flex: 1 }}
				>
					<Flex direction="column" gap="2px" id="result-content-container">
						<TitleLink
							href={result.link || "#"}
							target="_blank"
							rel="noopener noreferrer"
							onClick={handleLinkClick}
						>
							<Text
								size="2"
								weight="medium"
								style={{ color: "var(--mb-blue-8)" }}
							>
								{result.title}
							</Text>
						</TitleLink>
						<Flex align="center" gap="7px">
							{result.time && (
								<Text
									size="1"
									style={{
										color: "var(--mb-gray-9)",
									}}
								>
									{formatTime(result.time)}
								</Text>
							)}
						</Flex>
						<SnippetContent slidedirection={slideDirection}>
							<CustomReactMarkdown
								styles={customMarkdownStyles as any}
								disableLinks={true}
							>
								{formatSnippet(result?.snippet ?? "")}
							</CustomReactMarkdown>
						</SnippetContent>
					</Flex>
					{hasMultipleResults && (
						<NavigationContainer>
							<Icon.CaretLeft
								size={20}
								color="var(--mb-gray-8)"
								onClick={(e) => handleNavigation(e, () => handlePrevious(e))}
								style={{
									cursor: "pointer",
									opacity: currentIndex === 0 ? 0.5 : 1,
								}}
							/>
							{isSelectionMode ? (
								<Flex gap="2">
									{groupResults.map((groupResult, idx) => (
										<Flex
											key={groupResult.id}
											style={{
												width: "8px",
												height: "8px",
												borderRadius: "50%",
												backgroundColor: selectedResults.some(
													(selected) => selected.id === groupResult.id,
												)
													? "var(--mb-blue-8)"
													: "var(--mb-gray-6)",
												cursor: "pointer",
											}}
											onClick={(e) => {
												e.stopPropagation();
												setCurrentIndex(idx);
											}}
										/>
									))}
								</Flex>
							) : (
								<Text style={{ color: "var(--mb-gray-8)", fontSize: "13px" }}>
									{currentIndex + 1}/{count}
								</Text>
							)}
							<Icon.CaretRight
								size={20}
								color="var(--mb-gray-8)"
								onClick={(e) => handleNavigation(e, () => handleNext(e))}
								style={{
									cursor: "pointer",
									opacity: currentIndex === count - 1 ? 0.5 : 1,
								}}
							/>
						</NavigationContainer>
					)}
				</Flex>
				{showSelectedOnly && (
					<Icon.X
						size={16}
						color="var(--mb-gray-8)"
						style={{ cursor: "pointer", flexShrink: 0, alignSelf: "center" }}
						onClick={(e) =>
							handleNavigation(e, () => toggleResultSelection(result))
						}
					/>
				)}
			</ContentContainer>
		</ResultContainer>
	);
};

export default SearchResult;
