export const ERROR_MESSAGES = {
	card_declined: "Your card was declined. Please try another card.",
	expired_card: "Your card has expired. Please try another card.",
	incorrect_cvc: "Your card's security code is incorrect.",
	invalid_number: "Your card number is invalid.",
	incomplete_number: "Your card number is incomplete.",
	incomplete_cvc: "Your card's security code is incomplete.",
	incomplete_expiry: "Your card's expiration date is incomplete.",
	invalid_expiry_month: "Your card's expiration month is invalid.",
	invalid_expiry_year: "Your card's expiration year is invalid.",
	postal_code_invalid: "Your postal code is invalid.",
	rate_limit: "Too many requests. Please try again later.",
	default: "An error occurred while processing your payment. Please try again.",
};
