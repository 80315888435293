import React, { useEffect, useRef, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { Flex, Spinner } from "@mightybot/web-ui";
import { Meeting } from "@mightybot/core";
import { InfiniteScrollContainer } from "./styled";

type QueryFnResponse = {
	data: Meeting[] | any[];
	isLoading: boolean;
	error: Error | null;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	fetchNextPage: () => Promise<void>;
	fetchPreviousPage: () => Promise<void>;
};

type QueryFnRequest = { initialCursor: string; perPage?: number };
interface InfiniteScrollProps {
	queryFn?: (params: QueryFnRequest) => QueryFnResponse;
	initialCursor: string;
	renderItems: (items: Meeting[] | any[]) => React.ReactNode;
	loadingComponent?: React.ReactNode;
	endMessage?: React.ReactNode;
	enablePastScroll?: boolean;
	initialPosition: string;
	showNoMoreItems?: boolean;
}

let firstLoad = true;

export function InfiniteScroll({
	queryFn,
	initialCursor,
	renderItems,
	loadingComponent = <Spinner />,
	endMessage = <p>No more items to load</p>,
	showNoMoreItems = true,
	enablePastScroll = false,
	initialPosition,
}: InfiniteScrollProps) {
	const [showTopRef, setShowTopRef] = useState(false);
	const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
	const [bottomRef, bottomInView] = useInView({
		threshold: 1,
	});

	const [topRef, topInView] = useInView({
		threshold: 1,
	});

	const containerRef = useRef<HTMLDivElement>(null);
	const lastScrollTop = useRef(0);

	if (!queryFn) {
		throw new Error("queryFn is required");
	}

	const {
		data,
		isLoading,
		error,
		hasNextPage,
		hasPreviousPage,
		fetchNextPage,
		fetchPreviousPage,
	} = queryFn({ initialCursor });

	let element: HTMLElement | null = null;

	const scrollElementToTop = (element: HTMLElement) => {
		if (containerRef.current) {
			if (element) {
				const containerTop = containerRef.current.getBoundingClientRect().top;
				const elementTop = element.getBoundingClientRect().top;
				const scrollAmount = elementTop - containerTop;

				containerRef.current.scrollBy({
					top: scrollAmount,
					behavior: "smooth",
				});
			}
		}
	};

	const handleScroll = useCallback(() => {
		if (containerRef.current) {
			const { scrollTop } = containerRef.current;
			if (scrollTop < lastScrollTop.current && scrollTop < 100) {
				setShowTopRef(true);
			}
			lastScrollTop.current = scrollTop;
		}
	}, []);

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			return () => container.removeEventListener("scroll", handleScroll);
		}
	}, [handleScroll]);

	useEffect(() => {
		if (bottomInView && hasNextPage) {
			fetchNextPage();
		}
	}, [bottomInView, fetchNextPage, hasNextPage]);

	useEffect(() => {
		if (enablePastScroll && topInView && hasPreviousPage && showTopRef) {
			setIsLoadingPrevious(true);
			fetchPreviousPage().then(() => {
				setIsLoadingPrevious(false);
				setShowTopRef(false);

				// Scroll to the bottom of the spacer after fetching previous items
				if (spacerRef.current && containerRef.current) {
					const spacerBottom =
						spacerRef.current.offsetTop + spacerRef.current.offsetHeight;
					containerRef.current.scrollTop = spacerBottom + 30;
				}
			});
		}
	}, [
		enablePastScroll,
		topInView,
		fetchPreviousPage,
		hasPreviousPage,
		showTopRef,
	]);

	useEffect(() => {
		if (containerRef.current) {
			element = containerRef.current.querySelector(`#${initialPosition}`);
		}
		if (data && firstLoad && element) {
			scrollElementToTop(element);
			firstLoad = false;
		}
	}, [data, firstLoad, element, initialPosition]);

	const spacerRef = useRef<HTMLDivElement>(null);

	// if (isLoading && (!data || data.length === 0)) {
	// 	return (
	// 		<Flex direction="column" gap="2">
	// 			{[...Array(5)].map((_, index) => (
	// 				<Flex
	// 					key={index}
	// 					align="center"
	// 					gap="2"
	// 					style={{ padding: "8px 16px" }}
	// 				>
	// 					<Skeleton width="52px" height="16px" />
	// 					<Skeleton width="16px" height="16px" />
	// 					<Skeleton width="70%" height="16px" />
	// 				</Flex>
	// 			))}
	// 		</Flex>
	// 	);
	// }
	if (isLoading && !data) return <>{loadingComponent}</>;
	if (error) return <p>Error fetching data: {error.message}</p>;

	return (
		<InfiniteScrollContainer direction="column" ref={containerRef}>
			{enablePastScroll && showTopRef && (
				<Flex
					justify="center"
					align="center"
					data-testid="top-ref"
					ref={topRef}
				>
					{hasPreviousPage && isLoadingPrevious ? loadingComponent : null}
					{!hasPreviousPage && showNoMoreItems && endMessage}
				</Flex>
			)}
			{enablePastScroll && (
				<Flex
					id="past-scroll-spacer"
					ref={spacerRef}
					style={{
						height: showNoMoreItems ? "8px" : "0px",
						minHeight: showNoMoreItems ? "8px" : "0px",
					}}
				/>
			)}

			{renderItems(data)}
			<Flex
				justify="center"
				align="center"
				ref={bottomRef}
				style={{ marginTop: "8px", minHeight: "8px" }}
			>
				{hasNextPage ? loadingComponent : null}
				{!hasNextPage && showNoMoreItems && endMessage}
			</Flex>
		</InfiniteScrollContainer>
	);
}
