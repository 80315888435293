import styled from "styled-components";
import { Button, Flex, Text, mediaQueries } from "@mightybot/web-ui";

export const MeetingDetailsContainer = styled(Flex)`
	height: 100%;
	width: 100%;
	${mediaQueries.tablet} {
		padding: 0px 0px 10px 0px;
		flex-direction: column;
		background-color: var(--mb-gray-1);
	}
`;

export const ListButton = styled(Button)`
	width: 100%;
	height: 2.875rem;
	font-weight: 400;

	&:not(.rt-variant-solid) {
		box-shadow: inset 0 -1px 0 1px var(--gray-4);
		color: var(--gray-12);
	}
`;

export const SidebarContainer = styled(Flex)`
	width: 270px;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--mb-color-light-blue-1);
	border-radius: 16px;
	padding: var(--SIzing-size-05, 20px) 10px 16px 10px;
	padding: 20px 10px 16px 10px;
	height: 100vh;
`;

export const SelectContainer = styled(Flex)`
	background-color: var(--mb-color-light-blue-1);
	border-radius: 16px;
	align-items: center;
	gap: 10px;
	width: 270px;
`;

export const MenuItem = styled(Flex)<{ color: string }>`
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	gap: 10px;
	padding: 8px 16px;
	border-radius: var(--radius-pill);
	transition: background-color 0.2s ease;
	background-color: ${({ color }) =>
		color === "blue" ? "var(--mb-color-light-blue-2)" : "transparent"};

	white-space: nowrap;
	overflow: hidden;

	&:hover {
		background-color: var(--mb-color-light-blue-2);
		color: var(--mb-color-light-blue-8);
		div {
			background-color: var(--mb-color-light-blue-7);
		}
	}
`;

export const Cube = styled(Flex)<{ color: string }>`
	flex-shrink: 0;
	width: 8px;
	height: 8px;
	background-color: ${({ color }) =>
		color === "blue"
			? "var(--mb-color-light-blue-9)"
			: "var(--mb-color-light-blue-1)"};
	margin-right: 6px;
	border-radius: 2px;
	transition: background-color 0.2s ease;
`;

export const ConversationItem = styled(Flex)<{ color: string }>`
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	gap: 5px;
	border-radius: var(--radius-pill);
	transition: all 0.2s ease-in-out;
	padding: 8px 16px;

	background-color: ${({ color }) =>
		color === "white"
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-blue-1)"};
	color: ${({ color }) =>
		color === "white" ? "white" : "var(--mb-color-light-blue-8)"};

	svg {
		color: ${({ color }) =>
			color === "white" ? "white" : "var(--mb-color-light-blue-8)"};
	}
`;

export const MenuItemText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80%;
	&:hover {
		overflow: visible;
		white-space: normal;
		word-break: break-word;
	}
`;
