import React from "react";
import { Text, Icon } from "@mightybot/web-ui";
import { useMe, useTour } from "@mightybot/core";
import {
	TourContainer,
	PreviewImage,
	TourActions,
	SkipButton,
	StartButton,
	TourPopupWrapper,
} from "./styled";
import { TourStart } from "@mightybot/core-ui";

interface TourPopupProps {
	isOpen: boolean;
	onClose: () => void;
	onStartTour: () => void;
}

const TourPopup: React.FC<TourPopupProps> = ({
	isOpen,
	onClose,
	onStartTour,
}) => {
	const { data: user } = useMe();
	const { startTour, remindLater, shouldShowTour, isLoading } = useTour();

	if (isLoading) return null;

	// Only show if both isOpen is true and shouldShowTour hook says to show it
	if (!isOpen || !shouldShowTour) return null;

	const handleStartTour = async () => {
		await startTour();
		onStartTour();
	};

	const handleSkipTour = async () => {
		await remindLater();
		onClose();
	};

	if (!user?.onboarding_completed) return null;

	return (
		<TourPopupWrapper>
			<TourContainer>
				<PreviewImage src={TourStart} alt="Tour preview" />
				<Text
					size="3"
					weight="medium"
					style={{
						lineHeight: "22px",
					}}
				>
					Ready to Explore?
				</Text>
				<Text
					size="1"
					style={{
						lineHeight: "16px",
					}}
				>
					Discover the key features of MightyBot in just a few minutes!
				</Text>
				<TourActions direction="column" justify="end" align="center" gap="6px">
					<SkipButton variant="ghost" onClick={handleSkipTour}>
						Skip for later
					</SkipButton>
					<StartButton onClick={handleStartTour}>
						Start Tour
						<Icon.ArrowRight size={16} color="white" />
					</StartButton>
				</TourActions>
			</TourContainer>
		</TourPopupWrapper>
	);
};

export default TourPopup;
