import React, { useEffect, useState } from "react";
import { useConversation, useSearchSelection } from "@mightybot/core";
import Sidebar from "../Sidebar/Sidebar";
import { UUID } from "crypto";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Icon,
	Flex,
	useMediaQuery,
	breakpointNumbers,
} from "@mightybot/web-ui";
import { MobileSidebarContainer } from "./styled";
const ChatsSidebar: React.FC = () => {
	const navigate = useNavigate();
	const { chatId: activeConversationId } = useParams();
	const {
		conversations,
		isLoadingConversations,
		clearConversation,
		refetchConversations,
		conversation,
	} = useConversation(activeConversationId as UUID);
	const { clearSelection } = useSearchSelection();
	const isTablet = useMediaQuery(`(max-width: ${breakpointNumbers.tablet}px)`);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);

	useEffect(() => {
		setIsSidebarOpen(false);
	}, [isTablet]);

	const toggleSidebar = () => {
		if (isTablet) {
			setIsMobileHistoryOpen(!isMobileHistoryOpen);
		} else {
			setIsSidebarOpen(!isSidebarOpen);
		}
	};

	useEffect(() => {
		refetchConversations();
	}, [refetchConversations, conversation]);

	const handleItemClick = (id: UUID) => {
		clearSelection();
		clearConversation();
		navigate(`/chats/${id}`);
		if (isTablet) {
			setIsMobileHistoryOpen(false);
		}
	};

	const handleNewChat = () => {
		clearSelection();
		clearConversation();
		navigate(`/chats`);
		if (isTablet) {
			setIsMobileHistoryOpen(false);
		}
	};

	const sidebarItems =
		conversations?.map((conv) => ({
			id: conv.id,
			text: conv.title || `New conversation`,
			date: new Date(conv.created_ts),
		})) || [];

	if (isTablet) {
		return (
			<MobileSidebarContainer>
				<Sidebar
					title="Chat History"
					toggleSidebar={toggleSidebar}
					isSidebarOpen={isSidebarOpen}
					activeItemId={activeConversationId as UUID}
					items={sidebarItems}
					onItemClick={handleItemClick}
					containerStyle={{
						margin: "10px 0px 10px 10px",
						position: "relative",
						height: "auto",
						zIndex: 1,
					}}
					isLoading={isLoadingConversations}
					itemIcon={<Icon.ChatTeardropText size={18} />}
					isMobileHistoryOpen={isMobileHistoryOpen}
				/>
			</MobileSidebarContainer>
		);
	}

	return (
		<Flex
			direction="column"
			style={{
				width: "100%",
				maxWidth: isSidebarOpen ? "280px" : "80px",
				height: "auto",
				transition: "all 0.2s ease",
			}}
			align={isSidebarOpen ? "start" : "center"}
		>
			<Button
				onClick={handleNewChat}
				style={{
					margin: "10px 0px 0px 10px",
					cursor: "pointer",
					height: "38px",
					borderRadius: "var(--radius-pill)",
					width: "100%",
					padding: isSidebarOpen ? undefined : "0",
					maxWidth: isSidebarOpen ? "270px" : "80px",
					transition: "all 0.2s ease",
				}}
			>
				<Icon.Plus />
				{isSidebarOpen && "New Chat"}
			</Button>
			<Sidebar
				title="Chat History"
				toggleSidebar={toggleSidebar}
				isSidebarOpen={isSidebarOpen}
				activeItemId={activeConversationId as UUID}
				items={sidebarItems}
				onItemClick={handleItemClick}
				containerStyle={{
					margin: "10px 0px 10px 10px",
					position: "relative",
					height: "100%",
					zIndex: 1,
				}}
				isLoading={isLoadingConversations}
				itemIcon={<Icon.ChatTeardropText size={18} />}
				isMobileHistoryOpen={isMobileHistoryOpen}
			/>
		</Flex>
	);
};

export default ChatsSidebar;
