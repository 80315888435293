import React, { useMemo, useEffect } from "react";
import {
	Cube,
	MeetingOutlineContainer,
	MeetingOutlineItem,
	MeetingOutlineItemText,
	MeetingOutlineHeaderContainer,
	MeetingOutlineContentContainer,
	MeetingOutlineSidebarToggleButton,
	MeetingOutlineItemsContainer,
	MobileOutlineBar,
	MobileOutlineContent,
} from "./styled";
import { Flex, Text, Icon, Skeleton } from "@mightybot/web-ui";
import { useMeetingContent } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { useLocation } from "react-router-dom";

type MeetingOutlineProps = {
	toggleSidebar: () => void;
	isSidebarOpen: boolean;
	isMobile: boolean;
};

const MeetingOutline = ({
	toggleSidebar,
	isSidebarOpen,
	isMobile,
}: MeetingOutlineProps) => {
	const meetingId = useParams().meetingId;
	const { meetingOutline, isLoading } = useMeetingContent(meetingId ?? "");
	const { activeItem, handleMeetingOutlineItemClick, updateUrlHash } =
		useMeetingContentNavigation();
	const location = useLocation();

	useEffect(() => {
		const hash = location.hash.slice(1);
		if (hash && meetingOutline) {
			const matchingItem = meetingOutline.find(
				(item) => item.title.toLowerCase().replace(/\s+/g, "-") === hash,
			);
			if (matchingItem) {
				handleMeetingOutlineItemClick(matchingItem.id, matchingItem.title);
			}
		}
	}, [location.hash, meetingOutline, handleMeetingOutlineItemClick]);

	const meetingOutlineItems = useMemo(
		() =>
			meetingOutline?.map((content: any) => ({
				text: content.title,
				id: content.id,
			})) || [],
		[meetingOutline],
	);

	const renderMeetingOutlineItems = () => {
		if (isLoading) {
			return (
				<Flex direction="column" gap="2px">
					{[...Array(5)].map((_, index) => (
						<Flex
							key={index}
							align="center"
							justify="between"
							style={{ padding: "8px 16px" }}
						>
							<Skeleton width="80%" height="20px" />
							<Skeleton width="16px" height="16px" />
						</Flex>
					))}
				</Flex>
			);
		}

		return (
			<MeetingOutlineItemsContainer
				direction={isMobile ? "row" : "column"}
				gap="2px"
				style={{ padding: "0px 10px", overflow: "scroll" }}
			>
				{meetingOutlineItems.map((item, index) => (
					<MeetingOutlineItem
						justify="between"
						selected={activeItem === item.id ? "true" : "false"}
						key={`${item.id}-${index}`}
						onClick={() => handleMeetingOutlineItemClick(item.id, item.text)}
					>
						<MeetingOutlineItemText size="2" weight="medium">
							{item.text}
						</MeetingOutlineItemText>
						{!isMobile && (
							<Cube color={activeItem === item.id ? "blue" : "gray"} />
						)}
					</MeetingOutlineItem>
				))}
			</MeetingOutlineItemsContainer>
		);
	};

	if (isMobile) {
		return (
			<>
				<MobileOutlineBar
					onClick={toggleSidebar}
					style={{
						borderBottom: isSidebarOpen ? "1px solid var(--mb-gray-5)" : "none",
					}}
				>
					{/* <Icon.ListBullets weight="bold" size={20} /> */}
					<Text size="3" weight="medium">
						Meeting Outline
					</Text>
					<Icon.CaretDown
						style={{
							transform: isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)",
							transition: "transform 0.3s ease",
						}}
					/>
				</MobileOutlineBar>
				<MobileOutlineContent isopen={isSidebarOpen}>
					{renderMeetingOutlineItems()}
				</MobileOutlineContent>
			</>
		);
	}

	return (
		<MeetingOutlineContainer
			align={isSidebarOpen ? "start" : "center"}
			style={{ maxWidth: isSidebarOpen ? "270px" : "80px" }}
		>
			{isSidebarOpen ? (
				<Flex
					direction="column"
					gap="10px"
					style={{ width: "100%", overflow: "hidden" }}
				>
					<MeetingOutlineHeaderContainer align="center" justify="between">
						<Icon.ListBullets
							weight="bold"
							size={isSidebarOpen ? 16 : 40}
							onClick={toggleSidebar}
						/>
						<Text size="3" weight="medium" style={{ flex: 1 }}>
							Meeting Outline
						</Text>
						<Icon.ArrowLineLeft
							style={{ cursor: "pointer" }}
							onClick={toggleSidebar}
						/>
					</MeetingOutlineHeaderContainer>
					<MeetingOutlineContentContainer direction="column">
						{renderMeetingOutlineItems()}
					</MeetingOutlineContentContainer>
				</Flex>
			) : (
				<MeetingOutlineSidebarToggleButton
					onClick={toggleSidebar}
					align="center"
				>
					<Icon.ListBullets size={20} weight="thin" />
				</MeetingOutlineSidebarToggleButton>
			)}
		</MeetingOutlineContainer>
	);
};

export default MeetingOutline;
