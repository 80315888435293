import React, { useCallback, useState, useRef, useEffect } from "react";
import { cn } from "../../utils/classNames";
import { IconButton } from "@mightybot/web-ui";
import { CaretLeft } from "@phosphor-icons/react";
import { motion, AnimatePresence } from "framer-motion";
import { useGetMeetingVideoQuery } from "@mightybot/core";
import { VideoJsPlayer } from "../VideoPlayer";
import TranscriptView from "../VideoPlayer/TranscriptView";
import { useParams } from "react-router-dom";
import { isGoogleSignedUrlExpired } from "../../utils/helpers";

export const ToggleButton = ({
	onClick,
	isExpanded,
}: {
	onClick: () => void;
	isExpanded: boolean;
}) => (
	<IconButton
		onClick={onClick}
		radius="full"
		className={cn(
			"bg-white",
			"cursor-pointer",
			"border-[#E0E0E0]",
			"border",
			"border-solid",
			"absolute",
			"top-3",
			"right-5",
		)}
	>
		<CaretLeft
			width="16"
			height="16"
			className={cn(
				"text-[#333333]",
				"transition-transform",
				"duration-300",
				isExpanded ? "rotate-180" : "rotate-0",
			)}
		/>
	</IconButton>
);

interface ResizableRightSidebarProps {
	isOpen?: boolean;
}

const ResizableRightSidebar: React.FC<ResizableRightSidebarProps> = ({
	isOpen,
}) => {
	const meetingId = useParams().meetingId;
	const {
		data,
		isLoading: isLoadingVideo,
		refetch,
	} = useGetMeetingVideoQuery({
		meeting_id: meetingId ?? "",
	});
	const playerRef = useRef<any>(null);

	const [isExpanded, setIsExpanded] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);

	const sidebarWidth = isExpanded ? 564 : 350;

	const checkAndRefreshUrl = useCallback(() => {
		if (!data?.video_url) return;

		try {
			const isExpired = isGoogleSignedUrlExpired(data.video_url);
			if (isExpired) {
				refetch();
			}
		} catch (error) {
			console.error("Error checking URL expiration:", error);
		}
	}, [data?.video_url, refetch]);

	const handleTimeUpdate = useCallback(
		(time: number) => {
			setCurrentTime(time);
			checkAndRefreshUrl();
		},
		[checkAndRefreshUrl],
	);

	const handleExpand = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	const handleSeek = useCallback((time: number) => {
		if (playerRef.current) {
			playerRef.current.currentTime(time);
		}
	}, []);

	const videoJsOptions = {
		sources: [
			{
				src: data?.video_url,
				type: "video/mp4",
			},
		],
		controls: true,
		fluid: true,
		preload: "auto",
		controlBar: {
			volumePanel: {
				inline: false,
			},
		},
	};

	return (
		<AnimatePresence mode="sync" initial={false}>
			{isOpen && (
				<motion.div
					id="sidebar"
					key="sidebar"
					initial={{ width: 0 }}
					animate={{ width: sidebarWidth }}
					exit={{ width: 0 }}
					transition={{
						duration: 0.3,
						ease: "easeInOut",
					}}
					className={cn(
						"bg-[var(--mb-color-light-gray-3)]",
						"h-full",
						"overflow-hidden",
						"relative",
						"min-w-[350px]",
						"flex-shrink-0",
					)}
				>
					<div className={cn("h-full flex flex-col")}>
						<div className="p-4 flex-shrink-0">
							<h2 className="font-medium mb-4 text-base">Transcript</h2>
							<div>
								<VideoJsPlayer
									isLoading={isLoadingVideo}
									ref={playerRef}
									options={videoJsOptions}
									setCurrentTime={handleTimeUpdate}
									onExpand={handleExpand}
									src={data?.video_url}
									onError={checkAndRefreshUrl}
								/>
							</div>
						</div>
						<div className="flex-1 overflow-hidden">
							<TranscriptView currentTime={currentTime} onSeek={handleSeek} />
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default ResizableRightSidebar;
