import styled from "styled-components";
import * as NavigationMenuRadix from "@radix-ui/react-navigation-menu";
import { Flex, mediaQueries, Text } from "@mightybot/web-ui";

export const NavBarContainer = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 110px;
	padding: 20px 25px 20px 0px;
	z-index: 0;
	background: linear-gradient(180deg, #071a40 0%, #032874 100%), var(--gray-1);
	display: flex;
	flex-direction: column;
	align-items: center;
	${mediaQueries.tablet} {
		display: none;
	}
`;

export const NavBarLogo = styled.img`
	width: 32px;
	height: 32px;
	margin-bottom: 30px;
`;

export const UserAvatar = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 50%;
`;

export const NavigationMenu = styled(Flex)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${mediaQueries.tablet} {
		align-items: flex-start;
		height: 100%;
		justify-content: space-between;
	}
`;

export const NavigationMenuItem = styled(NavigationMenuRadix.Item)`
	width: 46px;
	list-style-type: none;
	${mediaQueries.tablet} {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		height: 44px;
	}
`;

export const NavigationMenuLink = styled(Flex)<{
	active: string;
	isMobile?: string;
}>`
	color: var(--gray-1);
	gap: 6px;
	opacity: ${(props) => (props.active === "true" ? 1 : 0.5)};
	transition: opacity 0.2s ease-in-out;
	&:hover {
		opacity: 1;
	}
	${mediaQueries.tablet} {
		flex-direction: row;
		gap: 24px;
		color: var(--gray-12);
	}
`;

export const IconBackground = styled(Flex)<{ active: string }>`
	width: 100%;
	height: 30px;
	border-radius: var(--radius-pill);
	background-color: ${({ active }) =>
		active === "true" ? "rgba(255, 255, 255, 0.1)" : "transparent"};
	transition: background-color 0.2s ease-in-out;
`;

export const UserPopoverContainer = styled(Flex)`
	margin: 8px;
	${mediaQueries.tablet} {
		margin: 0;
	}
`;

export const MobileNavBar = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background: linear-gradient(90deg, #071a40 0%, #032874 100%),
		var(--surface-primary, #fff);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	max-height: 50px;
`;

export const MobileNavBarOverlay = styled(Flex)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.3s ease-in-out,
		visibility 0.3s ease-in-out;

	&.open {
		opacity: 1;
		visibility: visible;
	}
`;

export const MobileNavBarContent = styled(Flex)`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 80%;
	max-width: 300px;
	background-color: white;
	overflow-y: auto;
	z-index: 1002;
	padding: 20px;
	flex-direction: column;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;

	&.open {
		transform: translateX(0);
	}

	&.closing {
		transform: translateX(-100%);
	}
`;

export const ProfileFlyout = styled(Flex)`
	background: white;
	border-radius: 4px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	padding-bottom: 6px;
	width: 180px;
	max-width: 180px;
	flex-direction: column;
	padding: 0px 6px 6px 6px;
`;

export const ProfileOption = styled(Flex)`
	gap: 8px;
	cursor: pointer;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	height: 30px;
	padding: 0px 8px;
	align-items: center;
	width: 100%;
	${mediaQueries.tablet} {
		justify-content: flex-start;
		height: 44px;
	}
`;

export const ProfileContainer = styled(Flex)`
	width: 100%;
`;

export const NavItemLabel = styled(Text)`
	line-height: 10px;
	font-size: 12px;
	font-weight: 400;
	${mediaQueries.tablet} {
		font-size: 14px;
		font-weight: 500;
	}
`;
