import React, { useEffect, useState } from "react";

import {
	Flex,
	Text,
	Icon,
	Spinner,
	LazyIcon,
	Skeleton,
} from "@mightybot/web-ui";

import {
	OverlappingIcons,
	ContentHeader,
	ContentBox,
	ContentBody,
	Content,
	ConnectButton,
} from "./styled";
import {
	GoogleAppType,
	UPDATE_INTEGRATIONS,
	useIntegrationConnect,
	useIntegrations,
	useMe,
} from "@mightybot/core";
import { useIntegrationLogos } from "@mightybot/core-ui";
import WhatYoullGet from "./WhatYoullGet.json";
import { useNavigate } from "react-router-dom";

const requiredIntegrations = [
	"gmail",
	"google_calendar",
	"google_drive",
	"google_docs",
	"google_sheets",
];

type OnboardingContentProps = {
	styles?: React.CSSProperties;
	overlay?: boolean;
};

export const OnboardingContent = ({
	styles,
	overlay,
}: OnboardingContentProps) => {
	const [isConnecting, setIsConnecting] = useState(false);

	const navigate = useNavigate();

	const { getIntegrationLogo } = useIntegrationLogos();
	const { data: integrations, isLoading: isLoadingIntegrations } =
		useIntegrations();

	const requiredIntegrationsData = integrations?.filter((integration) =>
		requiredIntegrations.includes(integration.id),
	);
	const { connectGoogleAppBatch } = useIntegrationConnect();
	const { refetch: refetchUser } = useMe();

	useEffect(() => {
		const listener = (event: MessageEvent) => {
			if (event.data.type === UPDATE_INTEGRATIONS) {
				refetchUser();
				setIsConnecting(false);
				if (!overlay) {
					const navigateTo = "/home";
					navigate(navigateTo);
				}
			}
		};
		window.addEventListener("message", listener);
		return () => window.removeEventListener("message", listener);
	}, []);

	const handleConnect = async () => {
		if (requiredIntegrationsData) {
			setIsConnecting(true);
			await connectGoogleAppBatch(requiredIntegrationsData as GoogleAppType[]);
		}
	};
	return (
		<ContentBox direction="column" align="center" style={{ ...styles }}>
			<ContentHeader direction="column" align="center">
				<OverlappingIcons>
					{!requiredIntegrationsData && (
						<>
							{[...Array(5)].map((_, index) => (
								<Flex key={index} justify="center" align="center">
									<Skeleton
										key={index}
										width="50px"
										height="50px"
										style={{
											marginLeft: index > 0 ? "-10px" : "0",
											zIndex: 5 - index,
											borderRadius: "50%",
										}}
									/>
								</Flex>
							))}
						</>
					)}
					{requiredIntegrationsData &&
						requiredIntegrations?.map((integration, index) => (
							<Flex
								justify="center"
								align="center"
								key={integration}
								style={{
									marginLeft: index > 0 ? "-10px" : "0",
									zIndex: requiredIntegrations.length - index,
									width: "50px",
									height: "50px",
								}}
							>
								<LazyIcon
									src={getIntegrationLogo(integration as string) as string}
									alt={integration as string}
								/>
							</Flex>
						))}
				</OverlappingIcons>
				<Text weight="bold" size="4">
					Connect Google Workspace to start your automations
				</Text>
			</ContentHeader>
			<ContentBody direction="column">
				<Content direction="column" align="start">
					<Text style={{ marginBottom: "5px" }} weight="bold">
						What you’ll get
					</Text>
					{WhatYoullGet.map((item) => (
						<Flex
							key={item as string}
							direction="row"
							align="start"
							style={{ gap: "10px" }}
						>
							<Icon.Check
								size="18px"
								style={{ color: "var(--mb-blue-8)", flexShrink: 0 }}
							/>
							<Text
								weight="light"
								style={{ color: "var(--mb-color-dark-gray-4)" }}
							>
								{item as string}
							</Text>
						</Flex>
					))}
				</Content>
				{/* @ts-ignore */}
				<ConnectButton
					onClick={handleConnect}
					disabled={isConnecting || isLoadingIntegrations}
				>
					{isConnecting || isLoadingIntegrations ? (
						<Spinner weight="fill" size={22} />
					) : (
						"Connect"
					)}
				</ConnectButton>
			</ContentBody>
		</ContentBox>
	);
};
