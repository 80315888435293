import React, { useEffect, useState, useRef } from "react";
import {
	StyledBox,
	StyledTextField,
	PastSearchesContainer,
	PastSearchItem,
	SearchQueryText,
	ResultCount,
	MentionPopover,
	MentionPopoverContent,
	MentionItem,
	NoResultsItem,
} from "./styled";
import {
	Icon,
	TextField,
	IconButton,
	Separator,
	Flex,
	Text,
	AvatarWrapper,
	Skeleton,
} from "@mightybot/web-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	Search,
	useSearch,
	useSearchPastSearches,
	useSearchSelection,
	useRelevantPeople,
	Person,
} from "@mightybot/core";
import debounce from "lodash/debounce";
import { UUID } from "crypto";
import { getCursorOffset } from "../../utils/helpers";

interface SearchBarProps {
	clearSearch: () => void;
	isDialog: boolean;
	handleSearch: (query: string) => Promise<void>;
	isFirstRender: React.MutableRefObject<boolean>;
}

interface MentionData {
	displayText: string;
	email: string;
	startIndex: number;
	endIndex: number;
}

const MENTION_ITEM_HEIGHT = 56; // Height of each mention item in pixels

const MentionLoadingState = () => (
	<Flex direction="column" style={{ padding: "10px" }}>
		{[1, 2, 3, 4].map((i) => (
			<Flex key={i} gap="4px" align="center" style={{ padding: "8px" }}>
				<Skeleton width="24px" height="24px" style={{ borderRadius: "50%" }} />
				<Flex direction="column" gap="4px" style={{ flex: 1 }}>
					<Skeleton height="16px" width="120px" />
					<Skeleton height="14px" width="160px" />
				</Flex>
			</Flex>
		))}
	</Flex>
);

const SearchBar = ({
	clearSearch,
	isDialog,
	handleSearch,
	isFirstRender,
}: SearchBarProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const activeSearchId = searchParams.get("searchId") as UUID;
	const { activeSearch, userSearches } = useSearch(activeSearchId);
	const { selectedResults, clearSelection } = useSearchSelection();
	const [searchQuery, setSearchQuery] = useState("");
	const { pastSearches, searchPastSearches } = useSearchPastSearches();
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const [isFocused, setIsFocused] = useState(false);
	const [showMentionPopover, setShowMentionPopover] = useState(false);
	const [mentionSearchTerm, setMentionSearchTerm] = useState("");
	const [cursorPosition, setCursorPosition] = useState(0);
	const [selectedMentionIndex, setSelectedMentionIndex] = useState(-1);
	const [activeMentions, setActiveMentions] = useState<MentionData[]>([]);

	const { people, isLoading: isLoadingPeople } = useRelevantPeople({
		searchTerm: mentionSearchTerm,
		enabled: showMentionPopover,
	});

	const debouncedSearch = useRef(
		debounce((query: string) => {
			searchPastSearches(query);
		}, 300),
	).current;

	const searchContainerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const mentionPopoverRef = useRef<HTMLDivElement>(null);

	// Add effect to clean up states when activeSearchId changes
	useEffect(() => {
		if (!activeSearchId) {
			setSearchQuery("");
			setIsResultsVisible(false);
			setSelectedIndex(-1);
			setIsFocused(false);
		}
	}, [activeSearchId]);

	// Modify the searchQuery effect
	useEffect(() => {
		if (searchQuery) {
			debouncedSearch(searchQuery);
		}
	}, [searchQuery, setSearchParams, debouncedSearch]);

	useEffect(() => {
		if (activeSearch) {
			setSearchQuery(activeSearch.query || "");
		} else {
			setSearchQuery("");
		}
	}, [activeSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(event.target as Node)
			) {
				setIsResultsVisible(false);
				setIsFocused(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setSelectedIndex(-1);
	}, [searchQuery]);

	useEffect(() => {
		if (isDialog && !activeSearchId && selectedResults.length === 0) {
			inputRef.current?.focus();
		}
	}, [isDialog, activeSearchId, selectedResults]);

	useEffect(() => {
		setSelectedMentionIndex(-1);
	}, [people]);

	// Add this helper function to detect mentions in the text
	const getMentions = (text: string): MentionData[] => {
		return activeMentions.filter(
			(mention) =>
				text.substring(mention.startIndex, mention.endIndex) ===
				mention.displayText,
		);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchQuery(value);

		// Get cursor position
		const curPos = e.target.selectionStart || 0;
		setCursorPosition(curPos);

		// Check for @ mentions
		const beforeCursor = value.slice(0, curPos);
		const matches = beforeCursor.match(/@([^@\s]*)$/);

		if (matches) {
			setMentionSearchTerm(matches[1]);
			setShowMentionPopover(true);
			setTimeout(() => {
				inputRef.current?.focus();
			}, 0);
		} else {
			setShowMentionPopover(false);
		}

		// Original search logic
		setIsResultsVisible(true);
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Handle backspace for mentions
		if (e.key === "Backspace" && !showMentionPopover) {
			const curPos = inputRef.current?.selectionStart || 0;
			const mentions = getMentions(searchQuery);

			// Find if cursor is at the end of any mention
			const mentionToDelete = mentions.find((mention) => {
				const mentionEnd = mention.endIndex; // +1 for the space after mention
				return curPos === mentionEnd;
			});

			if (mentionToDelete) {
				e.preventDefault();
				const newQuery =
					searchQuery.slice(0, mentionToDelete.startIndex) +
					searchQuery.slice(mentionToDelete.endIndex);

				setSearchQuery(newQuery);

				// Set cursor position to where the mention started
				setTimeout(() => {
					if (inputRef.current) {
						const newPos = mentionToDelete.startIndex;
						inputRef.current.setSelectionRange(newPos, newPos);
					}
				}, 0);
				return;
			}
		}

		if (showMentionPopover && people.length > 0) {
			switch (e.key) {
				case "ArrowDown":
					e.preventDefault();
					setSelectedMentionIndex((prev) => {
						const newIndex = prev < people.length - 1 ? prev + 1 : prev;
						// Scroll into view
						if (mentionPopoverRef.current) {
							const scrollTop = mentionPopoverRef.current.scrollTop;
							const containerHeight = mentionPopoverRef.current.clientHeight;
							const itemPosition = newIndex * MENTION_ITEM_HEIGHT;

							if (
								itemPosition + MENTION_ITEM_HEIGHT >
								scrollTop + containerHeight
							) {
								mentionPopoverRef.current.scrollTop =
									itemPosition + MENTION_ITEM_HEIGHT - containerHeight;
							}
						}
						return newIndex;
					});
					return;
				case "ArrowUp":
					e.preventDefault();
					setSelectedMentionIndex((prev) => {
						const newIndex = prev > -1 ? prev - 1 : -1;
						// Scroll into view
						if (mentionPopoverRef.current && newIndex >= 0) {
							const scrollTop = mentionPopoverRef.current.scrollTop;
							const itemPosition = newIndex * MENTION_ITEM_HEIGHT;

							if (itemPosition < scrollTop) {
								mentionPopoverRef.current.scrollTop = itemPosition;
							}
						}
						return newIndex;
					});
					return;
				case "Enter":
					e.preventDefault();
					if (selectedMentionIndex >= 0) {
						handleMentionSelect(people[selectedMentionIndex]);
						return;
					}
					break;
				case "Escape":
					e.preventDefault();
					setShowMentionPopover(false);
					setSelectedMentionIndex(-1);
					return;
				case "Tab":
					if (selectedMentionIndex >= 0) {
						e.preventDefault();
						handleMentionSelect(people[selectedMentionIndex]);
						return;
					}
					break;
			}
		}

		// Original keyboard handling for search
		if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && pastSearches && pastSearches[selectedIndex]) {
				await onSearch(pastSearches[selectedIndex].query);
			} else {
				await onSearch(searchQuery);
			}
		} else if (e.key === "ArrowDown" && !showMentionPopover) {
			e.preventDefault();
			if (!isResultsVisible) {
				setIsResultsVisible(true);
				setIsFocused(true);
				if (!searchQuery) {
					searchPastSearches("");
				}
				setSelectedIndex(-1);
				return;
			}

			if (visiblePastSearches?.length) {
				setSelectedIndex((prev) =>
					prev < visiblePastSearches.length - 1 ? prev + 1 : prev,
				);
			}
		} else if (e.key === "ArrowUp" && !showMentionPopover) {
			e.preventDefault();
			if (isResultsVisible && visiblePastSearches?.length) {
				setSelectedIndex((prev) => (prev > -1 ? prev - 1 : -1));
				if (selectedIndex === 0) {
					setIsResultsVisible(false);
					setIsFocused(false);
				}
			}
		} else if (e.key === "Escape" && !showMentionPopover) {
			e.preventDefault();
			setIsResultsVisible(false);
			setIsFocused(false);
			setSelectedIndex(-1);
		}
	};

	const onSearch = async (query: string) => {
		let finalQuery = query;

		// Sort mentions by startIndex in reverse order to avoid position shifts
		const sortedMentions = [...activeMentions].sort(
			(a, b) => b.startIndex - a.startIndex,
		);

		// Replace each mention's display text with its email
		for (const mention of sortedMentions) {
			finalQuery =
				finalQuery.slice(0, mention.startIndex - 1) + // -1 to include @
				`${mention.email}` +
				finalQuery.slice(mention.endIndex);
		}

		setIsResultsVisible(false);
		setIsFocused(false);
		setSelectedIndex(-1);
		if (!isDialog) {
			!activeSearchId && (isFirstRender.current = true);
			clearSelection();
		}
		await handleSearch(finalQuery);
	};

	const handleClearSearch = () => {
		setSearchQuery("");
		setActiveMentions([]);
		clearSearch();
		clearSelection();

		const params = new URLSearchParams(searchParams);
		const filteredParams = Array.from(params.entries()).filter(
			([key]) => key !== "q" && key !== "searchId",
		);

		setSearchParams(Object.fromEntries(filteredParams));
		setIsResultsVisible(false);
		setSelectedIndex(-1);
	};

	const handlePastSearchClick = (pastSearch: Search) => {
		isFirstRender.current = true;
		setIsResultsVisible(false);
		setIsFocused(false);
		setSelectedIndex(-1);

		if (!isDialog) {
			clearSelection();
			navigate(`/search?searchId=${pastSearch.id}&q=${pastSearch.query}`);
		} else {
			const params = new URLSearchParams(searchParams);
			const existingParams = Object.fromEntries(params.entries());
			setSearchParams({
				...existingParams,
				q: pastSearch.query,
				searchId: pastSearch.id,
			});
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
		if (!searchQuery) {
			searchPastSearches("");
		}
	};

	const handleBlur = () => {
		// Don't set isFocused to false here as we want to handle this in the clickOutside handler
	};

	const visiblePastSearches = searchQuery
		? pastSearches
		: userSearches?.slice(0, 5) || [];

	const isPastSearchesVisible =
		(isFocused || isResultsVisible) &&
		visiblePastSearches &&
		visiblePastSearches.length > 0;

	const handleMentionSelect = (person: Person) => {
		const beforeCursor = searchQuery.slice(0, cursorPosition);
		const lastAtPos = beforeCursor.lastIndexOf("@");

		const displayText = person.name || person.email;
		const newMention: MentionData = {
			displayText,
			email: person.email,
			startIndex: lastAtPos + 1, // +1 to skip the @
			endIndex: lastAtPos + 1 + displayText.length,
		};

		const newQuery =
			searchQuery.slice(0, lastAtPos) +
			`@${displayText} ` +
			searchQuery.slice(cursorPosition);

		setSearchQuery(newQuery);
		setActiveMentions((prev) => [...prev, newMention]);
		setShowMentionPopover(false);
		setSelectedMentionIndex(-1);

		// Set cursor position after the inserted mention
		const newCursorPos = lastAtPos + displayText.length + 2; // +2 for @ and space
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
				inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
			}
		}, 0);
	};

	// Calculate popover position based on cursor position
	const getPopoverPosition = () => {
		if (!inputRef.current) return { top: 0, left: 0 };

		const input = inputRef.current;
		const inputRect = input.getBoundingClientRect();
		const cursorOffset = getCursorOffset(input, cursorPosition);

		return {
			top: inputRect.bottom + window.scrollY + 4,
			left: inputRect.left + cursorOffset.left,
		};
	};

	// Add this effect to handle incoming search queries
	useEffect(() => {
		if (activeSearch?.query) {
			// Clear mentions when a new search is loaded
			setActiveMentions([]);
		}
	}, [activeSearch?.query]);

	return (
		<StyledBox width="100%" position="relative" ref={searchContainerRef}>
			<StyledTextField
				ref={inputRef}
				placeholder="Search Files, Docs, Emails, Slack, Meetings, Linear"
				size="3"
				value={searchQuery}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				onBlur={handleBlur}
				isexpanded={isPastSearchesVisible ? "true" : "false"}
			>
				<TextField.Slot>
					<Icon.MagnifyingGlass width="16" height="16" />
				</TextField.Slot>
				<TextField.Slot pr="3">
					{searchQuery && (
						<IconButton
							size="2"
							variant="ghost"
							onClick={handleClearSearch}
							style={{ cursor: "pointer" }}
						>
							<Icon.X width="16" height="16" />
						</IconButton>
					)}
				</TextField.Slot>
			</StyledTextField>
			{/* Mention Popover */}
			{showMentionPopover && (
				<MentionPopover
					open={showMentionPopover}
					onOpenChange={(open) => {
						if (open) {
							inputRef.current?.focus();
						}
					}}
				>
					<MentionPopoverContent
						ref={mentionPopoverRef}
						style={{
							position: "absolute",
							...getPopoverPosition(),
							zIndex: 1000,
							overflowY: "auto",
						}}
						id="mention-popover-content"
					>
						{isLoadingPeople ? (
							<MentionLoadingState />
						) : people.length === 0 ? (
							<NoResultsItem>No people found</NoResultsItem>
						) : (
							<Flex direction="column" gap="2px" width="100%">
								{people.map((person, index) => (
									<MentionItem
										key={person.email}
										onClick={() => handleMentionSelect(person)}
										isselected={(index === selectedMentionIndex).toString()}
										role="option"
										aria-selected={index === selectedMentionIndex}
									>
										<AvatarWrapper
											src={person.profile_pic || ""}
											alt={person.name || person.email}
											size="1"
											radius="full"
											fallback={person.name?.[0] || person.email[0]}
										/>
										<Flex direction="column">
											<Text weight="medium">{person.name || person.email}</Text>
											{person.name && (
												<Text size="1" style={{ color: "var(--mb-gray-7)" }}>
													{person.email}
												</Text>
											)}
										</Flex>
									</MentionItem>
								))}
							</Flex>
						)}
					</MentionPopoverContent>
				</MentionPopover>
			)}
			<PastSearchesContainer
				isvisible={isPastSearchesVisible ? "true" : "false"}
			>
				{isPastSearchesVisible && pastSearches && pastSearches.length > 0 && (
					<Separator
						style={{
							width: "calc(100% - 24px)",
							marginBottom: "10px",
							flexShrink: 0,
							alignSelf: "center",
						}}
					/>
				)}
				{isPastSearchesVisible &&
					visiblePastSearches &&
					visiblePastSearches.map((search, index) => (
						<PastSearchItem
							key={index}
							onClick={() => handlePastSearchClick(search)}
							isselected={index === selectedIndex ? "true" : "false"}
						>
							<SearchQueryText>
								<Icon.MagnifyingGlass width="16" height="16" />
								{search.query}
							</SearchQueryText>
							{search.results_count && (
								<ResultCount>
									{search.results_count ?? 0}{" "}
									{search.results_count === 1 ? "result" : "results"}
								</ResultCount>
							)}
						</PastSearchItem>
					))}
			</PastSearchesContainer>
		</StyledBox>
	);
};

export default SearchBar;
