import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMeetingContentHover } from "../../contexts/MeetingContentHoverContext";
import { useMeetingContent } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { MeetingContentItem } from "@mightybot/core";
import {
	CustomReactMarkdown,
	Flex,
	Text,
	Icon,
	Skeleton,
	Tooltip,
} from "@mightybot/web-ui";
import { WidgetPosition } from "./MeetingContentScroller";
import { MeetingContentDetailsContainer, FocusableContent } from "./styled";
import MeetingContentWidget from "./MeetingContentWidget";
import { EmptyMeetingFrame } from "@mightybot/core-ui";

type MeetingContentItemWithParagraphs = MeetingContentItem & {
	paragraphs: string[];
};

interface MeetingContentDetailsProps {
	showWidget: boolean;
}

const MeetingContentDetails: React.FC<MeetingContentDetailsProps> = ({
	showWidget,
}) => {
	const meetingId = useParams().meetingId;
	const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
	const contentBodyRef = useRef<HTMLDivElement>(null);

	const { setHighlightedItem, setHoverData } = useMeetingContentHover();
	const { meetingContent, isLoading } = useMeetingContent(meetingId ?? "");

	const {
		handleSectionVisible,
		activeItem,
		isScrollTriggeredByClick,
		containerRef,
	} = useMeetingContentNavigation();

	const [widgetPosition, setWidgetPosition] = useState<WidgetPosition | null>(
		null,
	);
	const [isCopied, setIsCopied] = useState(false);
	useEffect(() => {
		if (isScrollTriggeredByClick && containerRef.current) {
			const activeElement = containerRef.current.querySelector(
				`[data-content-id="${activeItem}"]`,
			);
			const titleElement = containerRef.current.querySelector(
				`[data-title="${meetingId}-title"]`,
			);
			if (activeElement) {
				const offset = (titleElement?.getBoundingClientRect().height ?? 0) + 10;
				const containerRect = containerRef.current.getBoundingClientRect();
				const elementRect = activeElement.getBoundingClientRect();
				const relativeTop = elementRect.top - containerRect.top;

				const newScrollTop =
					containerRef.current.scrollTop + relativeTop - offset;

				containerRef.current.scrollTo({
					top: newScrollTop,
					behavior: "smooth",
				});
			}
		}
	}, [activeItem, isScrollTriggeredByClick]);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const contentId = entry.target.getAttribute("data-content-id");
						if (contentId) {
							handleSectionVisible(contentId);
						}
					}
				});
			},
			{ threshold: 1 },
		);

		meetingContent?.forEach((item: MeetingContentItem) => {
			const element = itemRefs.current[item.id];
			if (element) {
				observer.observe(element);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [meetingContent, handleSectionVisible]);

	useEffect(() => {
		const handleTextSelection = () => {
			const selection = window.getSelection();
			if (selection && !selection.isCollapsed) {
				const selectedText = selection.toString().trim();
				if (selectedText) {
					setHighlightedItem(selectedText);
				}
			}
		};

		// TODO : resolve highlight issue
		// document.addEventListener("selectionchange", handleTextSelection);
		// return () =>
		// 	document.removeEventListener("selectionchange", handleTextSelection);
	}, []);

	const formattedMeetingContent: MeetingContentItemWithParagraphs[] =
		useMemo(() => {
			if (!meetingContent) return [];

			return meetingContent.map((content: MeetingContentItem) => {
				const paragraphs = content.content.split("\n\n").filter(Boolean);
				return {
					...content,
					paragraphs: paragraphs,
				};
			});
		}, [meetingContent]);

	const handleTitleHover = (
		event: React.MouseEvent<HTMLDivElement>,
		item: MeetingContentItemWithParagraphs,
	) => {
		const rect = event.currentTarget.getBoundingClientRect();
		setWidgetPosition({
			top: rect.top + window.scrollY,
			left: rect.right + window.scrollX - 60,
		});
		setHoverData({
			type: "title",
			content: item.paragraphs.join("\n\n"),
			item: item,
		});
	};

	const handleParagraphHover = (
		event: React.MouseEvent<HTMLParagraphElement>,
		item: MeetingContentItemWithParagraphs,
		paragraph: string,
	) => {
		const rect = event.currentTarget.getBoundingClientRect();
		const contentPromptElement = document
			.getElementById(`content-prompt`)
			?.getBoundingClientRect();

		let posTop = rect.top + window.scrollY;
		if (posTop < 120) {
			posTop = 120;
		} else if (
			contentPromptElement &&
			posTop > contentPromptElement.top - 116
		) {
			posTop = contentPromptElement.top - 116;
		}

		setWidgetPosition({
			top: posTop,
			left: rect.right + window.scrollX - 60,
		});
		setHoverData({
			type: "paragraph",
			content: paragraph,
			item: item,
		});
	};

	const handleSummaryCopy = () => {
		const summary = formattedMeetingContent
			.map((item) => item.paragraphs.join("\n\n"))
			.join("\n\n");
		navigator.clipboard.writeText(summary);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	if (isLoading) {
		return (
			<MeetingContentDetailsContainer direction="column" ref={contentBodyRef}>
				{[...Array(5)].map((_, index) => (
					<Flex
						key={index}
						direction="column"
						gap="16px"
						style={{ marginBottom: "24px" }}
					>
						<Flex align="center" gap="8px">
							<Skeleton width="20px" height="20px" />
							<Skeleton width="200px" height="24px" />
						</Flex>
						<Skeleton width="100%" height="16px" />
						<Skeleton width="100%" height="16px" />
						<Skeleton width="80%" height="16px" />
					</Flex>
				))}
			</MeetingContentDetailsContainer>
		);
	}

	return (
		<MeetingContentDetailsContainer direction="column" ref={contentBodyRef}>
			{formattedMeetingContent.length === 0 && (
				<Flex
					align="center"
					justify="center"
					direction="column"
					style={{ width: "100%", marginTop: "10%" }}
				>
					<img src={EmptyMeetingFrame as string} />
					<Text size="4" weight="medium" style={{ marginBottom: "14px" }}>
						Meeting Summary & Transcript is being generated{" "}
					</Text>
					<Text size="2">Please check again in 10-15 minutes.</Text>
				</Flex>
			)}
			{formattedMeetingContent.map(
				(item: MeetingContentItemWithParagraphs, index: number) => (
					<div
						key={`${item.id}-${index}`}
						ref={(el) => (itemRefs.current[item.id] = el)}
						data-content-id={item.id}
						data-title={item.title}
						data-content={item.content}
					>
						<Flex
							align="center"
							gap="8px"
							style={{ marginBottom: "10px" }}
							onMouseEnter={(e) => handleTitleHover(e, item)}
						>
							<Icon.FileText size={20} />
							<FocusableContent data-content={item.title}>
								<Text weight="medium" size="4">
									{item.title}
								</Text>
							</FocusableContent>
						</Flex>
						{item.paragraphs.map((paragraph, pIndex) => (
							<FocusableContent
								key={`${item.id}-p-${pIndex}`}
								onMouseEnter={(e) => handleParagraphHover(e, item, paragraph)}
								data-content={paragraph}
							>
								<CustomReactMarkdown>{paragraph}</CustomReactMarkdown>
							</FocusableContent>
						))}
					</div>
				),
			)}

			{formattedMeetingContent.length > 0 && (
				<Flex
					align="center"
					justify="between"
					gap="8px"
					key="end-of-summary"
					onMouseLeave={() => setWidgetPosition(null)}
					style={{ width: "100%" }}
				>
					<Flex align="center" gap="4px">
						<Icon.Flag weight="bold" color="gray" />
						<Text size="4" weight="medium" style={{ color: "var(--mb-gray-7" }}>
							End of summary.
						</Text>
					</Flex>
					<Flex
						align="center"
						onClick={handleSummaryCopy}
						style={{ cursor: "pointer" }}
						gap="4px"
					>
						<Tooltip content="Copy" side="top">
							{isCopied ? <Icon.Check /> : <Icon.Copy />}
						</Tooltip>
					</Flex>
				</Flex>
			)}

			{widgetPosition && showWidget && (
				<MeetingContentWidget widgetPosition={widgetPosition} />
			)}
		</MeetingContentDetailsContainer>
	);
};

export default MeetingContentDetails;
