import React, { useState } from "react";
import {
	Flex,
	Text,
	Icon,
	Switch,
	TextArea,
	Checkbox,
	Tooltip,
} from "@mightybot/web-ui";
import { Autopilot, Log } from "@mightybot/core";
import Pagination from "../Pagination";
import LogsOutputDialog from "./LogsOutputDialog";

interface MilestoneChange {
	promptIndex: number;
	milestoneIndex: number;
	old: string;
	new: string;
}

interface LogChanges {
	agentType?: {
		old: string;
		new: string;
	};
	prompts?: {
		old: any[];
		new: any[];
	};
	milestoneChanges?: MilestoneChange[];
}

interface ExtendedLog extends Log {
	configChanged?: boolean;
	changes?: LogChanges;
}

interface Milestone {
	evidence?: string;
	milestone_name: string;
	reasoning?: string;
	result: boolean;
}

interface Prompt {
	content?: string;
	text?: string;
	prompt?: string;
	milestones?: string[];
	variables?: string[];
}

type MilestoneLogsProps = {
	logs: Log[];
	autopilot?: Partial<Autopilot>;
	total: number;
	page: number;
	perPage: number;
	onPageChange: (newPage: number) => void;
	showTestOnly: boolean;
	onTestOnlyChange: (value: boolean) => void;
};

export const MilestoneLogs: React.FC<MilestoneLogsProps> = ({
	logs,
	autopilot,
	total,
	page,
	perPage,
	onPageChange,
	showTestOnly,
	onTestOnlyChange,
}) => {
	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
	const [selectedLogs, setSelectedLogs] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [selectedLog, setSelectedLog] = useState<Log | null>(null);
	const [compareMode, setCompareMode] = useState(false);

	// Define utility functions first
	const getMaxMilestones = React.useCallback(() => {
		let max = 0;
		logs.forEach((log) => {
			const config =
				typeof log.ap_config === "string"
					? JSON.parse(log.ap_config || "{}")
					: log.ap_config || {};
			const messages = config.messages || [];
			messages.forEach((message: any) => {
				const milestoneLength = message.milestones?.length ?? 0;
				if (milestoneLength > max) {
					max = milestoneLength;
				}
			});
		});
		return max;
	}, [logs]);

	const compareConfigs = React.useCallback(
		(prevLog: Log, currentLog: Log): LogChanges | null => {
			const prevConfig =
				typeof prevLog.ap_config === "string"
					? JSON.parse(prevLog.ap_config || "{}")
					: prevLog.ap_config || {};
			const currentConfig =
				typeof currentLog.ap_config === "string"
					? JSON.parse(currentLog.ap_config || "{}")
					: currentLog.ap_config || {};

			const changes: LogChanges = {};
			let hasChanges = false;

			// Check agent type changes
			if (prevConfig.agent_type !== currentConfig.agent_type) {
				changes.agentType = {
					old: prevConfig.agent_type,
					new: currentConfig.agent_type,
				};
				hasChanges = true;
			}

			// Check prompts changes
			const prevPrompts = prevConfig.messages || [];
			const currentPrompts = currentConfig.messages || [];

			if (JSON.stringify(prevPrompts) !== JSON.stringify(currentPrompts)) {
				changes.prompts = {
					old: prevPrompts,
					new: currentPrompts,
				};
				hasChanges = true;
			}

			// Check milestone changes
			const milestoneChanges: MilestoneChange[] = [];
			prevPrompts.forEach((prompt: Prompt, promptIndex: number) => {
				const prevMilestones = prompt.milestones || [];
				const currentMilestones = currentPrompts[promptIndex]?.milestones || [];

				prevMilestones.forEach((milestone: string, milestoneIndex: number) => {
					if (milestone !== currentMilestones[milestoneIndex]) {
						milestoneChanges.push({
							promptIndex,
							milestoneIndex,
							old: milestone,
							new: currentMilestones[milestoneIndex] || "",
						});
					}
				});
			});

			if (milestoneChanges.length > 0) {
				changes.milestoneChanges = milestoneChanges;
				hasChanges = true;
			}

			return hasChanges ? changes : null;
		},
		[],
	);

	const handleSort = () => {
		setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	const toggleLogSelection = React.useCallback(
		(logId: string, checked: boolean) => {
			setSelectedLogs((prev) => {
				if (!checked) {
					return prev.filter((id) => id !== logId);
				}
				if (prev.length < 2) {
					return [...prev, logId];
				}
				return [prev[1], logId];
			});
		},
		[],
	);

	const handleCompare = React.useCallback(() => {
		if (selectedLogs.length === 2) {
			const [firstLogId, secondLogId] = selectedLogs;
			const firstLog = logs.find((l) => l.id === firstLogId);
			const secondLog = logs.find((l) => l.id === secondLogId);

			if (firstLog && secondLog) {
				setSelectedLog(secondLog);
				setCompareMode(true);
				setOpen(true);
			}
		}
	}, [selectedLogs, logs]);

	// Memoized computations
	const filteredLogs = React.useMemo(() => {
		return logs.filter(
			(log) =>
				log.status === "SUCCESS" ||
				log.status === "NO_DATA" ||
				log.status === "FAILED",
		);
	}, [logs]);

	const sortedLogs = React.useMemo(() => {
		return [...filteredLogs].sort((a, b) => {
			const dateA = new Date(a.execution_time ?? 0).getTime();
			const dateB = new Date(b.execution_time ?? 0).getTime();
			return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
		});
	}, [filteredLogs, sortOrder]);

	const processedLogs = React.useMemo(() => {
		return sortedLogs.map((log, index) => {
			if (index === 0) return log;

			const prevLog = sortedLogs[index - 1];
			const changes = compareConfigs(prevLog, log);

			return {
				...log,
				configChanged: !!changes,
				changes,
			} as ExtendedLog;
		});
	}, [sortedLogs, compareConfigs]);

	const maxMilestones = React.useMemo(
		() => getMaxMilestones(),
		[getMaxMilestones],
	);

	return (
		<Flex direction="column" style={{ gap: "20px", padding: "20px" }}>
			<Flex justify="between" align="center">
				<Flex align="center" style={{ gap: "10px" }}>
					<Text>Show Test Runs Only</Text>
					<Switch
						checked={showTestOnly}
						onCheckedChange={() => onTestOnlyChange(!showTestOnly)}
						style={{ cursor: "pointer" }}
					/>
				</Flex>
				<Flex align="center" style={{ gap: "10px" }}>
					{selectedLogs.length > 0 && (
						<Text size="2">
							{selectedLogs.length} log{selectedLogs.length > 1 ? "s" : ""}{" "}
							selected
						</Text>
					)}
					{selectedLogs.length === 2 && (
						<button
							onClick={handleCompare}
							style={{
								padding: "8px 16px",
								background: "var(--mb-color-light-blue-6)",
								border: "none",
								borderRadius: "4px",
								cursor: "pointer",
							}}
						>
							Compare Logs
						</button>
					)}
				</Flex>
			</Flex>

			<div style={{ overflowX: "auto", width: "100%" }}>
				<Flex
					direction="column"
					style={{ gap: "24px", minWidth: "max-content" }}
				>
					{processedLogs.map((log: ExtendedLog) => {
						const isCompareSelected = selectedLogs.includes(log.id ?? "");
						const config =
							typeof log.ap_config === "string"
								? JSON.parse(log.ap_config || "{}")
								: log.ap_config || {};
						const prompts = config.messages || [];
						const executionTime = new Date(
							log.execution_time ?? 0,
						).toLocaleString();

						return (
							<Flex
								key={log.id}
								direction="column"
								style={{
									border: "1px solid var(--mb-color-light-gray-5)",
									borderRadius: "8px",
									overflow: "hidden",
									background: isCompareSelected
										? "var(--mb-color-light-yellow-1)"
										: log.configChanged
											? "var(--mb-color-light-blue-1)"
											: "transparent",
								}}
							>
								{/* Header Row with Time and Trace ID */}
								<Flex
									direction="column"
									style={{
										background: "var(--mb-color-light-gray-3)",
										borderBottom: "1px solid var(--mb-color-light-gray-5)",
									}}
								>
									<Flex
										align="center"
										style={{
											padding: "16px",
											gap: "16px",
											borderBottom: log.trace_url
												? "1px solid var(--mb-color-light-gray-5)"
												: "none",
										}}
									>
										<Checkbox
											checked={isCompareSelected}
											onCheckedChange={(checked: boolean) =>
												toggleLogSelection(log.id ?? "", checked)
											}
											disabled={selectedLogs.length >= 2 && !isCompareSelected}
										/>
										<Text weight="bold">{executionTime}</Text>
										<Text>{log.trace_id}</Text>
									</Flex>

									{/* Trace Links Row */}
									{log.trace_url && (
										<div style={{ overflowX: "auto" }}>
											<Flex
												align="center"
												style={{
													padding: "12px 16px",
													gap: "16px",
													minWidth: "max-content",
												}}
											>
												<Text weight="bold">Trace Links:</Text>
												{Array.isArray(log.trace_url) ? (
													log.trace_url.map((url, index) => (
														<a
															key={index}
															href={url}
															target="_blank"
															rel="noopener noreferrer"
															style={{ color: "var(--mb-color-light-blue-8)" }}
														>
															Trace Link {index + 1}
														</a>
													))
												) : (
													<a
														href={log.trace_url}
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "var(--mb-color-light-blue-8)" }}
													>
														Trace Link
													</a>
												)}
											</Flex>
										</div>
									)}
								</Flex>

								{/* Changes Row */}
								{log.configChanged && (
									<Flex
										direction="column"
										style={{
											padding: "12px 16px",
											background: "var(--mb-color-light-blue-1)",
											borderBottom: "1px solid var(--mb-color-light-gray-5)",
											gap: "8px",
										}}
									>
										<Flex align="center" style={{ gap: "8px" }}>
											<Icon.Info size="16px" />
											<Text weight="bold">Configuration Changes:</Text>
										</Flex>
										<Flex
											direction="column"
											style={{ gap: "4px", paddingLeft: "24px" }}
										>
											{log.changes?.agentType && (
												<Text>
													• Agent Type changed from "{log.changes.agentType.old}
													" to "{log.changes.agentType.new}"
												</Text>
											)}
											{log.changes?.prompts && (
												<Text>• Prompt configuration was modified</Text>
											)}
											{log.changes?.milestoneChanges?.map((change, idx) => (
												<Text key={idx}>
													• Milestone {change.milestoneIndex + 1} in Prompt{" "}
													{change.promptIndex + 1} changed from "{change.old}"
													to "{change.new}"
												</Text>
											))}
										</Flex>
									</Flex>
								)}

								{/* Prompts and Milestones */}
								{prompts.map((prompt: Prompt, promptIndex: number) => {
									const milestones = (log.milestones?.[promptIndex]
										?.milestones || []) as Milestone[];
									const promptText =
										log.milestones?.[promptIndex]?.prompt ||
										"No prompt text available";
									const promptMilestones = prompt.milestones || [];

									return (
										<div
											key={`${log.id}-prompt-${promptIndex}`}
											style={{ overflowX: "auto" }}
										>
											<Flex
												direction="column"
												style={{
													padding: "16px",
													borderBottom:
														promptIndex < prompts.length - 1
															? "1px solid var(--mb-color-light-gray-5)"
															: "none",
													minWidth: "max-content",
												}}
											>
												<Flex style={{ marginBottom: "12px" }}>
													<Tooltip
														content={
															<Flex
																direction="column"
																style={{
																	gap: "8px",
																	maxHeight: "300px",
																	overflowY: "auto",
																	maxWidth: "300px",
																	whiteSpace: "pre-wrap",
																	wordBreak: "break-word",
																}}
															>
																<Text>
																	<strong>Prompt Content:</strong>
																</Text>
																<Text>{promptText}</Text>
															</Flex>
														}
													>
														<Text style={{ cursor: "pointer" }}>
															Prompt {promptIndex + 1}
														</Text>
													</Tooltip>
												</Flex>
												<Flex style={{ gap: "12px" }}>
													{milestones.map((milestone, milestoneIndex) => (
														<Tooltip
															key={milestoneIndex}
															content={
																<Flex
																	direction="column"
																	style={{
																		gap: "8px",
																		maxHeight: "300px",
																		overflowY: "auto",
																	}}
																>
																	<Text>
																		<strong>Milestone:</strong>{" "}
																		{milestone.milestone_name}
																	</Text>
																	{milestone.reasoning && (
																		<Text>
																			<strong>Reasoning:</strong>{" "}
																			{milestone.reasoning}
																		</Text>
																	)}
																</Flex>
															}
														>
															<Flex
																align="center"
																style={{
																	padding: "8px 12px",
																	background: milestone.result
																		? "var(--mb-color-light-lime-4)"
																		: "var(--mb-color-light-red-4)",
																	borderRadius: "4px",
																	cursor: "pointer",
																	gap: "8px",
																	minWidth: "fit-content",
																}}
															>
																<Text>{milestone.milestone_name}</Text>
																<Icon.MagnifyingGlass
																	size="16px"
																	style={{ cursor: "pointer" }}
																	onClick={() => setSelectedLog(log)}
																/>
															</Flex>
														</Tooltip>
													))}
												</Flex>
											</Flex>
										</div>
									);
								})}
							</Flex>
						);
					})}
				</Flex>
			</div>

			{/* Pagination */}
			<Flex direction="column">
				<div
					style={{
						width: "100%",
						borderColor: "var(--mb-color-light-gray-5)",
						height: "1px",
						backgroundColor: "var(--mb-color-light-gray-5)",
					}}
				/>
				<Pagination
					page={page}
					total={total}
					perPage={perPage}
					onPageChange={onPageChange}
				/>
			</Flex>
			<LogsOutputDialog
				open={open}
				onClose={() => {
					setOpen(false);
					setCompareMode(false);
				}}
				log={selectedLog}
				compareLog={
					compareMode && selectedLogs.length === 2
						? (logs.find((l) => l.id === selectedLogs[0]) ?? null)
						: null
				}
			/>
		</Flex>
	);
};

export default MilestoneLogs;
