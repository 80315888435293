import React, { useState, useEffect, useRef } from "react";
import { Flex, Icon, Text, Switch, Input } from "@mightybot/web-ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	useAutopilot,
	Autopilot as AutopilotType,
	useAutopilotById,
	useDraftAutopilots,
	useMe,
	useAutopilotAuthor,
} from "@mightybot/core";
import { AutopilotForm } from "./AutoPilotForm";
import Toast from "../Toast";
import { getErrorString } from "../../utils/helpers";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { isEqual } from "lodash";
import { UUID } from "crypto";

type ToastData = {
	title: string;
	description?: string;
	status: "success" | "error" | "info";
};
const formatData = (data: Partial<AutopilotType>) => {
	const requiredKeys = [
		"name",
		"is_enabled",
		"config",
		"description",
		"id",
		"users",
		"enterprise_id",
		"shared_enterprise_ids",
	];
	return requiredKeys.reduce((acc, key) => {
		if (data[key] !== undefined) {
			acc[key] = data[key];
		}
		return acc;
	}, {});
};

const setTemplateVariables = (data: Partial<AutopilotType>) => {
	if (!data.config?.messages) {
		return data;
	}
	const regex = /{([^}]+)}/g;
	const ignoreRegex = /```.*?```/gs; // Regex to match content within triple quotes
	const updatedMessages = data.config?.messages.map((message) => {
		let match: RegExpExecArray | null;
		let reqMessage = { ...message };
		let variables: string[] = [];
		const promptWithoutIgnored = message.prompt.replace(
			ignoreRegex,
			(match) => {
				return ""; // Remove ignored sections
			},
		);

		while ((match = regex.exec(promptWithoutIgnored)) !== null) {
			if (match[1] && match[1] !== "") {
				variables.push(match[1]);
			}
		}
		reqMessage["variables"] = [...variables];
		return reqMessage;
	});
	const updatedData = {
		...data,
		config: { ...data.config, messages: updatedMessages },
	};
	return updatedData;
};

export default function Autopilot() {
	const navigate = useNavigate();
	const location = useLocation();
	const isDraftPage = location.pathname.includes("draft");
	const { saveDraft, removeDraft, getDraft } = useDraftAutopilots();
	const autopilotNameRef = useRef<HTMLInputElement>(null);
	const [autopilotData, setAutopilotData] = useState<Partial<AutopilotType>>({
		is_enabled: false,
	});
	const [initialAutopilotData, setInitialAutopilotData] = useState<
		Partial<AutopilotType>
	>({ is_enabled: false });
	const [justSaved, setJustSaved] = useState(false);
	const { data: user } = useMe();

	const [confirmationDialogData, setConfirmationDialogData] = useState<{
		title: string;
		description?: string;
		ctaText?: string;
		secondaryCtaText?: string;
		handleCtaClick?: () => void;
		handleSecondaryCtaClick?: () => void;
	}>({
		title: "",
		description: "",
		ctaText: "",
		secondaryCtaText: "",
		handleCtaClick: () => {},
		handleSecondaryCtaClick: () => {},
	});

	const [autopilot, setAutopilot] = useState<Partial<AutopilotType>>({});

	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<ToastData>({
		title: "",
		description: "",
		status: "success",
	});
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	let {
		autopilotId,
		enterpriseId,
		id: enterpriseIdParam,
	} = useParams<{
		autopilotId: UUID;
		enterpriseId: UUID;
		id: UUID;
	}>();

	if (!enterpriseId) {
		enterpriseId = enterpriseIdParam;
	}
	const { data: autopilotInstance, error: autopilotError } = useAutopilotById({
		autopilotId: autopilotId ?? "",
		enterpriseId: enterpriseId ?? "",
		includeUsers: true,
	});

	const { data: authorData } = useAutopilotAuthor(autopilotInstance?.author_id);

	const { addAutopilot, updateAutopilot } = useAutopilot();

	useEffect(() => {
		if (isDraftPage) {
			async function fetchDraft() {
				const draft = await getDraft(autopilotId ?? "");
				setAutopilotData(draft as Partial<AutopilotType>);
				setAutopilot(draft as AutopilotType);
			}
			fetchDraft();
		}
	}, []);

	useEffect(() => {
		if (autopilotInstance && autopilotId && !autopilotError) {
			const updatedAutopilotData = {
				...autopilotData,
				...autopilotInstance,
			};
			setAutopilotData(updatedAutopilotData);
			setInitialAutopilotData(updatedAutopilotData);
			setAutopilot(autopilotInstance);
		}
		const shouldSetSharedEnterpriseIds =
			!autopilotInstance?.id ||
			(autopilotInstance.id &&
				!autopilotInstance?.shared_enterprise_ids?.includes(
					enterpriseId as UUID,
				));
		if (shouldSetSharedEnterpriseIds) {
			setAutopilotData({
				...autopilotData,
				shared_enterprise_ids: [enterpriseId as UUID],
			});
		}
	}, [autopilotInstance, autopilotId, autopilotInstance?.id]);

	const updateAutopilotData = async (formattedData: Partial<AutopilotType>) => {
		try {
			await updateAutopilot({
				...formattedData,
				enterprise_id: enterpriseId,
			}).unwrap();
			setToastData({
				title: "Autopilot updated",
				status: "success",
			});
			setJustSaved(true);
			setInitialAutopilotData(formattedData);
			setOpenToast(true);
		} catch (error) {
			setAutopilotData({
				...autopilotData,
				users: initialAutopilotData.users,
				shared_enterprise_ids: initialAutopilotData.shared_enterprise_ids,
			});
			const { status: errorStatus, data: errorData } = error;
			let errorString =
				errorData.error ||
				getErrorString(errorData.validation_error || errorData.details);
			if (errorString === "VALIDATION_FAILED")
				errorString = getErrorString(
					errorData.validation_error || errorData.details,
				);
			if (errorStatus === 403 && errorString.includes("USER_NOT_AUTHOR")) {
				setConfirmationDialogData({
					title: "Acquire Lock",
					description: `You are not currently authorized to edit this autopilot. Current author: ${authorData?.email}. Would you like to acquire a lock?`,
					ctaText: "Acquire Lock and Save",
					handleCtaClick: () => {
						updateAutopilotData({
							...formattedData,
							author_id: user?.id,
						});
					},
				});
				setConfirmationDialogOpen(true);
				return;
			}

			setToastData({
				title: "Failed to update autopilot",
				description: errorString,
				status: "error",
			});
			setOpenToast(true);
		}
	};

	const createAutopilotData = async (
		formattedData: Partial<AutopilotType>,
		reqData: Partial<AutopilotType>,
	) => {
		try {
			const newAutopilot = await addAutopilot(
				reqData as AutopilotType,
			).unwrap();
			if (newAutopilot?.id) {
				setToastData({
					title: "Autopilot created",
					status: "success",
				});
				setJustSaved(true);
				setInitialAutopilotData(formattedData);
				setOpenToast(true);
				setTimeout(() => {
					navigate(
						`/admin/enterprise/${enterpriseId}/autopilot/${newAutopilot.id}/edit`,
					);
				}, 500);
				if (autopilotData.draft_id) {
					removeDraft(autopilotData.draft_id);
				}
			}
		} catch (error) {
			console.error("Error adding autopilot:", error);

			setToastData({
				title: "Failed to create autopilot",
				description: getErrorString(
					error.data.validation_error || error.data.details,
				),
				status: "error",
			});
			setOpenToast(true);
		}
	};

	const onSaveAutopilot = async () => {
		if (!autopilotData) {
			return;
		}
		const reqData: Partial<AutopilotType> = setTemplateVariables(autopilotData);
		setAutopilotData(reqData);
		const formattedData = formatData(reqData);
		if (autopilot.id) {
			await updateAutopilotData(formattedData);
		} else {
			await createAutopilotData(formattedData, reqData);
		}
	};

	const onToggleAutopilot = async () => {
		setAutopilotData({
			...autopilotData,
			is_enabled: !autopilotData.is_enabled,
		});
		if (autopilot?.id) {
			try {
				await updateAutopilot({
					id: autopilot?.id,
					is_enabled: !autopilotData.is_enabled,
					enterprise_id: enterpriseId,
				}).unwrap();
				setToastData({
					title: "Autopilot updated",
					status: "success",
				});
			} catch (error) {
				console.error("Error updating autopilot:", error);
				setToastData({
					title: "Failed to update autopilot",
					description: getErrorString(error.data.validation_error),
					status: "error",
				});
			}
			setOpenToast(true);
		}
	};

	const onSaveDraft = async () => {
		if (!autopilotData?.name) {
			setToastData({
				title: "Please enter a name",
				status: "error",
			});
			setOpenToast(true);
			return;
		}
		let draftId = autopilotData.draft_id;
		if (!draftId) {
			draftId = crypto.randomUUID();
		}
		saveDraft({
			...autopilotData,
			draft_id: draftId,
		});

		const updatedAutopilotData = {
			...autopilotData,
			draft_id: draftId,
		};
		setAutopilotData(updatedAutopilotData);
		setInitialAutopilotData(updatedAutopilotData);
		setToastData({
			title: "Draft saved",
			status: "success",
		});
		setOpenToast(true);
	};

	const onCloseAutopilot = () => {
		setConfirmationDialogOpen(false);
		navigate(`/admin/enterprise/${enterpriseId}`);
	};

	useEffect(() => {
		if (!isEqual(autopilotData, initialAutopilotData)) {
			setJustSaved(false);
		}
	}, [autopilotData, initialAutopilotData]);

	const hasFormDataChanged = () => {
		return !isEqual(autopilotData, initialAutopilotData);
	};

	const isUserAuthor = !autopilot.author_id || autopilot.author_id === user?.id;

	useEffect(() => {
		if (hasFormDataChanged() && !isUserAuthor) {
			setConfirmationDialogData({
				title: "Acquire Lock",
				description: `You are not currently authorized to edit this autopilot. Current author: ${authorData?.email}. Would you like to acquire a lock?`,
				ctaText: "Acquire Lock",
				handleCtaClick: () => {
					updateAutopilot({
						id: autopilot.id,
						author_id: user?.id,
						enterprise_id: enterpriseId,
					});
				},
			});
			setConfirmationDialogOpen(true);
		}
	}, [hasFormDataChanged(), isUserAuthor]);

	return (
		<React.Fragment>
			<Flex
				direction="column"
				style={{
					gap: "10px",
					borderRadius: "12px",
					border: "1px solid #E0E0E0",
					width: "1200px",
					overflow: "scroll",
				}}
			>
				<Flex direction="column" justify="between">
					<Flex align="center" justify="between" style={{ padding: "20px" }}>
						<Flex
							direction="column"
							style={{ gap: "8px", width: "calc(50% - 10px)" }}
							justify="center"
							id={`autopilot-name`}
						>
							<Text weight="bold">Enter Autopilot name</Text>
							<Input
								size="2"
								ref={autopilotNameRef}
								placeholder="Enter the autopilot name"
								required
								defaultValue={autopilotData?.name || autopilot?.name}
								style={{ height: "42px" }}
								onChange={(e) => {
									setAutopilotData({
										...autopilotData,
										name: e.target.value,
									});
								}}
							/>
						</Flex>
						<Flex align="center" style={{ gap: "20px" }}>
							<Flex align="center" style={{ gap: "8px" }}>
								<Text weight="bold">Enable Autopilot</Text>
								<Switch
									style={{ cursor: "pointer" }}
									onCheckedChange={onToggleAutopilot}
									mr="2"
									defaultChecked={autopilot?.is_enabled}
									checked={autopilotData?.is_enabled}
									radius="full"
									id={`autopilot-toggle-${autopilot?.id}-${enterpriseId}`}
								/>
							</Flex>
							<Flex align="center" style={{ gap: "8px" }}>
								<Text weight="bold">Run Milestones</Text>
								<Switch
									style={{ cursor: "pointer" }}
									checked={
										autopilotData?.config?.enable_milestone_eval || false
									}
									onCheckedChange={(checked) => {
										setAutopilotData({
											...autopilotData,
											config: {
												...autopilotData?.config,
												enable_milestone_eval: checked,
											},
										});
									}}
								/>
							</Flex>
							<Icon.X
								size="22px"
								style={{ cursor: "pointer", marginLeft: "20px" }}
								onClick={() => {
									if (hasFormDataChanged()) {
										setConfirmationDialogData({
											title: "Close Autopilot",
											description:
												"Are you sure you want to close this autopilot? You will lose any changes made to the autopilot.",
											ctaText: "Exit",
											handleCtaClick: onCloseAutopilot,
											secondaryCtaText: "Save and Exit",
											handleSecondaryCtaClick: () => {
												onSaveAutopilot();
												setConfirmationDialogOpen(false);
												onCloseAutopilot();
											},
										});
										setConfirmationDialogOpen(
											hasFormDataChanged() && !justSaved,
										);
									} else {
										onCloseAutopilot();
									}
								}}
							/>
						</Flex>
					</Flex>
					<div
						style={{
							width: "100%",
							borderColor: "#E0E0E0",
							height: "1px",
							backgroundColor: "#E0E0E0",
						}}
					/>
				</Flex>
				<AutopilotForm
					autopilot={autopilot || {}}
					setAutopilotFormData={setAutopilotData}
					autopilotFormData={autopilotData}
					onSaveAutopilot={onSaveAutopilot}
					enterpriseId={enterpriseId}
					onDraftSave={onSaveDraft}
					formDataChanged={hasFormDataChanged()}
				/>
			</Flex>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title || "Autopilot"}
				type={toastData.status}
				description={toastData.description || ""}
			/>
			<ConfirmationDialog
				title={confirmationDialogData.title}
				open={confirmationDialogOpen}
				onOpenChange={setConfirmationDialogOpen}
				handleClose={() => setConfirmationDialogOpen(false)}
				text={confirmationDialogData.description || ""}
				handleCtaClick={confirmationDialogData.handleCtaClick}
				ctaText={confirmationDialogData.ctaText}
				secondaryCtaText={confirmationDialogData.secondaryCtaText}
				handleSecondaryCtaClick={confirmationDialogData.handleSecondaryCtaClick}
			/>
		</React.Fragment>
	);
}
