export const professionalFeatures = [
	"Integrations with Slack, Hubspot, Salesforce, Google Workspace (Gmail, Calendar, Docs, Sheets, Others)",
	"MightyBot Meeting Presence records, transcribes, and answers any questions about your Meetings",
	"Individual Autopilot workflow automations",
	"Advanced internal knowledge retrievals",
	"Precise human-like research tool (Web/LinkedIn)",
	"Comprehensive writer tool crafts 20+ page docs",
];

export const enterpriseFeatures = [
	"Advanced security options",
	"Dedicated account support",
	"Team Autopilot workflow automations",
	"Forecasting & personalized coaching reports",
	"Win/Loss analysis reports",
	"Generates research reports & PRDs",
	"Builds detailed client reports (Consulting, Project, Marketing, Business Performance, others)",
];
