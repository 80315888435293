import React from "react";
import { Dialog, Button, Text, Flex, Icon } from "@mightybot/web-ui";
import * as Styled from "./styled";

interface GenerateDocOutputProps {
	documentUrl: string;
	onClose: () => void;
}

const GenerateDocOutput: React.FC<GenerateDocOutputProps> = ({
	documentUrl,
	onClose,
}) => {
	const handleCopyLink = () => {
		navigator.clipboard.writeText(documentUrl);
	};

	const handleOpenDoc = () => {
		window.open(documentUrl, "_blank");
	};

	return (
		<Styled.GenerateDocContainer style={{ maxWidth: 456 }}>
			<Dialog.Close>
				<Styled.CloseButton>
					<Icon.X size={20} />
				</Styled.CloseButton>
			</Dialog.Close>

			<Flex direction="column" gap="4">
				<Flex align="center" gap="2">
					<Icon.CheckCircle size={32} />
					<Text size="4" weight="medium">
						Doc saved successfully
					</Text>
				</Flex>

				<Flex
					style={{
						padding: "8px",
						border: "1px solid var(--mb-color-light-gray-4)",
						borderRadius: "4px",
					}}
				>
					<Text size="2" style={{ wordBreak: "break-all" }}>
						{documentUrl}
					</Text>
				</Flex>

				<Flex gap="2">
					<Button variant="soft" onClick={handleCopyLink} style={{ flex: 1 }}>
						<Icon.Copy />
						Copy Link
					</Button>
					<Button onClick={handleOpenDoc} style={{ flex: 1 }}>
						<Icon.ArrowLineUpRight />
						Open doc
					</Button>
				</Flex>
			</Flex>
		</Styled.GenerateDocContainer>
	);
};

export default GenerateDocOutput;
