import styled from "styled-components";
import {
	Flex,
	Button,
	mediaQueries,
	breakpointNumbers,
	mediaMaxWidth,
} from "@mightybot/web-ui";

export const AuthContainer = styled(Flex)`
	width: 100%;
	background: linear-gradient(
			131deg,
			var(--mb-blue-2) 5.15%,
			var(--mb-gray-1) 54.57%,
			var(--mb-green-1) 123.63%
		),
		var(--mb-gray-1);
	height: 100vh;
`;

export const LogInButton = styled(Button)`
	background-color: var(--mb-blue-8);
	max-width: 340px;
	border-radius: 60px;
	height: 44px;
	cursor: pointer;
	margin-bottom: 15px;
	width: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	img {
		width: 24px;
		height: 24px;
	}

	span {
		color: white;
		font-size: 16px;
		font-weight: 500;
	}

	&:hover {
		background-color: var(--mb-blue-9);
	}
`;

export const SplitLayout = styled(Flex)`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 0.6fr 1fr;
	${mediaMaxWidth(breakpointNumbers.desktop)`
		grid-template-columns: 1fr;
	`}
`;

export const ContentSection = styled(Flex)`
	justify-content: center;
	align-items: center;
	background: linear-gradient(
			131deg,
			#bed3ff 5.15%,
			#fafafa 54.57%,
			#f1ffc7 123.63%
		),
		var(--mb-gray-1);
`;

export const AnimationSection = styled(Flex)`
	background: #0a0f1f;
	overflow: hidden;
`;

export const Subtitle = styled.p`
	width: 360px;
	color: var(--mb-gray-8);
	font-size: 14px;
	text-align: center;
	margin: 0 0 20px 0;
	line-height: 18px;
`;

export const CustomDomainLink = styled.button`
	background: none;
	border: none;
	color: var(--mb-blue-8);
	font-size: 12px;
	cursor: pointer;
	margin-top: 12px;
`;

export const ContentWrapper = styled(Flex)`
	width: 100%;
	max-width: 444px;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;

export const LogoWrapper = styled(Flex)`
	width: 192px;
	height: 41px;
	margin-bottom: 30px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const ButtonsContainer = styled(Flex)`
	width: 100%;
	padding: 30px 0;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

export const AuthAnimationWrapper = styled(Flex)`
	height: 100vh;
	overflow: hidden;
	flex-basis: 40%;

	${mediaQueries.desktop} {
		display: none;
	}
	${mediaQueries.desktopL} {
		flex-basis: 45%;
	}
`;
