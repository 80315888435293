import styled from "styled-components";

import { Flex, Text } from "@mightybot/web-ui";

export const MeetingsPreviewSidebarContainer = styled(Flex)`
	flex-direction: column;
	gap: 10px;
	width: 100%;
	transition: max-width 0.3s ease-in-out;
	padding: 10px 0px;
`;

export const MeetingsListContentItem = styled(Flex)<{ selected: boolean }>`
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	padding: 8px 16px;
	border-radius: var(--radius-pill);
	transition: background-color 0.2s ease;
	gap: 8px;
	height: 40px;
	width: 100%;
	justify-content: space-between;
	flex-shrink: 0;
	background-color: ${({ selected }) =>
		selected ? "var(--mb-color-light-blue-2)" : "transparent"};

	white-space: nowrap;
	overflow: hidden;

	&:hover {
		background-color: var(--mb-color-light-blue-2);
		color: var(--mb-color-light-blue-8);
		div {
			background-color: var(--mb-color-light-blue-7);
		}
	}

	&:last-child {
		border-bottom: 1px solid var(--mb-color-gray-9);
	}
`;

export const MeetingGroup = styled(Flex)`
	margin-top: 6px;
`;

export const MeetingsListContainer = styled(Flex)`
	width: 100%;
	flex-direction: column;
	background-color: var(--mb-color-light-blue-1);
	border-radius: 20px;
	padding-bottom: 16px;
	height: 100vh;
	gap: 3px;
	overflow: scroll;
	transition: all 0.2s ease;
`;

export const MeetingsListContentHeaderContainer = styled(Flex)`
	padding: 0px 0px 10px 16px;
	position: sticky;
	top: 0;
	gap: 8px;
	background-color: var(--mb-color-light-blue-1);
	padding: 20px 10px 10px 26px;
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const MeetingsListContentContainer = styled(Flex)`
	flex-direction: column;
	overflow-y: hidden;
`;
export const MeetingsListContentItemText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 80%;
	flex: 1;
`;

export const MeetingsListSidebarToggleButton = styled(Flex)`
	background-color: var(--mb-color-light-blue-2);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 14px;
`;
