import { Flex, Icon, Card, Text } from "@mightybot/web-ui";
import { motion } from "framer-motion";

import { styled } from "styled-components";

export const Dot = styled(Flex)`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--mb-color-light-gray-9);
`;

export const ChatUserMessageContainer = styled(Flex)`
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 6px;
	align-items: center;
	width: 100%;
`;

export const ChatReplyContainer = styled(Flex)`
	gap: 6px;
	background-color: var(--mb-color-light-blue-1);
	padding: 16px 20px 16px 20px;
	border-radius: 12px;
	margin-left: 38px;
`;

export const CopyIcon = styled(Icon.Copy)`
	cursor: pointer;
	&:hover {
		background-color: var(--mb-color-light-gray-3);
	}
`;

export const ChatContainer = styled(Flex)`
	width: 100%;
`;

export const TypingIndicator = styled.span`
	display: inline-block;
	animation: blink 1s step-end infinite;
	margin-left: 2px;

	@keyframes blink {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
	}
`;

export const MessageBackground = styled.div`
	padding: 8px 12px;
	border-radius: 24px;
	background-color: var(--mb-color-light-gray-3);
	width: fit-content;
	max-width: 100%;
	word-break: break-word;
	padding: 12px 20px;
`;

export const customMarkdownStyles = {
	content: {
		fontSize: "14px",
		fontWeight: "light",
		whiteSpace: "pre-wrap",
	},
	paragraph: {
		marginBottom: 0,
	},
};

export const RetryButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	background: var(--mb-color-light-gray-3);
	border: 1px solid var(--mb-color-light-gray-5);
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;

	&:hover {
		background: var(--mb-color-light-gray-4);
	}
`;

export const COTCard = styled(Card)`
	padding: 8px 12px;
	background-color: var(--mb-color-light-blue-1);
	border: none;
`;

export const AccordionCard = styled(Card)`
	background-color: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-3);
	border-radius: 8px;
	margin-bottom: 8px;
	overflow: hidden;
`;

export const ChatMessageContent = styled.div`
	font-size: var(--default-font-size);
	line-height: 1.5;
	white-space: pre-wrap;
	word-break: break-word;

	p {
		margin: 0;
	}
`;

export const SpinningIcon = styled(Icon.Spinner)`
	animation: spin 2s linear infinite;
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const ProgressBarContainer = styled.div`
	width: 100%;
	height: 4px;
	background-color: var(--mb-color-light-gray-3);
	border-radius: 2px;
`;

export const ProgressBar = styled(motion.div)`
	height: 100%;
	background-color: var(--mb-color-dark-blue-8);
	border-radius: 2px;
`;

export const ReasoningContainer = styled(Flex)`
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: inline-flex;
	width: 100%;
`;

export const ReasoningHeader = styled(Flex)`
	justify-content: flex-start;
	gap: 4px;
	display: inline-flex;
	cursor: pointer;
`;

export const ReasoningTitle = styled(Text)`
	color: var(--mb-color-dark-gray-4);
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
`;

export const ReasoningContentWrapper = styled(motion.div)`
	overflow: hidden;
	width: 100%;
`;

export const ReasoningInnerContent = styled.div`
	padding-top: 10px;
`;

export const ReasoningBorder = styled.div`
	padding-left: 16px;
	border-left: 2px var(--mb-color-dark-gray-7) solid;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ReasoningText = styled(Text)`
	color: var(--mb-color-dark-gray-4);
	font-weight: 400;
`;

export const CaretIconWrapper = styled.div<{ isOpen: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
	transition: transform 0.3s ease;
`;

export const ReasoningMarkdown = styled(Text)``;

export const ChainOfThoughtContainer = styled(Flex)`
	border-radius: 8px;
	background: var(--mb-color-light-gray-1);
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
	width: 100%;
`;

export const ChainOfThoughtHeader = styled(Flex)`
	width: 100%;
	padding: 6px 8px;
	cursor: pointer;
`;

export const ChainOfThoughtMessage = styled(Flex)`
	padding: 6px 8px;
	gap: 4px;
`;

export const IconContainer = styled.div`
	flex-shrink: 0;
	height: 22px;
`;

export const ChainOfThoughtDivider = styled.div`
	width: 100%;
	border-color: var(--mb-color-light-gray-5);
	height: 1px;
	background-color: var(--mb-color-light-gray-5);
`;

export const RetryText = styled(Text)`
	cursor: pointer;
	color: var(--mb-color-light-blue-8);
	text-decoration: underline;
	font-style: italic;
`;

export const agentSpinnerContainerStyle = {
	width: "24px",
	height: "24px",
	padding: "0px",
	flexShrink: 0,
} as const;

export const agentSpinnerStyle = {
	marginBottom: "0px",
	width: "12px",
	height: "12px",
	padding: "12px",
} as const;

export const ChainOfThoughtContent = styled(motion.div)`
	overflow: hidden;
	border-radius: 6px;
`;

export const ProgressBarWrapper = styled(Flex)`
	padding: 8px;
`;
