import React, { useEffect, useRef, useState } from "react";
import { Flex, Skeleton, Text } from "@mightybot/web-ui";
import { Plus, DotsThree } from "@phosphor-icons/react";
import {
	TeamsContainer,
	Header,
	HeaderTitle,
	SearchInput,
	AddMemberButton,
	TableHeader,
	TableCell,
	TableRow,
	UserInfo,
	UserDetails,
	StatusBadge,
	Avatar,
	ActionButton,
	TeamsContentContainer,
	MenuContainer,
	ActionContainer,
} from "./styled";
import { AddMemberModal } from "./AddMemberModal";
import { useTeams } from "@mightybot/core";
import Toast from "../Toast";

const LoadingRow = () => (
	<TableRow>
		<UserInfo>
			<Skeleton width="40px" height="40px" style={{ borderRadius: "50%" }} />
			<UserDetails>
				<Skeleton width="120px" height="18px" />
				<Skeleton width="160px" height="16px" style={{ marginTop: "4px" }} />
			</UserDetails>
		</UserInfo>
		<TableCell>
			<Skeleton width="80px" height="18px" />
		</TableCell>
		<TableCell>
			<Skeleton width="100px" height="18px" />
		</TableCell>
		<TableCell>
			<Skeleton width="80px" height="24px" style={{ borderRadius: "12px" }} />
		</TableCell>
		<TableCell>
			<Skeleton width="24px" height="24px" />
		</TableCell>
	</TableRow>
);

export const Teams: React.FC = () => {
	const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<{
		title: string;
		status: "success" | "error" | "info";
		description: string;
	}>({
		title: "Team Management",
		status: "success",
		description: "",
	});

	const {
		members,
		invites,
		isLoading,
		searchQuery,
		setSearchQuery,
		addMember,
		removeMember,
		resendInvite,
		cancelInvite,
		isTeamAdmin,
		totalSeats,
		refetch,
	} = useTeams();

	const handleAddMember = async (emails: string[]) => {
		try {
			const success = await addMember(emails);
			if (success) {
				setIsAddMemberModalOpen(false);
				setToastData({
					title: "Team Members",
					status: "success",
					description: `Successfully added ${emails.length} member${emails.length > 1 ? "s" : ""}`,
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Members",
					status: "error",
					description: "Failed to add team member(s)",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Failed to add team member(s)",
			});
			setOpenToast(true);
		}
	};

	const handleRemoveMember = async (email: string) => {
		try {
			const success = await removeMember(email);
			if (success) {
				setToastData({
					title: "Team Members",
					status: "success",
					description: "Successfully removed team member",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Members",
					status: "error",
					description: "Failed to remove team member",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Failed to remove team member",
			});
			setOpenToast(true);
		}
	};

	const handleResendInvite = async (inviteId: string) => {
		try {
			const success = await resendInvite(inviteId);
			if (success) {
				setToastData({
					title: "Team Invites",
					status: "success",
					description: "Successfully resent invitation",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Invites",
					status: "error",
					description: "Failed to resend invitation",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Invites",
				status: "error",
				description: "Failed to resend invitation",
			});
			setOpenToast(true);
		}
	};

	const handleCancelInvite = async (inviteId: string) => {
		try {
			const success = await cancelInvite(inviteId);
			if (success) {
				setToastData({
					title: "Team Invites",
					status: "success",
					description: "Successfully cancelled invitation",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Invites",
					status: "error",
					description: "Failed to cancel invitation",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Invites",
				status: "error",
				description: "Failed to cancel invitation",
			});
			setOpenToast(true);
		}
	};

	const refreshMembers = async () => {
		try {
			await refetch();
		} catch (error) {
			console.error("Failed to refresh members", error);
		}
	};

	const ActionMenu: React.FC<{
		memberId: string;
		memberEmail?: string;
		inviteId?: string;
		memberRole?: string;
		onRemoveMember?: (email: string) => void;
		onResendInvite?: (id: string) => void;
		onCancelInvite?: (id: string) => void;
	}> = ({
		memberId,
		memberEmail,
		inviteId,
		memberRole,
		onRemoveMember,
		onResendInvite,
		onCancelInvite,
	}) => {
		const [isOpen, setIsOpen] = useState(false);
		const menuRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				if (
					menuRef.current &&
					!menuRef.current.contains(event.target as Node)
				) {
					setIsOpen(false);
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () =>
				document.removeEventListener("mousedown", handleClickOutside);
		}, []);

		return (
			<ActionContainer ref={menuRef}>
				{(memberEmail && memberRole !== "Admin") || inviteId ? (
					<>
						<ActionButton onClick={() => setIsOpen(!isOpen)}>
							<DotsThree color="var(--mb-gray-12)" size={24} />
						</ActionButton>
						{isOpen && (
							<MenuContainer>
								{memberEmail && memberRole !== "Admin" && (
									<button
										onClick={() => {
											onRemoveMember?.(memberEmail);
											setIsOpen(false);
										}}
									>
										Remove member
									</button>
								)}
								{inviteId && (
									<>
										<button
											onClick={() => {
												onResendInvite?.(inviteId);
												setIsOpen(false);
											}}
										>
											Resend Invite
										</button>
										<button
											onClick={() => {
												onCancelInvite?.(inviteId);
												setIsOpen(false);
											}}
										>
											Cancel Invite
										</button>
									</>
								)}
							</MenuContainer>
						)}
					</>
				) : null}
			</ActionContainer>
		);
	};
	return (
		<TeamsContainer>
			<Header>
				<HeaderTitle>
					{isLoading ? (
						<Skeleton width="200px" height="24px" />
					) : (
						<Text size="5" weight="medium">
							Team members ({members.length + invites.length}/{totalSeats})
						</Text>
					)}
				</HeaderTitle>
				<Flex gap="10px">
					<SearchInput
						placeholder="Search members"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					{isTeamAdmin && (
						<AddMemberButton onClick={() => setIsAddMemberModalOpen(true)}>
							<Plus size={20} weight="bold" />
							Add member
						</AddMemberButton>
					)}
				</Flex>
			</Header>
			<TeamsContentContainer>
				<TableHeader>
					<TableCell>Name</TableCell>
					<TableCell>Role</TableCell>
					<TableCell>Date added</TableCell>
					<TableCell>Status</TableCell>
					{isTeamAdmin && <TableCell>Actions</TableCell>}
				</TableHeader>

				{isLoading ? (
					<>
						{Array(4)
							.fill(0)
							.map((_, index) => (
								<LoadingRow key={`loading-${index}`} />
							))}
					</>
				) : (
					<>
						{members.map((member) => (
							<TableRow key={member.id}>
								<UserInfo>
									<Avatar bgColor={member.avatarColor}>
										{member.name.charAt(0)}
									</Avatar>
									<UserDetails>
										<Text size="2" weight="regular">
											{member.name}
										</Text>
										<Text size="2" weight="regular" color="gray">
											{member.email}
										</Text>
									</UserDetails>
								</UserInfo>
								<TableCell>{member.role}</TableCell>
								<TableCell>
									{new Date(member.dateAdded).toLocaleDateString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
									})}
								</TableCell>

								<TableCell>
									<StatusBadge status={member.status}>
										{member.status}
									</StatusBadge>
								</TableCell>
								{isTeamAdmin && (
									<TableCell>
										<ActionMenu
											memberId={member.id}
											memberEmail={member.email}
											memberRole={member.role}
											onRemoveMember={handleRemoveMember}
										/>
									</TableCell>
								)}
							</TableRow>
						))}

						{invites.map((invite) => (
							<TableRow key={invite.id} style={{ opacity: 0.7 }}>
								<UserInfo>
									<Avatar bgColor="var(--mb-color-light-gray-7)">
										{invite.email.charAt(0)}
									</Avatar>
									<UserDetails>
										<Text size="2">{invite.email}</Text>
									</UserDetails>
								</UserInfo>
								<TableCell>Pending</TableCell>
								<TableCell>
									{new Date(invite.invitedAt).toLocaleDateString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
									})}
								</TableCell>
								<TableCell>
									<StatusBadge status="pending">Invited</StatusBadge>
								</TableCell>
								{isTeamAdmin && (
									<TableCell>
										<ActionMenu
											memberId={invite.id}
											inviteId={invite.id}
											onResendInvite={handleResendInvite}
											onCancelInvite={handleCancelInvite}
										/>
									</TableCell>
								)}
							</TableRow>
						))}
					</>
				)}
			</TeamsContentContainer>

			<AddMemberModal
				open={isAddMemberModalOpen}
				onOpenChange={setIsAddMemberModalOpen}
				onSubmit={handleAddMember}
			/>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</TeamsContainer>
	);
};

export default Teams;
