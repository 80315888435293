import React, { useState, useEffect } from "react";
import { useSearch, useSearchSelection } from "@mightybot/core";
import Sidebar from "../Sidebar/Sidebar";
import { UUID } from "crypto";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery, breakpointNumbers } from "@mightybot/web-ui";
import { MobileSidebarContainer } from "./styled";

type SearchSidebarProps = {
	isFirstRender: React.MutableRefObject<boolean>;
};

const SearchSidebar: React.FC<SearchSidebarProps> = ({ isFirstRender }) => {
	const [searchParams] = useSearchParams();
	const activeSearchId = searchParams.get("searchId") as UUID;
	const {
		userSearches,
		activeSearch,
		refetchUserSearches,
		isLoadingUserSearches,
	} = useSearch(activeSearchId);
	const { clearSelection } = useSearchSelection();
	const navigate = useNavigate();
	const isTablet = useMediaQuery(`(max-width: ${breakpointNumbers.tablet}px)`);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);

	useEffect(() => {
		setIsSidebarOpen(false);
	}, [isTablet]);

	const toggleSidebar = () => {
		if (isTablet) {
			setIsMobileHistoryOpen(!isMobileHistoryOpen);
		} else {
			setIsSidebarOpen(!isSidebarOpen);
		}
	};

	const handleItemClick = (id: string) => {
		isFirstRender.current = true;
		clearSelection();
		navigate(`/search?searchId=${id}`);
		if (isTablet) {
			setIsMobileHistoryOpen(false);
		}
	};

	useEffect(() => {
		refetchUserSearches();
	}, [activeSearch, refetchUserSearches]);

	const sidebarItems =
		userSearches?.map((search) => ({
			id: search.id as UUID,
			text: search.query || `Search ${search.id}`,
			date: new Date(search.created_ts),
		})) || [];

	if (isTablet) {
		return (
			<MobileSidebarContainer>
				<Sidebar
					title="Search History"
					toggleSidebar={toggleSidebar}
					isSidebarOpen={isSidebarOpen}
					items={sidebarItems}
					activeItemId={activeSearch?.id as UUID}
					onItemClick={handleItemClick}
					containerStyle={{
						margin: "10px 0px 10px 10px",
						position: "relative",
						height: "auto",
						zIndex: 1,
					}}
					isLoading={isLoadingUserSearches}
					isMobileHistoryOpen={isMobileHistoryOpen}
				/>
			</MobileSidebarContainer>
		);
	}

	return (
		<Sidebar
			title="Search History"
			toggleSidebar={toggleSidebar}
			isSidebarOpen={isSidebarOpen}
			items={sidebarItems}
			activeItemId={activeSearch?.id as UUID}
			onItemClick={handleItemClick}
			containerStyle={{
				margin: "10px 0px 10px 10px",
				position: "relative",
				height: "auto",
				zIndex: 1,
			}}
			isLoading={isLoadingUserSearches}
		/>
	);
};

export default SearchSidebar;
