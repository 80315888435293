// @ts-nocheck
// Disable type checking for this file as the library is not typed
import React, { useState, useEffect } from "react";
import { IconButton, Skeleton } from "@mightybot/web-ui";
import { FrameCorners } from "@phosphor-icons/react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = React.forwardRef(
	(
		props: {
			options: any;
			onReady?: (player: any) => void;
			setCurrentTime: (time: number) => void;
			onExpand: () => void;
			isLoading?: boolean;
			src?: string;
			onError?: () => void;
		},
		ref,
	) => {
		const [isReady, setIsReady] = useState(false);
		const videoRef = React.useRef(null);
		const playerRef = ref || React.useRef(null);
		const initialized = React.useRef(false);
		const {
			options,
			onReady,
			setCurrentTime,
			onExpand,
			isLoading,
			src,
			onError,
		} = props;

		useEffect(() => {
			if (!src) return;

			if (playerRef.current && initialized.current) {
				// Just update the source if player already exists
				playerRef.current.src({ src });
				return;
			}

			// Initial setup only happens once
			if (initialized.current || playerRef.current) return;

			initialized.current = true;

			console.debug("initializing player");

			const videoElement = document.createElement("video-js");
			videoElement.classList.add("vjs-big-play-centered");
			videoRef.current.appendChild(videoElement);

			playerRef.current = videojs(videoElement, options, () => {
				videojs.log("player is ready");
				setIsReady(true);
				onReady && onReady(playerRef.current);
			});

			playerRef.current.on("error", () => {
				const error = playerRef.current.error();
				const mediaError = playerRef.current.error().message;

				// Check specifically for access denied errors
				const isAccessError =
					error.code === 4 && //
					(mediaError.includes("403") ||
						mediaError.includes("401") || // Unauthorized
						mediaError.includes("access_denied") ||
						mediaError.includes("permission"));

				if (isAccessError) {
					console.error("Video access error detected");
					onError && onError();
				}
			});

			playerRef.current.on("timeupdate", () => {
				setCurrentTime(playerRef.current.currentTime());
			});

			return () => {
				initialized.current = false;
				if (playerRef.current && !playerRef.current.isDisposed()) {
					playerRef.current.dispose();
					playerRef.current = null;
				}
			};
		}, [src]);

		if (!isReady && isLoading) {
			return (
				<div className="w-full max-w-2xl mt-2">
					<Skeleton className="aspect-video rounded-md h-[178px]" />
				</div>
			);
		}

		return (
			<div
				data-vjs-player
				className="w-full max-w-2xl mt-2 rounded-md relative"
			>
				<div ref={videoRef} rounded-md />
				<IconButton
					className="absolute top-2 right-2 z-10 cursor-pointer bg-white bg-opacity-70"
					onClick={onExpand}
				>
					<FrameCorners className="h-6 w-6" />
				</IconButton>
			</div>
		);
	},
);

export default VideoJS;
