import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Button, Text } from "@mightybot/web-ui";

import {
	Container,
	OnboardingWrapper,
	LogoWrapper,
	Footer,
	ImageContainer,
	SplitLayout,
} from "./styled";

import { MBLogoHeader, OnboardingAnimationFallback } from "@mightybot/core-ui";
import OnboardingAnimation from "./OnboardingAnimation.json";
import Lottie from "react-lottie-player";
import { OnboardingContent } from "./OnboardingContent";

const Onboarding = () => {
	return (
		<Container>
			<SplitLayout>
				<ImageContainer align="end" justify="end">
					<ErrorBoundary
						fallback={
							<img
								src={OnboardingAnimationFallback as string}
								alt="Onboarding Animation Fallback"
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
							/>
						}
					>
						<Lottie
							animationData={OnboardingAnimation as any}
							key="animation-onboarding"
							loop={true}
							play={true}
							style={{ width: "100%", height: "100%", display: "block" }}
							rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
						/>
					</ErrorBoundary>
				</ImageContainer>
				<OnboardingWrapper justify="center" align="start" direction="column">
					<LogoWrapper>
						<img
							src={MBLogoHeader as string}
							alt="logo"
							style={{ maxWidth: "100%", height: "100%" }}
						/>
					</LogoWrapper>
					<OnboardingContent />
					<Footer align="center" justify="between">
						<Text
							weight="light"
							style={{ color: "var(--mb-color-dark-gray-4)" }}
						>
							<Text weight="bold">Having trouble?</Text> Feel free to contact
							us.
						</Text>
						{/* @ts-ignore */}
						<Button
							style={{ cursor: "pointer" }}
							variant="soft"
							onClick={() => {
								window.open(
									"https://www.mightybot.ai/help/contact-support",
									"_blank",
								);
							}}
						>
							Contact Us
						</Button>
					</Footer>
				</OnboardingWrapper>
			</SplitLayout>
		</Container>
	);
};

export default Onboarding;
