import React from "react";
import { Flex, Icon, Text } from "@mightybot/web-ui";
import { capitalize } from "lodash";
import { AppFilterPill } from "../styled";
import { MBLogoGray, useIntegrationLogos } from "@mightybot/core-ui";
import { Apps } from "@mightybot/core";

interface AppFilterContentProps {
	appResultCounts: { app: string; count: number }[];
	selectedApps: string[] | null;
	handleAppSelect: (app: string) => void;
	results: any[];
}

// Update the VALID_APPS array to be more maintainable
export const VALID_APPS: Apps[] = [
	"Google Calendar",
	"Gmail",
	"Google Docs",
	"Google Sheets",
	"PDF",
	"Slack",
	"Linear",
	"Meetings",
	"Salesforce",
	"HubSpot",
] as const;

const AppFilterContent = ({
	appResultCounts,
	selectedApps,
	handleAppSelect,
	results,
}: AppFilterContentProps) => {
	const { getIntegrationLogoRegex } = useIntegrationLogos();

	// Create a map of app counts for efficient lookup
	const appCountMap = Object.fromEntries(
		appResultCounts.map(({ app, count }) => [app, count]),
	);

	// Filter and sort valid apps by count
	const sortedApps = VALID_APPS.sort(
		(a, b) => (appCountMap[b] || 0) - (appCountMap[a] || 0),
	);

	// If all valid apps are selected, treat it as "all" selected
	const isAllSelected =
		!selectedApps || selectedApps?.length === VALID_APPS.length;

	return (
		<Flex direction="column" gap="8px" p="16px">
			<Flex gap="6px" style={{ maxHeight: 530, flexWrap: "wrap" }}>
				<AppFilterPill
					key="all"
					onClick={() => handleAppSelect("all")}
					selected={isAllSelected}
				>
					<Icon.Check size={16} />
					<Text style={{ fontSize: 13 }}>All Apps</Text>
				</AppFilterPill>
				{sortedApps.map((app) => (
					<AppFilterPill
						key={app}
						onClick={() => handleAppSelect(app)}
						selected={selectedApps?.includes(app) ?? false}
					>
						<img
							src={
								getIntegrationLogoRegex(app.toLowerCase().replace(" ", "_")) ??
								MBLogoGray
							}
							alt={app}
							width={16}
							height={16}
						/>
						<Text style={{ fontSize: 13 }}>{app}</Text>
						{/* <Text style={{ color: "#889096", fontSize: 13 }}>
							{appCountMap[app] ?? 0}
						</Text> */}
					</AppFilterPill>
				))}
			</Flex>
		</Flex>
	);
};

export default AppFilterContent;
