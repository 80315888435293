import React, { useState } from "react";
import { subMonths } from "date-fns";
import { Button, Calendar, Flex, Icon, Text } from "@mightybot/web-ui";
import { DatePickerWrapper, CalendarsContainer, ActionButtons } from "./styled";

interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onChange: (dates: [Date | null, Date | null]) => void;
	singleDate?: boolean;
	placeholderText?: string;
	maxDate?: Date;
	minDate?: Date;
	isOpen?: boolean;
	onClose?: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
	startDate,
	endDate,
	onChange,
	singleDate = false,
	placeholderText,
	maxDate,
	minDate,
	isOpen,
	onClose,
}) => {
	const [leftMonth, setLeftMonth] = useState(
		singleDate ? new Date() : subMonths(new Date(), 1),
	);
	const [rightMonth, setRightMonth] = useState(
		singleDate ? new Date() : new Date(),
	);
	const [tempStartDate, setTempStartDate] = useState<Date | null>(startDate);
	const [tempEndDate, setTempEndDate] = useState<Date | null>(endDate);

	const handleDateSelect = (date: Date) => {
		if (singleDate) {
			setTempStartDate(date);
			setTempEndDate(null);
		} else {
			if (!tempStartDate || tempEndDate) {
				setTempStartDate(date);
				setTempEndDate(null);
			} else {
				if (date < tempStartDate) {
					setTempStartDate(date);
					setTempEndDate(tempStartDate);
				} else {
					setTempEndDate(date);
				}
			}
		}
	};

	const handleLeftMonthChange = (date: Date) => {
		setLeftMonth(date);
		// setRightMonth(addMonths(date, 1));
	};

	const handleRightMonthChange = (date: Date) => {
		setRightMonth(date);
		// setLeftMonth(addMonths(date, -1));
	};

	const handleApply = () => {
		onChange([tempStartDate, tempEndDate]);
		onClose?.();
	};

	const handleCancel = () => {
		setTempStartDate(startDate);
		setTempEndDate(endDate);
		onClose?.();
	};

	const handleClear = () => {
		setTempStartDate(null);
		setTempEndDate(null);
		onClose?.();
	};

	return (
		<DatePickerWrapper>
			<Flex direction="column">
				<Flex
					justify="between"
					align="center"
					style={{ width: "100%", padding: "10px 16px" }}
				>
					<Text weight="medium">
						{singleDate ? "Select date" : "Select date range"}
					</Text>
					<Icon.X
						size={16}
						onClick={handleClear}
						style={{ cursor: "pointer" }}
					/>
				</Flex>

				<CalendarsContainer>
					{/* Left Calendar */}
					<Calendar
						selectedStartDate={tempStartDate}
						selectedEndDate={tempEndDate}
						onDateSelect={handleDateSelect}
						currentMonth={leftMonth}
						onMonthChange={handleLeftMonthChange}
						minDate={minDate}
						maxDate={maxDate}
					/>

					{/* Right Calendar - Only show for date range selection */}
					{!singleDate && (
						<Calendar
							selectedStartDate={tempStartDate}
							selectedEndDate={tempEndDate}
							onDateSelect={handleDateSelect}
							currentMonth={rightMonth}
							onMonthChange={handleRightMonthChange}
							minDate={minDate}
							maxDate={maxDate}
						/>
					)}
				</CalendarsContainer>

				<ActionButtons>
					<Button
						variant="white-bg"
						onClick={handleCancel}
						style={{ cursor: "pointer" }}
					>
						Cancel
					</Button>
					<Button onClick={handleApply} style={{ cursor: "pointer" }}>
						Apply
					</Button>
				</ActionButtons>
			</Flex>
		</DatePickerWrapper>
	);
};

export default DateRangePicker;
