import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
	LOGIN_ERROR_MESSAGE_KEY,
	LOGOUT_MESSAGE_KEY,
	PosthogUtil,
	useLogin,
	useMe,
} from "@mightybot/core";
import {
	GoogleIcon,
	MBLogoHeaderLarge,
	OnboardingAnimationFallback,
} from "@mightybot/core-ui";
import { Callout, Flex, Heading, Icon, Spinner, Text } from "@mightybot/web-ui";

import {
	AnimationSection,
	AuthAnimationWrapper,
	AuthContainer,
	LogInButton,
	ContentSection,
	SplitLayout,
	Subtitle,
	BackButton,
} from "./styled";
import Disclaimer from "../Disclaimer";
import Loading from "../Loading";
import Lottie from "react-lottie-player";
import { ErrorBoundary } from "react-error-boundary";

import OnboardingAnimation from "../Onboarding/OnboardingAnimation.json";
import {
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";

export const loginViewLoader = () => {
	return <div>Loading login...</div>;
};

declare global {
	interface Window {
		google: any;
	}
}

// Implement this function to generate a unique nonce
const generateNonce = () => {
	return (
		Math.random().toString(36).substring(2, 15) +
		Math.random().toString(36).substring(2, 15)
	);
};

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Auth = () => {
	const [signInStarted, setSignInStarted] = useState<boolean>(false);
	const [login, { isError }] = useLogin();
	const {
		data: user,
		isLoading: isUserLoading,
		isError: isUserError,
	} = useMe();
	const navigate = useNavigate();
	const [isTokenError, setIsTokenError] = useState(false);
	const [googleSignInReady, setGoogleSignInReady] = useState(false);
	const [googleError, setGoogleError] = useState<string | null>(null);
	const location = useLocation();
	const isSignupFlow = location.pathname.includes("/signup");

	const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
		null,
	);

	const workspaceDomain = window.location.hostname;
	const [isAccessDenied, setIsAccessDenied] = useState(false);

	useEffect(() => {
		if (user) {
			setSignInStarted(false);
			const intendedDestination = localStorage.getItem("intendedDestination");
			if (intendedDestination) {
				localStorage.removeItem("intendedDestination");
				navigate(intendedDestination);
			} else {
				const isSignupFlow = localStorage.getItem("isSignupFlow");
				localStorage.removeItem("isSignupFlow");
				const navigateTo = isSignupFlow ? "/onboarding" : "/home";
				navigate(navigateTo);
			}
		}
	}, [user, navigate]);

	useEffect(() => {
		const message = localStorage.getItem(LOGIN_ERROR_MESSAGE_KEY);
		const logoutMessage = localStorage.getItem(LOGOUT_MESSAGE_KEY);
		if (message) {
			setLoginErrorMessage(message);
			localStorage.removeItem(LOGIN_ERROR_MESSAGE_KEY);
		}
	}, []);

	useEffect(() => {
		const hash = window.location.hash;
		const state = location.state as { error?: string };
		if (hash === "#access-denied" || state?.error === "Access Denied") {
			setIsAccessDenied(true);
		}
	}, [location]);

	const initializeGoogleSignIn = useCallback(() => {
		if (window.google && GOOGLE_CLIENT_ID && !isUserLoading && !user) {
			try {
				window.google.accounts.id.initialize({
					client_id: GOOGLE_CLIENT_ID,
					callback: handleCredentialResponse,
					auto_select: false,
					cancel_on_tap_outside: false,
					context: "signin",
					ux_mode: "popup",
					nonce: generateNonce(),
					//TODO: Need to enable fedcm to ensure continuity. currently disabled to ensure that we can login with google
					use_fedcm_for_prompt: true,
				});
				setGoogleSignInReady(true);
				setGoogleError(null);

				// Prompt for One Tap
				if (
					["/app/login", "/app/signin", "/app/auth", "/app/signup"].includes(
						window.location.pathname,
					)
				) {
					window.google.accounts.id.prompt((notification: any) => {
						if (notification.isSkippedMoment()) {
							console.debug("One Tap prompt was skipped");
						} else if (notification.isDismissedMoment()) {
							console.debug(
								"One Tap prompt was dismissed",
								notification.getDismissedReason(),
							);
							if (notification.getDismissedReason() === "credential_returned") {
								console.debug("User signed in successfully");
							} else if (notification.getDismissedReason() === "user_cancel") {
								console.debug("User cancelled the prompt");
							}
						}
					});
				}
			} catch (error) {
				console.error("Error initializing Google Sign-In:", error);
				setGoogleError("Failed to initialize Google Sign-In");
			}
		}
	}, [GOOGLE_CLIENT_ID, user, isUserLoading]);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://accounts.google.com/gsi/client";
		script.onload = initializeGoogleSignIn;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [initializeGoogleSignIn]);

	const handleCredentialResponse = async (response: any) => {
		if (response.credential) {
			setSignInStarted(true);
			try {
				const loginResponse = await login({
					token: response.credential,
					token_type: "google",
					workspace_domain: workspaceDomain,
				}).unwrap();

				Analytics.trackEvent(ANALYTICS_EVENTS.USER_LOGGED_IN, {
					[ANALYTICS_PROPERTIES.SOURCE]: "google",
					[ANALYTICS_PROPERTIES.WORKSPACE]: workspaceDomain,
					is_existing_user: loginResponse.data.is_existing_user,
				});

				window.dispatchEvent(
					new MessageEvent("message", {
						data: { type: "initiate_fcm_token" },
					}),
				);
				PosthogUtil.captureEvent("User logged in", {
					tenant: workspaceDomain,
				});
				const isExistingUser = loginResponse.data.is_existing_user;
				const navigateTo =
					!isExistingUser && isSignupFlow ? "/onboarding" : "/home";
				navigate(navigateTo);
			} catch (error: any) {
				console.error("Login error:", error);

				setIsTokenError(true);
				setGoogleError(
					"Failed to login with Google. Falling back to traditional OAuth.",
				);
				fallbackToTraditionalOAuth();
			} finally {
				setSignInStarted(false);
			}
		} else {
			console.error("No credential received from Google");
			setGoogleError(
				"No credential received from Google. Falling back to traditional OAuth.",
			);
			fallbackToTraditionalOAuth();
		}
	};

	const onSignInClick = useCallback(() => {
		if (isSignupFlow) {
			localStorage.setItem("isSignupFlow", "true");
		} else {
			localStorage.removeItem("isSignupFlow");
		}

		setSignInStarted(true);
		fallbackToTraditionalOAuth();
	}, []);

	const fallbackToTraditionalOAuth = () => {
		const clientIdEncoded = encodeURIComponent(GOOGLE_CLIENT_ID as string);
		const redirectUri = encodeURIComponent(
			`${window.location.origin}/app/auth/callback`,
		);
		const scopes = encodeURIComponent(["openid", "email", "profile"].join(" "));
		const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientIdEncoded}&response_type=token&redirect_uri=${redirectUri}&scope=${scopes}&prompt=select_account`;
		window.location.href = authUrl;
	};

	if (isUserLoading || user) {
		return <Loading />;
	}

	return (
		<AuthContainer>
			<SplitLayout>
				<AuthAnimationWrapper>
					<AnimationSection>
						<ErrorBoundary
							fallback={
								<img
									src={OnboardingAnimationFallback}
									alt="Animation Fallback"
									style={{ width: "100%", height: "100%", objectFit: "cover" }}
								/>
							}
						>
							<Lottie
								animationData={OnboardingAnimation}
								loop={true}
								play={true}
								style={{
									width: "100%",
									height: "100%",
									display: "block",
									objectFit: "contain",
								}}
								rendererSettings={{
									preserveAspectRatio: "xMidYMid meet",
									progressiveLoad: true,
									hideOnTransparent: true,
								}}
							/>
						</ErrorBoundary>
					</AnimationSection>
				</AuthAnimationWrapper>
				<ContentSection>
					<Flex
						direction="column"
						align="center"
						justify="between"
						style={{ height: "100%", padding: "20px 0", width: "100%" }}
					>
						<Flex
							direction="column"
							align="center"
							justify="center"
							style={{ flex: 1, width: "100%" }}
						>
							<img
								src={MBLogoHeaderLarge}
								alt=""
								style={{ width: 192, height: 40, marginBottom: 30 }}
							/>
							{isAccessDenied ? (
								<>
									<Heading
										as="h1"
										style={{
											color: "var(--mb-color-dark-gray-2)",
											fontSize: "22px",
											fontWeight: "500",
											lineHeight: "26px",
											marginBottom: "16px",
										}}
									>
										Please Signup with a Business Email address{" "}
									</Heading>
									<Text
										style={{
											color: "var(--mb-color-dark-gray-4)",
											fontSize: "14px",
											fontWeight: "400",
											lineHeight: "18px",
											marginBottom: "24px",
										}}
									>
										MightyBot does not allow @gmail.com and other free-mail
										accounts.{" "}
									</Text>
									<LogInButton
										onClick={onSignInClick}
										disabled={signInStarted || !googleSignInReady}
										id="google-sign-in-button"
									>
										{signInStarted ? (
											<Spinner weight="fill" size={22} />
										) : (
											<>
												<img src={GoogleIcon} alt="Google Icon" />
												<span>Signup with Business Email</span>
											</>
										)}
									</LogInButton>
									<Text style={{ marginBottom: "16px" }}>
										Questions?{" "}
										<a
											href="https://www.mightybot.ai/get-a-demo"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: "var(--mb-color-dark-blue-8)" }}
										>
											Contact Us
										</a>
									</Text>
								</>
							) : (
								<Flex direction="column" align="center" justify="center">
									<Heading
										as="h1"
										size="3"
										style={{
											marginBottom: isSignupFlow ? 20 : 20,
											fontWeight: 500,
											fontSize: isSignupFlow ? 24 : 18,
										}}
									>
										{isSignupFlow
											? "Start 7-day free trial"
											: "Get started with MightyBot"}
									</Heading>
									{isSignupFlow && (
										<Subtitle style={{ marginBottom: 30 }}>
											Business email address is required. MightyBot does not
											work with a free mail account.
										</Subtitle>
									)}
									{(isError || isTokenError) && (
										<Callout.Root color="red" style={{ marginBottom: "10px" }}>
											<Callout.Icon>
												<Icon.WarningCircle />
											</Callout.Icon>
											<Callout.Text>There was an error!</Callout.Text>
										</Callout.Root>
									)}
									<LogInButton
										disabled={signInStarted || !googleSignInReady}
										onClick={onSignInClick}
										id="google-sign-in-button"
									>
										{signInStarted ? (
											<Spinner weight="fill" size={22} />
										) : (
											<>
												<img src={GoogleIcon} alt="Google Icon" />
												<span>
													{isSignupFlow
														? "Continue with Google"
														: "Sign in with Google"}
												</span>
											</>
										)}
									</LogInButton>

									{!isSignupFlow && (
										<Text
											className="px-[20px]"
											style={{
												color: "var(--mb-gray-10)",
												textAlign: "center",
											}}
										>
											Business email address is required. MightyBot does not
											work with a free mail account.
										</Text>
									)}

									{(googleError || loginErrorMessage) && (
										<Text color="red" size="2" style={{ marginTop: "10px" }}>
											{googleError || loginErrorMessage}
										</Text>
									)}
								</Flex>
							)}
						</Flex>
						<Disclaimer />
					</Flex>
				</ContentSection>
			</SplitLayout>
		</AuthContainer>
	);
};

export default Auth;
