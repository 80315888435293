import React, { useState, useEffect } from "react";
import {
	SlackLogo,
	SalesforceLogo,
	HubspotLogo,
	GoogleWorkspaceLogo,
} from "@mightybot/core-ui";
import {
	useMe,
	useEnterprisePreferences,
	useSaveEnterprisePreferences,
	useUserInfo,
	AppName,
	EnterpriseAppConfig,
} from "@mightybot/core";
import {
	Container,
	Header,
	HeaderTitle,
	Content,
	NavTabs,
	TabItem,
	AutomationToggle,
	ToggleButton,
	ToggleButtonInner,
	LogoWrapper,
	AppContent,
	ToggleText,
	ErrorMessage,
	AppIcon,
	MessageText,
	InfoText,
	MessageContainer,
	MessageInner,
	MessageHeader,
} from "./styled";
import { Icon } from "@mightybot/web-ui";
import Toast from "../../Toast";

interface AppState {
	automationEnabled: boolean;
	error?: string;
}

interface AppConfig {
	id: AppName;
	name: string;
	logo: string;
	content: {
		toggleText: string;
	};
}

type ToastData = {
	title: string;
	description?: string;
	status: "success" | "error" | "info";
};

const APPS: AppConfig[] = [
	{
		id: "SALESFORCE" as AppName,
		name: "Salesforce",
		logo: SalesforceLogo,
		content: {
			toggleText: "Run Salesforce Automations as Admin User",
		},
	},
	// {
	// 	id: "HUBSPOT" as AppName,
	// 	name: "Hubspot",
	// 	logo: HubspotLogo,
	// 	content: {
	// 		toggleText: "Run Hubspot Automations as Admin User",
	// 	},
	// },
	// {
	// 	id: "SLACK" as AppName,
	// 	name: "Slack",
	// 	logo: SlackLogo,
	// 	content: {
	// 		toggleText: "Run Slack Automations as Admin User",
	// 	},
	// },
	// {
	// 	id: "GOOGLE" as AppName,
	// 	name: "Google Workspace",
	// 	logo: GoogleWorkspaceLogo,
	// 	content: {
	// 		toggleText: "Run Google Workspace Automations as Admin User",
	// 	},
	// },
];

const AppsConfiguration = () => {
	const { data: user } = useMe();
	const { userInfo } = useUserInfo();
	const { data: enterprisePreferences } = useEnterprisePreferences(
		user?.enterprise_id ?? "",
	);
	const [savePreferences, { isLoading }] = useSaveEnterprisePreferences();

	// Filter apps where user is admin
	const adminApps = APPS.filter(
		(app) => userInfo?.app_connect_info?.[app.id]?.is_admin,
	);

	const [selectedApp, setSelectedApp] = useState<AppName>(
		adminApps[0]?.id ?? APPS[0].id,
	);

	const [appStates, setAppStates] = useState<Record<AppName, AppState>>(() =>
		adminApps.reduce(
			(acc, app) => ({
				...acc,
				[app.id]: {
					automationEnabled: false,
					error: undefined,
				},
			}),
			{} as Record<AppName, AppState>,
		),
	);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<ToastData>({
		title: "",
		status: "info",
	});

	useEffect(() => {
		if (enterprisePreferences) {
			const newAppStates = adminApps.reduce(
				(acc, app) => ({
					...acc,
					[app.id]: {
						automationEnabled:
							enterprisePreferences?.apps_config?.[app.id]?.is_enabled ?? false,
						error: undefined,
					},
				}),
				{} as Record<AppName, AppState>,
			);
			setAppStates(newAppStates);
		}
	}, [enterprisePreferences]);

	const handleAppSelect = (appId: AppName) => {
		setSelectedApp(appId);
	};

	const handleToggleAutomation = async (appId: AppName) => {
		if (!user?.enterprise_id || !appStates[appId]) return;

		const newState = !appStates[appId].automationEnabled;
		const currentActiveAdmin =
			enterprisePreferences?.apps_config?.[appId]?.email_address;
		const isAdmin = userInfo?.app_connect_info?.[appId]?.is_admin;

		// If trying to turn off and another user is assigned, prevent it
		if (!newState && currentActiveAdmin && currentActiveAdmin !== user?.email) {
			setToastData({
				title: `Only ${currentActiveAdmin} can disable this automation`,
				status: "error",
			});
			setOpenToast(true);
			return;
		}

		// If trying to turn on but not an admin, prevent it
		if (newState && !isAdmin) {
			setToastData({
				title: "You need admin privileges to enable this automation",
				status: "error",
			});
			setOpenToast(true);
			return;
		}

		try {
			const currentConfig =
				enterprisePreferences?.apps_config ??
				({} as Record<AppName, EnterpriseAppConfig>);
			const newConfig = {
				...currentConfig,
				[appId]: {
					is_enabled: newState,
					email_address: newState ? user?.email : undefined,
				},
			};

			await savePreferences({
				enterprise_id: user.enterprise_id,
				preferences: {
					apps_config: newConfig,
					meeting_recording_preferences:
						enterprisePreferences?.meeting_recording_preferences ?? {
							generate_doc_transcript: true,
							get_meeting_recording_in_drive: true,
						},
				},
			}).unwrap();

			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					automationEnabled: newState,
					error: undefined,
				},
			}));
			setToastData({
				title: newState ? "Automation enabled" : "Automation disabled",
				status: "success",
			});
			setOpenToast(true);
		} catch (error) {
			console.error("Failed to update app configuration:", error);
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error: "Failed to update configuration",
				},
			}));
			setToastData({
				title: "Failed to update app configuration",
				status: "error",
			});
			setOpenToast(true);
		}
	};

	const selectedAppConfig = adminApps.find((app) => app.id === selectedApp);
	const selectedAppState = appStates[selectedApp] ?? {
		automationEnabled: false,
		error: undefined,
	};
	const currentActiveAdmin =
		enterprisePreferences?.apps_config?.[selectedApp]?.email_address;
	const isCurrentUserActiveAdmin = currentActiveAdmin === user?.email;

	if (adminApps.length === 0) {
		return (
			<Container>
				<Header>
					<HeaderTitle>Apps Configuration (Admin)</HeaderTitle>
				</Header>
				<Content>
					<MessageText>
						You don't have admin privileges for any apps
					</MessageText>
				</Content>
			</Container>
		);
	}

	return (
		<Container>
			<Header>
				<HeaderTitle>Apps Configuration (Admin)</HeaderTitle>
			</Header>

			<Content>
				<NavTabs>
					{adminApps.map((app) => (
						<TabItem
							key={app.id}
							active={selectedApp === app.id}
							onClick={() => handleAppSelect(app.id)}
						>
							<LogoWrapper>
								<AppIcon>
									<img src={app.logo} alt={app.name} />
								</AppIcon>
							</LogoWrapper>
							{app.name}
						</TabItem>
					))}
				</NavTabs>

				<AppContent>
					<MessageContainer>
						<MessageInner>
							<MessageHeader>
								<Icon.ShieldChevron size="32" />
								<MessageText>
									{`We've detected you are the ${selectedAppConfig?.name} Admin user (${user?.email}) for your team`}
								</MessageText>
							</MessageHeader>
							<InfoText>
								The scenario in which users do not have admin rights in{" "}
								{selectedAppConfig?.name}, while the admin holds those rights,
								prevents regular {selectedAppConfig?.name} automation from
								working for users with normal permissions. Turn on the switch
								below to enable Autopilot to run automations on behalf of the
								admin.
							</InfoText>
						</MessageInner>
					</MessageContainer>

					<AutomationToggle>
						<ToggleText>
							{selectedAppState.automationEnabled
								? `Running ${selectedAppConfig?.name} Automations as Admin User ${currentActiveAdmin ? `(${currentActiveAdmin})` : ""}`
								: `Run ${selectedAppConfig?.name} Automations as Admin User ${`(${user?.email})`}`}
						</ToggleText>
						<ToggleButton
							$active={selectedAppState.automationEnabled}
							onClick={() => handleToggleAutomation(selectedApp)}
							aria-disabled={false}
						>
							<ToggleButtonInner $active={selectedAppState.automationEnabled} />
						</ToggleButton>
					</AutomationToggle>

					{selectedAppState.error && (
						<ErrorMessage>{selectedAppState.error}</ErrorMessage>
					)}
				</AppContent>
			</Content>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description || ""}
				type={toastData.status}
			/>
		</Container>
	);
};

export default AppsConfiguration;
