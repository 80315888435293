import {
	Flex,
	Button,
	mediaQueries,
	breakpointNumbers,
	mediaMaxWidth,
	mediaMinWidth,
} from "@mightybot/web-ui";
import styled from "styled-components";

export const Container = styled(Flex)`
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	> * {
		flex-grow: 1;
	}
`;

export const SplitLayout = styled(Flex)`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 0.6fr 1fr;
	${mediaMaxWidth(breakpointNumbers.desktop)`
		grid-template-columns: 1fr;
	`}
`;

export const OnboardingWrapper = styled(Flex)`
	height: 100%;
	background: linear-gradient(
			112deg,
			rgba(190, 211, 255, 0.4) 3.75%,
			rgba(255, 255, 255, 0.4) 60.68%
		),
		var(--surface-primary, #fff);
	flex-basis: 60%;
	padding: 60px 140px;
	height: 100%;

	${mediaMaxWidth(breakpointNumbers.tablet)`
		padding: 40px;
		flex-basis: 100%;
	`}

	${mediaMaxWidth(breakpointNumbers.tablet)`
		padding: 20px;
	`}

	${mediaMinWidth(breakpointNumbers.desktopL)`
		flex-basis: 55%;
	`}
`;

export const OverlappingIcons = styled(Flex)`
	justify-content: center;
	margin-bottom: 1rem;

	> * {
		img {
			border: none;
			width: 70%;
			height: 70%;
			object-fit: cover;
		}
	}
`;

export const ContentBox = styled(Flex)`
	border-radius: 10px;
	background-color: white;
	width: 100%;
	border: 1px solid #e0e0e0;
	margin-top: 40px;

	${mediaMaxWidth(breakpointNumbers.laptop)`
		margin-top: 20px;
		width: 100% !important;
	`}
`;

export const ContentHeader = styled(Flex)`
	padding: 20px 10px;
	border-bottom: 1px solid #e0e0e0;
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	${mediaQueries.tablet} {
		text-align: center;
	}
`;

export const ContentBody = styled(Flex)`
	flex-direction: column;
	align-items: center;
	padding: 30px;
	gap: 10px;
`;

export const Content = styled(Flex)`
	flex-direction: column;
	align-items: start;
	gap: 5px;
	width: 100%;
	margin-bottom: 20px;
`;

export const CheckItem = styled(Flex)`
	gap: 0.5rem;
`;

export const ConnectButton = styled(Button)`
	max-width: 306px;
	border-radius: 60px;
	height: 44px;
	width: 100%;
	cursor: pointer;
`;

export const Footer = styled(Flex)`
	margin-top: 75px;
	width: 100%;
`;

export const ResponsiveImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
`;

export const ImageContainer = styled(Flex)`
	height: 100vh;
	overflow: hidden;
	flex-basis: 40%;

	${mediaQueries.desktop} {
		display: none;
	}
	${mediaQueries.desktopL} {
		flex-basis: 45%;
	}
`;

export const LogoWrapper = styled(Flex)`
	width: 100%;
	max-width: 200px;
	margin-bottom: 20px;
	height: 42px;

	${mediaMaxWidth(breakpointNumbers.laptop)`
		margin-bottom: 10px;
	`}
`;

export const OverlayContainer = styled(Flex)<{ isvisible: boolean }>`
	position: absolute;
	padding-top: 0px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 40%
	);
	z-index: 1000;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
	opacity: ${(props) => (props.isvisible ? 1 : 0)};
	transition: opacity 0.3s ease-in-out;
`;

export const OverlayContent = styled(Flex)<{ isvisible: boolean }>`
	background: white;
	border-radius: 24px 24px 0 0;
	width: 100%;
	min-height: 100vh;
	padding: 24px;
	position: relative;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	align-items: center;
	transform: ${(props) =>
		props.isvisible ? "translateY(0)" : "translateY(100%)"};
	transition: transform 0.3s ease-in-out;
	transform-origin: bottom;

	&::before {
		content: "";
		position: absolute;
		top: -50px;
		left: 0;
		right: 0;
		height: 50px;
		background: linear-gradient(
			to top,
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0)
		);
	}

	${mediaMaxWidth(breakpointNumbers.laptop)`
		min-height: 100vh;
	`}
`;
