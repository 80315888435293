import React from "react";
import { useEffect, useState } from "react";
import * as Styled from "./styled";
import {
	IntegrationApp,
	useIntegrations,
	useIntegrationConnect,
	AppType,
	GoogleAppType,
	UPDATE_INTEGRATIONS,
	useMe,
} from "@mightybot/core";
import { Flex, Text, Skeleton } from "@mightybot/web-ui";
import { AppCard } from "./AppCard";
import { IntegrationsAppData } from "../../constants";

export type AppData = {
	static_logo?: React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined;
	description?: string | undefined;
	app_name?: string | undefined;
};

export type CustomAppType = AppType & AppData;
export type CustomGoogleAppType = GoogleAppType & AppData;
export type CustomIntegrationAppType = IntegrationApp & AppData;

let allApps: IntegrationApp[] | CustomGoogleAppType[] = [];
let googleApps: CustomGoogleAppType[] = [];
let otherApps: CustomAppType[] = [];
let firstRun = true;

const EVENT_KEYS = ["slack_app", "hubspot_app", "salesforce_app", "linear_app"];
const APPS_TO_SHOW = [
	"slack_app",
	"salesforce_app",
	"hubspot_app",
	"linear_app",
	"google",
];

const APP_DATA: { [key: string]: AppData } = Object.assign(
	{},
	...APPS_TO_SHOW.map((app: string) => ({
		[app]: {
			static_logo: IntegrationsAppData.find((data) => data.id === app)
				?.static_logo as React.ComponentType<React.SVGProps<SVGSVGElement>>,
			description:
				IntegrationsAppData.find((data) => data.id === app)?.description ?? "",
			app_name:
				IntegrationsAppData.find((data) => data.id === app)?.app_name ?? "",
		},
	})),
);

const googleApp: CustomGoogleAppType = {
	integration_id: "google",
	id: "google",
	name: "Google Workspace",
	icon_url: "google",
	is_connected: false,
	scopes: [],
	redirect_url: "google",
	client_id: "google",
	receive_code_url: "google",
	...(APP_DATA.google as any),
};

export default function Connect() {
	const { refetch: refetchUser } = useMe();
	const [apps, setApps] = useState<CustomAppType[] | CustomGoogleAppType[]>([]);
	const [appConnecting, setAppConnecting] = useState<
		(CustomAppType["id"] | CustomGoogleAppType["id"])[]
	>([]);
	const {
		data: integrations,
		isLoading: integrationsLoading,
		refetch: integrationsRefetch,
	} = useIntegrations();
	const {
		connectApp,
		disconnectApp,
		connectGoogleAppBatch,
		disconnectAllGoogleApps,
	} = useIntegrationConnect();

	const handleSetApps = (integrations: CustomIntegrationAppType[]) => {
		if (integrations && integrations.length > 0) {
			googleApps = integrations.filter(
				(app) => app.integration_id === "google",
			) as CustomGoogleAppType[];

			otherApps = integrations
				.filter((app) => app.integration_id !== "google")
				.sort((a, b) => a.name.localeCompare(b.name)) as CustomAppType[];

			const allGoogleAppsConnected = googleApps.every(
				(app) => app.is_connected,
			);
			const updatedGoogleApp = {
				...googleApp,
				is_connected: allGoogleAppsConnected,
			};

			setApps([updatedGoogleApp, ...otherApps]);

			if (firstRun) {
				allApps = [updatedGoogleApp, ...otherApps];
				firstRun = false;
			}
		}
	};

	useEffect(() => {
		if (!integrations || integrations.length === 0) return;
		handleSetApps(integrations);
	}, [integrations, integrationsLoading]);

	const connect = async (app: CustomAppType | CustomGoogleAppType) => {
		if (app.integration_id === "google") {
			setAppConnecting([...appConnecting, app.id]);
			const notConnectedGoogleApps = googleApps.filter(
				(googleApp) => !googleApp.is_connected,
			);
			await connectGoogleAppBatch(
				notConnectedGoogleApps as CustomGoogleAppType[],
			);
		} else {
			setAppConnecting([...appConnecting, app.id]);
			connectApp(app);
		}
	};

	const disconnect = async (app: CustomAppType | CustomGoogleAppType) => {
		if (app.integration_id === "google") {
			await disconnectAllGoogleApps();
			refetchUser();
		} else {
			await disconnectApp(app);
		}
	};

	useEffect(() => {
		const messageListener = (event: MessageEvent) => {
			const { type, data } = event.data as {
				type: string;
				data: IntegrationApp["id"] | CustomGoogleAppType["id"][];
			};
			if (type === UPDATE_INTEGRATIONS) {
				(async () => {
					setAppConnecting((prev) => prev.filter((id) => id !== googleApp.id));
					const response = await integrationsRefetch();
					handleSetApps(response.data ?? []);
				})();
			}
		};
		window.addEventListener("message", messageListener);
		const storageListener = (event: StorageEvent) => {
			if (
				event.key &&
				Object.keys(localStorage).includes(event.key) &&
				EVENT_KEYS.includes(event.key)
			) {
				localStorage.removeItem(event.key);
				setAppConnecting((prev) => prev.filter((id) => id !== event.key));
				(async () => {
					const response = await integrationsRefetch();
					handleSetApps(response.data ?? []);
				})();
			}
		};
		window.addEventListener("storage", storageListener);
		return () => {
			window.removeEventListener("message", messageListener);
			window.removeEventListener("storage", storageListener);
		};
	}, []);

	const renderShimmerCards = () => {
		return Array(4)
			.fill(0)
			.map((_, index) => (
				<Styled.AppCardContainer
					key={`shimmer-${index}`}
					align="center"
					justify="between"
				>
					<Flex style={{ columnGap: "10px" }}>
						<Skeleton width="40px" height="40px" />
						<Flex direction="column" justify="center">
							<Skeleton width="120px" height="18px" />
							<Skeleton
								width="200px"
								height="16px"
								style={{ marginTop: "4px" }}
							/>
						</Flex>
					</Flex>
					<Skeleton width="80px" height="32px" />
				</Styled.AppCardContainer>
			));
	};

	return (
		<Styled.ConnectContainer direction="column" justify="center">
			<Styled.AppHeader align="center" justify="between">
				{integrationsLoading ? (
					<Skeleton width="200px" height="24px" />
				) : (
					<Text size="6" weight="medium">
						{(otherApps?.length ?? 0) + 1} total apps & services
					</Text>
				)}
				<Styled.StyledInput
					placeholder="Search apps..."
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const searchTerm = e.target.value.toLowerCase();
						const filteredApps = allApps.filter((app) =>
							app.name.toLowerCase().includes(searchTerm),
						);
						setApps(filteredApps);
					}}
				/>
			</Styled.AppHeader>
			<Styled.AppContainer direction="column">
				{integrationsLoading
					? renderShimmerCards()
					: apps.map(
							(app: CustomAppType | CustomGoogleAppType, index: number) => (
								<AppCard
									key={app.id}
									app={
										{
											...app,
											...(APP_DATA[app.id] ?? {}),
										} as CustomAppType | CustomGoogleAppType
									}
									lastItem={index === apps.length - 1}
									connect={connect}
									disconnect={disconnect}
									appConnecting={appConnecting.includes(app.id)}
								/>
							),
						)}
			</Styled.AppContainer>
		</Styled.ConnectContainer>
	);
}
