import styled from "styled-components";
import { Flex, Text } from "@mightybot/web-ui";

export const PricingToggle = styled(Flex)`
	gap: 0px;
`;

export const PricingToggleButton = styled.button<{ active: boolean }>`
	width: 167px;
	padding: 8px 14px;
	border: 1px solid
		${(props) =>
			props.active
				? "var(--mb-color-light-blue-8)"
				: "var(--mb-color-light-gray-4)"};
	color: ${(props) =>
		props.active
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-gray-9)"};
	border-radius: 4px;
	background: var(--mb-color-light-gray-1);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	cursor: pointer;
	height: 34px;
`;

export const PurchasePlanButton = styled.button`
	width: 122px;
	padding: 8px 14px;
	color: var(--mb-color-light-blue-8);
	border: 1px solid var(--mb-color-light-blue-8);
	border-radius: 4px;
	background: var(--mb-color-light-gray-1);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	cursor: pointer;
`;

export const ContactSalesButton = styled.button`
	width: 200px;
	height: 34px;
	padding: 0 14px;
	color: var(--mb-color-light-gray-1);
	background: var(--mb-color-light-blue-8);
	border: none;
	border-radius: 999px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
`;

export const PlanPrice = styled(Flex)`
	align-items: baseline;
	gap: 5px;
	margin: 10px 0;
`;

export const TrialBadge = styled(Flex)`
	width: 200px;
	height: 34px;
	padding: 0 14px;
	background: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-6);
	border-radius: 999px;
	justify-content: center;
	align-items: center;

	> div {
		color: var(--mb-color-light-gray-9);
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}
`;
export const StatusBadge = styled(Flex)<{ status?: string }>`
	width: auto;
	min-width: 59px;
	height: 24px;
	padding: 0 12px;
	background: ${({ status }) => {
		switch (status?.toLowerCase()) {
			case "paid":
				return "var(--mb-color-dark-green-1)";
			case "unpaid":
				return "var(--mb-color-light-blue-2)";
			case "pending":
				return "var(--mb-color-light-blue-2)";
			default:
				return "var(--mb-color-light-gray-2)";
		}
	}};
	border-radius: 100px;
	justify-content: center;
	align-items: center;

	> div {
		color: ${({ status }) => {
			switch (status?.toLowerCase()) {
				case "paid":
					return "var(--mb-color-dark-green-1)";
				case "unpaid":
					return "var(--mb-color-light-blue-11)";
				case "pending":
					return "var(--mb-color-light-blue-11)";
				default:
					return "var(--mb-color-light-gray-11)";
			}
		}};
		font-size: 13px;
		font-family: "FK Grotesk Neue";
		font-weight: 400;
		line-height: 17px;
	}
`;
export const PlanFeatureList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 20px 0 0;

	li {
		display: flex;
		align-items: flex-start;
		font-size: 13px;
		line-height: 17px;
		color: var(--mb-color-dark-gray-1);
		margin-bottom: 5px;
	}
`;

export const PaymentMethodCard = styled.div`
	padding: 16px;
	border: 1px solid var(--mb-color-dark-gray-8);
	border-radius: 8px;
	background: var(--mb-color-light-gray-1);
`;

export const InvoiceTable = styled.div`
	.invoice-header {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		padding: 12px 16px;
		background: var(--mb-color-light-gray-2);
		border-radius: 4px 4px 0 0;
	}
	.invoice-row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		padding: 12px 16px;
		border-bottom: 1px solid var(--mb-color-dark-gray-8);
		&:last-child {
			border-bottom: none;
			border-radius: 0 0 4px 4px;
		}
	}
`;

export const PaymentMethodsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 16px;
`;

export const InvoiceRow = styled(Flex)`
	width: 100%;
	padding: 12px 20px;
	background: var(--mb-color-light-gray-1);
	border-bottom: 1px solid var(--mb-color-dark-gray-8);
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: var(--mb-color-light-gray-1);
	}
`;

export const InvoiceCell = styled(Flex)<{ isDate?: boolean }>`
	flex: 1;
	color: ${(props) =>
		props.isDate
			? "var(--mb-color-light-blue-a9)"
			: "var(--mb-color-light-gray-11)"};
	font-size: 13px;
	font-weight: 400;
	line-height: 17px;
`;

export const TileContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 80px);
	background: var(--mb-color-light-gray-1);
	border-radius: 12px;
	border: 1px solid var(--mb-color-light-gray-4);
	overflow: hidden;
`;

export const TileHeader = styled(Flex)`
	padding: 20px;
	border-bottom: 1px solid var(--mb-color-light-gray-4);

	> div {
		color: var(--mb-color-light-gray-9);
		font-size: 22px;
		font-weight: 500;
		line-height: 26px;
	}
`;

export const TileSubHeader = styled(Flex)`
	padding: 20px;
	background: var(--mb-color-light-gray-3);
	justify-content: space-between;
	align-items: center;
`;
export const TileContent = styled(Flex)`
	flex-direction: column;
	flex: 1;
	overflow: auto;
	height: 100%;
`;

export const TileSection = styled(Flex)<{
	background?: string;
	padding?: string;
	borderBottom?: boolean;
}>`
	flex-direction: column;
	width: 100%;
	padding: ${(props) => props.padding || "20px"};
	background: ${(props) => props.background || "transparent"};
	max-height: 400px;
	overflow: auto;
	${(props) =>
		props.borderBottom &&
		`
    border-bottom: 1px solid var(--mb-color-light-gray-4);
  `}
`;
export const TextSection = styled(Flex)<{
	background?: string;
	padding?: string;
	borderBottom?: boolean;
}>`
	flex-direction: column;
	width: 100%;
	padding: 20px;
	background: ${(props) => props.background || "transparent"};
	${(props) =>
		props.borderBottom &&
		`
    border-bottom: 1px solid var(--mb-color-light-gray-6);
  `}
	border-top : 1px solid var(--mb-color-light-gray-6);
`;

export const TileGrid = styled(Flex)`
	gap: 40px;
	background: var(--mb-color-light-gray-1);
	padding: 0 70px 30px;
`;

export const TileGridItem = styled(Flex)`
	width: 100%;
	max-width: 350px;
	flex-direction: column;
	padding-top: 20px;
	border: 1px solid var(--mb-color-light-gray-5);
	border-radius: 12px;
	align-items: center;
	gap: 5px;

	> div:first-child {
		height: 174px;
		padding-bottom: 20px;
		border-bottom: 1px solid var(--mb-color-light-gray-5);
	}
`;
export const TileTable = styled(Flex)`
	flex-direction: column;
	width: 100%;
`;

export const TileTableRow = styled(Flex)<{
	header?: boolean;
}>`
	padding: 10px 20px;
	background: ${(props) =>
		props.header ? "transparent" : "var(--mb-color-light-gray-1)"};
	border-bottom: 1px solid var(--mb-color-light-gray-6);

	> div {
		flex: 1;
		color: ${(props) =>
			props.header ? "var(--mb-color-dark-gray-6)" : "inherit"};
	}
`;

export const TileTableContainer = styled(Flex)`
	flex: 1;
	overflow: auto;
	min-height: 0;
`;
export const InvoiceHeader = styled(Text)`
	padding: 12px 20px;
	background-color: var(--mb-color-light-gray-2);
	font-weight: 500;
	position: sticky;
	top: 0;
	z-index: 1;
	margin-bottom: 0;
`;

export const BottomText = styled(Text)`
	color: var(--mb-color-dark-gray-2);
`;
