import { Step } from "react-joyride";

interface TourStep extends Step {
	path: string;
}

export const tourSteps: TourStep[] = [
	{
		target: ".tour-home",
		content:
			"Categorized feed of notifications, alerts, tasks, reminders, reports, and more",
		disableBeacon: true,
		placement: "left",
		title: "Home Feed",
		path: "/home",
	},
	{
		target: ".nav-chats-button",
		content: "Start a new chat or ask a question",
		title: "Just More Than Chat",
		placement: "right",
		path: "/chats",
	},
	{
		target: ".nav-search-button",
		content: "Search through Files, Emails, Docs, Slack, or Meetings",
		placement: "right",
		title: "Enterprise Data Search",
		path: "/search",
	},
	{
		target: ".nav-meetings-button",
		content:
			"Navigate through all your indexed meetings to see analysis and perform more actions on meeting data",
		title: "Meeting Preps & Post-Meeting Summaries",
		placement: "right",
		path: "/meetings",
	},
	{
		target: ".nav-people-button",
		content:
			"Navigate through team members, customers, prospects, partners, and others",
		title: "People",
		placement: "right",
		path: "/people",
	},
	{
		target: ".nav-companies-button",
		content:
			"Navigate through companies who are customers, prospects, partners, and others",
		title: "Companies",
		placement: "right",
		path: "/companies",
	},
	{
		target: ".nav-deals-button",
		content: "Navigate through all deals",
		title: "Deals",
		placement: "right",
		path: "/deals",
	},
	{
		target: ".nav-settings-button",
		content:
			"Your preferences, data control, privacy, and connecting your favorite apps.",
		title: "Settings",
		placement: "right",
		path: "/settings/connect",
	},
];
