import React, { forwardRef } from "react";
import { ChatContainer } from "./styled";
import ChatUserMessage from "./ChatUserMessage";
import ChatReply from "./ChatReply";
import { User } from "@mightybot/core";

export interface ChatProps {
	timestamp: string;
	question: string;
	reply: string;
	content?: string;
	author?: Partial<User> | null;
	isStreaming?: boolean;
	onRetry?: () => void;
	completionId?: string;
	streamingFailed?: boolean;
}

const Chat = forwardRef<HTMLDivElement, ChatProps>(
	(
		{
			timestamp,
			content,
			question,
			reply,
			author,
			isStreaming,
			onRetry,
			completionId,
			streamingFailed,
		},
		ref,
	) => {
		return (
			<ChatContainer
				ref={ref}
				direction="column"
				gap="6px"
				style={{ width: "100%" }}
			>
				<ChatUserMessage
					timestamp={timestamp}
					content={content}
					question={question}
					author={author}
				/>
				<ChatReply
					reply={reply}
					isStreaming={isStreaming}
					onRetry={onRetry}
					completionId={completionId}
					streamingFailed={streamingFailed}
				/>
			</ChatContainer>
		);
	},
);

Chat.displayName = "Chat";

export default Chat;
