import React, { useEffect, useState } from "react";
import { UPDATE_INTEGRATIONS, useMe } from "@mightybot/core";
import { useLocation } from "react-router-dom";
import { OverlayContent, OverlayContainer } from "./styled";
import { OnboardingContent } from "./OnboardingContent";

interface OnboardingOverlayProps {}

export const OnboardingOverlay: React.FC<OnboardingOverlayProps> = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [isRemoved, setIsRemoved] = useState(false);
	const { data: userData, isLoading: isLoadingUser } = useMe();
	const pathname = useLocation().pathname;

	useEffect(() => {
		const listener = (event: MessageEvent) => {
			if (event.data.type === UPDATE_INTEGRATIONS) {
				setIsVisible(false);
				// Wait for animation to complete before removing from DOM
				setTimeout(() => setIsRemoved(true), 300);
			}
		};
		window.addEventListener("message", listener);
		return () => window.removeEventListener("message", listener);
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 100);

		return () => clearTimeout(timer);
	}, []);

	if (
		userData?.onboarding_completed ||
		!userData ||
		pathname.includes("/settings") ||
		isRemoved ||
		isLoadingUser
	)
		return null;

	return (
		<OverlayContainer isvisible={isVisible}>
			<OverlayContent isvisible={isVisible}>
				<OnboardingContent
					overlay
					styles={{ marginTop: "0px", width: "60%" }}
				/>
			</OverlayContent>
		</OverlayContainer>
	);
};
