import React from "react";
import { WelcomeDemo, WelcomeInstall, MBLogoHeader } from "@mightybot/core-ui";
import { Text, Flex } from "@mightybot/web-ui";
import Cookies from "js-cookie";
import {
	ResponsiveFlex,
	LogoContainer,
	Card,
	CardImage,
	CardContent,
	InstallCard,
	InstallCardContent,
	InstallCardImage,
	WelcomeContainer,
} from "./styled";

export default function Welcome() {
	const welcomePageShown = Cookies.get("welcomePageShown");

	if (!welcomePageShown) {
		Cookies.set("welcomePageShown", "true");
	}

	return (
		<WelcomeContainer>
			<Flex
				direction="column"
				style={{
					padding: "20px 20px",
					width: "100%",
				}}
			>
				<Flex direction="row" style={{ gap: "20px" }}>
					<img
						src={MBLogoHeader as string}
						alt="logo"
						width={200}
						height={42}
					/>
				</Flex>
			</Flex>

			<ResponsiveFlex direction="column" align="center" justify="center">
				<LogoContainer align="center" justify="center">
					<Text
						style={{
							fontSize: "28px",
							fontWeight: "500",
							lineHeight: "34px",
						}}
					>
						MightyBot is Now Active
					</Text>
				</LogoContainer>
				<Card>
					<CardImage src={WelcomeDemo} alt="Welcome Card 1" />
					<CardContent direction="column" justify="center">
						<Text
							weight="bold"
							style={{
								fontSize: "18px",
								lineHeight: "24px",
							}}
						>
							Your personalized AI Assistant
						</Text>
						<Text
							style={{
								fontSize: "14px",
								fontWeight: "400",
								lineHeight: "18px",
							}}
						>
							Automate your daily tasks and intelligently retrieve & utilize
							connected apps data.
						</Text>
					</CardContent>
				</Card>
				<InstallCard justify="center" align="center">
					<InstallCardContent direction="column" justify="center">
						<Flex
							justify="center"
							align="center"
							style={{
								borderRadius: "100px",
								background: "#CFDFFF",
								maxWidth: "72px",
								height: "24px",
							}}
						>
							<Text
								weight="bold"
								style={{
									color: "#022366",
								}}
							>
								Act now
							</Text>
						</Flex>
						<Text
							weight="bold"
							style={{
								fontSize: "18px",
								lineHeight: "24px",
							}}
						>
							Pin MightyBot to your browser for quick access
						</Text>
						<Text
							style={{
								fontSize: "14px",
								fontWeight: "400",
								lineHeight: "18px",
							}}
						>
							Pinning a Chrome extension helps you to easily and quickly access
							it.
						</Text>
					</InstallCardContent>
					<InstallCardImage src={WelcomeInstall} alt="Welcome Card 2" />
				</InstallCard>
			</ResponsiveFlex>
		</WelcomeContainer>
	);
}
