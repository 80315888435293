import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	AvatarWrapper,
	Text,
	Flex,
	Tabs,
	Icon,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import {
	PEOPLE_PAGE_FEATURE,
	useFavorites,
	useFeatureFlag,
	usePersonById,
	usePersonDetails,
} from "@mightybot/core";
import {
	Container,
	Header,
	BackButton,
	ProfileSection,
	ProfileInfo,
	Name,
	ContentContainer,
	LeftSection,
	RightSection,
	TabsRoot,
	TabsContainer,
	TabContentContainer,
	ContactInfo,
	ContactItem,
	IconWrapper,
	TagsSection,
	Section,
	SectionTitle,
	ContactLinks,
	ContactLink,
	Highlight,
} from "./styled";
import { Activities } from "./PeopleContent/Activities";
import { Deals } from "./PeopleContent/Deals";
import { Notes } from "./PeopleContent/Notes";
import { Tasks } from "./PeopleContent/Tasks";
import { PeopleTags } from "../People/PeopleTags";

const TABS = [
	{ id: "activities", label: "Activities", icon: Icon.Pulse },
	{ id: "deals", label: "Deals", icon: Icon.Buildings },
	{ id: "notes", label: "Notes", icon: Icon.NoteBlank },
	{ id: "tasks", label: "Tasks", icon: Icon.CheckSquare },
	// { id: "chats", label: "Chats", icon: Icon.ChatTeardropText },
] as const;

const PeopleDetails: React.FC = () => {
	const isPeoplePageEnabled = useFeatureFlag(PEOPLE_PAGE_FEATURE);

	const { personId } = useParams<{ personId: string }>();
	const { toggleFavorite, isFavorite } = useFavorites("PERSON");
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = React.useState("deals");
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const {
		data: person,
		isLoading,
		error,
	} = usePersonById(personId || "", {
		skip: !personId,
	});

	const { personDetails } = usePersonDetails(person?.email || "", {
		include_latest_activity: true,
		include_notes: false,
		include_tasks: false,
		include_deals: false,
	});

	const personTags = [person?.person_type, person?.status].filter(
		Boolean,
	) as string[];

	const onCopyPersonLink = () => {
		const link = `${window.location.origin}/people/${personId}/details`;
		navigator.clipboard.writeText(link);
	};

	if (isPeoplePageEnabled === null) {
		return null;
	}
	const handleCopyEmail = () => {
		if (person?.email) {
			navigator.clipboard.writeText(person.email);
		}
	};

	const handleEmailClick = (e: React.MouseEvent) => {
		e.preventDefault();
		if (person?.email) {
			window.location.href = `mailto:${person.email}`;
		}
	};

	const CONTACT_LINKS = [
		{ logo: Icon.LinkedinLogo, label: "LinkedIn" },
		{
			logo: Icon.EnvelopeSimple,
			label: "Email",
			onClick: handleEmailClick,
			href: person?.email ? `mailto:${person.email}` : undefined,
		},
		{ logo: Icon.CalendarPlus, label: "Meetings" },
		{ logo: Icon.SlackLogo, label: "Slack" },
		{ logo: Icon.Users, label: "CRM" },
	];

	if (!isPeoplePageEnabled) {
		return (
			<Flex justify="center" align="center" style={{ height: "100vh" }}>
				<Text>Feature coming soon</Text>
			</Flex>
		);
	}
	if (isLoading) {
		return (
			<Container>
				<Text>Loading...</Text>
			</Container>
		);
	}

	if (error || !person) {
		return (
			<Container>
				<Text>Person not found</Text>
			</Container>
		);
	}

	return (
		<Container>
			<ContentContainer>
				<LeftSection>
					<Header id="header">
						<BackButton
							variant="outline"
							radius="full"
							onClick={() => navigate("/people")}
						>
							<Icon.ArrowLeft />
							Back
						</BackButton>
						{isTablet && (
							<Flex
								align="center"
								justify="center"
								style={{
									cursor: "pointer",
									backgroundColor: "var(--gray-4)",
									padding: "5px",
									borderRadius: "50%",
									width: "30px",
									height: "30px",
								}}
							>
								<Icon.X size="20" color="var(--gray-9)" />
							</Flex>
						)}
					</Header>

					<Flex
						direction="column"
						justify="center"
						gap="6px"
						id="profile-section"
					>
						<ProfileSection>
							<Flex
								direction="column"
								gap="6px"
								justify="center"
								align="center"
								id="profile-section"
							>
								<AvatarWrapper
									size="8"
									radius="full"
									variant="soft"
									alt={person?.name || ""}
									src={person?.profile_pic ?? ""}
									styles={{
										fontSize: "24px",
										width: "80px",
										height: "80px",
										position: "relative",
										top: "-40px",
									}}
								/>
								<ProfileInfo>
									<Name>
										<Text
											size="4"
											weight="medium"
											style={{ textTransform: "capitalize" }}
										>
											{person?.name || "Name"}
										</Text>
										<Icon.Star
											color="var(--gray-9)"
											weight={isFavorite(personId || "") ? "fill" : "regular"}
											style={{ flexShrink: 0, cursor: "pointer" }}
											size="18"
											onClick={() => toggleFavorite(personId || "")}
										/>
									</Name>
									<Flex
										align="center"
										width="100%"
										gap={"5px"}
										style={{ flexShrink: 0 }}
									>
										<Text
											style={{
												color: "var(--gray-9)",
												fontSize: "13px",
												textTransform: "capitalize",
											}}
										>
											{person?.job_title || "Title"} at{" "}
										</Text>
										<Icon.Buildings size="16" style={{ flexShrink: 0 }} />
										<Text
											style={{
												fontSize: "13px",
												textTransform: "capitalize",
											}}
										>
											{" "}
											{person?.organization || "Organization"}
										</Text>
									</Flex>
								</ProfileInfo>
							</Flex>
						</ProfileSection>

						<ContactInfo id="contact-info">
							<ContactItem>
								<IconWrapper>
									<Icon.EnvelopeSimple />
								</IconWrapper>
								<Flex
									align="center"
									gap="4px"
									style={{ cursor: "pointer" }}
									onClick={handleCopyEmail}
								>
									<Text style={{ fontSize: "13px" }}>Copy Email</Text>
									<Icon.Copy size={14} />
								</Flex>
							</ContactItem>
							{person?.phone && (
								<ContactItem>
									<IconWrapper>
										<Icon.Phone />
									</IconWrapper>
									<Text style={{ fontSize: "13px" }}>
										{person?.phone || "+1 (555) 123-4567"}
									</Text>
								</ContactItem>
							)}
							{/* <ContactItem>
								<IconWrapper>
									<Icon.Buildings />
								</IconWrapper>
								<Text style={{ fontSize: "13px", textTransform: "capitalize" }}>
									{person?.organization || "Organization"}
								</Text>
							</ContactItem> */}
						</ContactInfo>

						<TagsSection id="tags-section">
							<PeopleTags tags={personTags} />
						</TagsSection>

						<ContactLinks id="contact-links">
							{CONTACT_LINKS.map((link) => (
								<ContactLink
									as={link.href && "a"}
									href={link.href}
									style={{
										flex: 1,
										cursor: link.href ? "pointer" : "not-allowed",
										textDecoration: "none",
									}}
									key={link.label}
									last={link.label === "More"}
									onClick={link.onClick}
								>
									<link.logo
										size="18"
										color={link.href ? "var(--gray-9)" : "var(--gray-6)"}
									/>
									<Text
										size="1"
										style={{
											color: link.href ? "var(--gray-9)" : "var(--gray-6)",
										}}
									>
										{link.label}
									</Text>
								</ContactLink>
							))}
						</ContactLinks>
					</Flex>

					{!isTablet && (
						<Section id="highlights-section">
							<SectionTitle>
								<Text size="3" weight="medium">
									Highlights
								</Text>
							</SectionTitle>
							{personDetails?.last_contacted?.created_ts && (
								<Highlight>
									<Text style={{ color: "var(--gray-9)" }}>
										Last Activity Date
									</Text>
									<Text>{personDetails?.last_contacted?.created_ts}</Text>
								</Highlight>
							)}
							{person?.last_interacted_ts && (
								<Highlight>
									<Text style={{ color: "var(--gray-9)" }}>Last Contacted</Text>
									<Text>{person?.last_interacted_ts}</Text>
								</Highlight>
							)}
							{person.status && (
								<Highlight>
									<Text style={{ color: "var(--gray-9)" }}>
										Lifecycle Stage
									</Text>
									<Text>{person?.status || "Cold"}</Text>
								</Highlight>
							)}
							{/* <Highlight>
								<Text style={{ color: "var(--gray-9)" }}>Industry</Text>
								<Text>{person?.industry || "Technology"}</Text>
							</Highlight> */}
							{/* <Highlight>
								<Text style={{ color: "var(--gray-9)" }}>Contact Owner</Text>
								<Text>{person?.contact_owner || "John Smith"}</Text>
							</Highlight> */}
							{person?.created_ts && (
								<Highlight>
									<Text style={{ color: "var(--gray-9)" }}>Created On</Text>
									<Text>{person?.created_ts}</Text>
								</Highlight>
							)}
						</Section>
					)}
				</LeftSection>

				<RightSection>
					<TabsRoot>
						<TabsContainer>
							<Tabs.Root defaultValue="activities">
								<Tabs.List
									style={{
										height: "54px",

										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Flex
										align="center"
										justify="between"
										style={{ height: "100%" }}
									>
										{TABS.map((tab) => (
											<Tabs.Trigger
												key={tab.id}
												value={tab.id}
												style={{
													height: "100%",
													padding: isTablet ? "0 12px" : "0 20px",
													cursor: "pointer",
												}}
											>
												<Flex align="center" gap="6px">
													<tab.icon size="20" style={{ flexShrink: 0 }} />
													<Text size="3">{tab.label}</Text>
												</Flex>
											</Tabs.Trigger>
										))}
									</Flex>
									{!isTablet && (
										<Flex align="center" justify="center" gap="6px" mr="20px">
											{/* <Share
												shareData={person as Person}
												onCopy={onCopyPersonLink}
												type="person"
											/> */}
											<Icon.DotsThree
												size="20"
												color="var(--gray-9)"
												style={{ flexShrink: 0, cursor: "pointer" }}
											/>
										</Flex>
									)}
								</Tabs.List>
								<TabContentContainer>
									<Tabs.Content value="activities">
										<Activities email={person?.email || ""} />
									</Tabs.Content>
									<Tabs.Content value="deals">
										<Deals
											email={person?.email || ""}
											hubspot_id={person?.hubspot_id}
										/>
									</Tabs.Content>
									<Tabs.Content value="notes">
										<Notes
											id={personId || ""}
											email={person?.email || ""}
											hubspot_id={person?.hubspot_id}
										/>
									</Tabs.Content>
									<Tabs.Content value="tasks">
										<Tasks
											id={personId || ""}
											email={person?.email || ""}
											hubspot_id={person?.hubspot_id}
										/>
									</Tabs.Content>
									{/* <Tabs.Content value="chats">
										<Chats />
									</Tabs.Content> */}
								</TabContentContainer>
							</Tabs.Root>
						</TabsContainer>
					</TabsRoot>
				</RightSection>
			</ContentContainer>
		</Container>
	);
};

export default PeopleDetails;
