import React, { useState } from "react";
import { Text, Icon, Skeleton, useMediaQuery } from "@mightybot/web-ui";
import { Meeting, useMeeting, useMeetingContent } from "@mightybot/core";
import { useParams, Link, useNavigate } from "react-router-dom";
import { formatDateTime, formatDuration } from "../../utils/helpers";
import GenerateDoc from "../GenerateDoc";
import ModelSelection from "../ModelSelection";
import { Button } from "@mightybot/web-ui";
import Toast from "../Toast";
import Share from "../Share";
import { cn } from "../../utils/classNames";
import { breakpoints } from "@mightybot/web-ui";

interface MeetingContentTitleProps {}

const MeetingContentTitle: React.FC<MeetingContentTitleProps> = ({}) => {
	const meetingId = useParams().meetingId;
	const { meeting, isLoading } = useMeeting(meetingId ?? "");
	const { meetingContent } = useMeetingContent(meetingId ?? "");
	const navigate = useNavigate();

	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error",
	});

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const duration = meeting?.meeting_duration
		? formatDuration(meeting.meeting_duration)
		: (formatDuration(
				meeting?.start_time ?? "",
				meeting?.end_time ?? "",
			) as string);

	const formattedDuration =
		typeof duration === "string" && duration.startsWith("0h ")
			? duration.slice(3)
			: duration;

	if (!meetingId) return null;

	const handleBackClick = () => {
		const isTeamView = window.location.pathname.includes("/team/");
		const basePath = isTeamView ? "/team/meetings" : "/meetings";
		navigate(`${basePath}/`);
	};

	const showToastMessage = (
		title: string,
		description: string,
		status: "success" | "error",
	) => {
		setToastData({
			title,
			description,
			status,
		});
		setOpenToast(true);
	};

	const onCopyMeetingLink = async (url?: string) => {
		try {
			const link = `${window.location.origin}/app/meetings/${meetingId}/details`;
			await navigator.clipboard.writeText(url ?? link);
		} catch (err) {
			console.error("Failed to copy link:", err);
		}
	};

	return (
		<div
			className={cn(
				"flex flex-col border-b border-border-primary border-x-0 border-t-0 border-solid px-4 py-5",
				isTablet && "px-[10px]",
			)}
			data-title={`${meetingId}-title`}
		>
			<div className="flex items-center gap-3 w-full min-w-0">
				<Button
					size="2"
					className="bg-blue-one text-text-primary rounded-full border border-border-primary border-solid cursor-pointer h-[34px] flex items-center justify-center"
					onClick={handleBackClick}
				>
					<Icon.ArrowLeft className="h-5 w-5" />
					{!isTablet && "Back"}
				</Button>

				{isLoading ? (
					<Skeleton width="300px" height="24px" />
				) : (
					<>
						<div className="flex-1 flex flex-row items-center min-w-0 gap-6">
							<div className="font-medium text-lg text-text-primary truncate">
								{meeting?.title}
							</div>
							{!isLoading && !isTablet && (
								<div className="flex items-center gap-4 text-text-secondary">
									<div className="flex items-center gap-1">
										<Icon.CalendarBlank className="flex-shrink-0" />
										<Text
											weight="regular"
											size="1"
											className="whitespace-nowrap"
										>
											{formatDateTime(meeting?.start_time ?? "")}
										</Text>
									</div>
									<div className="flex items-center gap-1">
										<Icon.Clock className="flex-shrink-0" />
										<Text
											weight="regular"
											size="1"
											className="whitespace-nowrap"
										>
											{formattedDuration}
										</Text>
									</div>
								</div>
							)}
						</div>

						<div className="flex items-center gap-2 flex-shrink-0">
							<Share
								shareData={meeting as Meeting}
								type="meeting"
								id={meetingId}
								onCopy={onCopyMeetingLink}
								notes={[
									"To share external, first create a snippet (Shared Clips)",
								]}
							/>
							<GenerateDoc
								meetingContent={meetingContent ?? []}
								showToastMessage={showToastMessage}
								meeting={meeting ?? {}}
							/>
							<ModelSelection />
						</div>
					</>
				)}
			</div>

			{!isLoading && isTablet && (
				<div className="flex items-center gap-2 mt-2 text-text-secondary">
					<div className="flex items-center gap-1">
						<Icon.CalendarBlank className="flex-shrink-0" />
						<Text weight="regular" size="1" className="whitespace-nowrap">
							{formatDateTime(meeting?.start_time ?? "")}
						</Text>
					</div>
					<div className="flex items-center gap-1">
						<Icon.Clock className="flex-shrink-0" />
						<Text weight="regular" size="1" className="whitespace-nowrap">
							{formattedDuration}
						</Text>
					</div>
				</div>
			)}

			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</div>
	);
};

export default MeetingContentTitle;
