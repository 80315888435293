import React, { useState } from "react";
import { Popover, Icon } from "@mightybot/web-ui";
import DateRangePicker from "../DateRangePicker";
import { FilterButton, FilterContainer } from "./styled";

type DateRange = {
	from: Date | undefined;
	to: Date | undefined;
};

type MeetingsListFiltersProps = {
	onDateChange: (range: DateRange) => void;
};

export const MeetingsListFilters = ({
	onDateChange,
}: MeetingsListFiltersProps) => {
	const [dateRange, setDateRange] = useState<DateRange>({
		from: undefined,
		to: undefined,
	});
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates;
		setDateRange({ from: start ?? undefined, to: end ?? undefined });
		onDateChange({ from: start ?? undefined, to: end ?? undefined });
		if (start && end) {
			setIsDatePickerOpen(false);
		}
	};

	const getDateButtonText = () => {
		if (!dateRange.from && !dateRange.to) return "Date";
		if (dateRange.from && dateRange.to) {
			return `${dateRange.from.toLocaleDateString()} - ${dateRange.to.toLocaleDateString()}`;
		}
		return "Date Selected";
	};

	return (
		<FilterContainer gap="8px">
			<Popover.Root open={isDatePickerOpen} onOpenChange={setIsDatePickerOpen}>
				<Popover.Trigger>
					<FilterButton
						disabled
						data-active={!!(dateRange.from || dateRange.to)}
						variant="white-bg"
					>
						{getDateButtonText()}
						<Icon.CaretDown />
					</FilterButton>
				</Popover.Trigger>
				<Popover.Content
					style={{ width: "100%", maxWidth: "1000px", padding: 0 }}
				>
					<DateRangePicker
						startDate={dateRange.from ?? null}
						endDate={dateRange.to ?? null}
						onChange={handleDateRangeChange}
						onClose={() => setIsDatePickerOpen(false)}
						maxDate={new Date()}
						placeholderText={
							dateRange.from && dateRange.to
								? "Select date range"
								: "Select start date"
						}
					/>
				</Popover.Content>
			</Popover.Root>

			<FilterButton variant="white-bg" disabled>
				Category
				<Icon.CaretDown />
			</FilterButton>
			<FilterButton variant="white-bg" disabled>
				Tag
				<Icon.CaretDown />
			</FilterButton>
			<FilterButton variant="white-bg" disabled>
				People
				<Icon.CaretDown />
			</FilterButton>
			<FilterButton variant="white-bg" disabled>
				Company
				<Icon.CaretDown />
			</FilterButton>
		</FilterContainer>
	);
};
