import styled from "styled-components";

import { Flex } from "@mightybot/web-ui";

export const DatePickerWrapper = styled(Flex)`
	width: 100%;
	flex-direction: column;
`;

export const PopoverContent = styled(Flex)`
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ActionButtons = styled(Flex)`
	justify-content: flex-end;
	gap: 8px;
	padding: 10px 16px;
	border-top: 1px solid var(--mb-gray-3);
	background: rgba(237, 243, 255, 0.5);
`;

export const CalendarsContainer = styled(Flex)`
	gap: 16px;
`;
