import React, { useState, useEffect } from "react";
import {
	Select,
	Switch,
	Checkbox,
	Text,
	Button,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import {
	UserNotificationContainer,
	SectionTitle,
	SubSectionTitle,
	Description,
	SelectContainer,
	SubDescription,
	NotificationItem,
	NotificationTitle,
	NotificationDescription,
} from "./styled";
import Toast from "../Toast";
import { useUserPreferences } from "@mightybot/core";

const startTimes = [
	{ value: "700", label: "7:00 AM" },
	{ value: "730", label: "7:30 AM" },
	{ value: "800", label: "8:00 AM" },
	{ value: "830", label: "8:30 AM" },
	{ value: "900", label: "9:00 AM" },
	{ value: "930", label: "9:30 AM" },
	{ value: "1000", label: "10:00 AM" },
	{ value: "1030", label: "10:30 AM" },
	{ value: "1100", label: "11:00 AM" },
];

const endTimes = [
	{ value: "1700", label: "5:00 PM" },
	{ value: "1730", label: "5:30 PM" },
	{ value: "1800", label: "6:00 PM" },
	{ value: "1830", label: "6:30 PM" },
	{ value: "1900", label: "7:00 PM" },
	{ value: "1930", label: "7:30 PM" },
	{ value: "2000", label: "8:00 PM" },
	{ value: "2030", label: "8:30 PM" },
	{ value: "2100", label: "9:00 PM" },
];

const notificationDays = [
	{ value: "WEEKDAYS", label: "Weekdays" },
	{ value: "WEEKEND", label: "Weekends" },
	{ value: "EVERYDAY", label: "Every day" },
];

const UserNotification: React.FC = () => {
	const { userPreferences, updateUserPreferences } = useUserPreferences();
	const [days, setDays] = useState("WEEKDAYS");
	const [startTime, setStartTime] = useState(800);
	const [endTime, setEndTime] = useState(1700);
	const [emailEnabled, setEmailEnabled] = useState(false);
	const [slackEnabled, setSlackEnabled] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [originalValues, setOriginalValues] = useState({
		days: "WEEKDAYS",
		startTime: 800,
		endTime: 1700,
		emailEnabled: false,
		slackEnabled: false,
	});
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<{
		title: string;
		status: "success" | "error" | "info" | undefined;
		description: string;
	}>({
		title: "Feedback",
		status: "success",
		description: "",
	});

	useEffect(() => {
		// Initialize form state from userPreferences
		if (userPreferences) {
			const initialValues = {
				days: userPreferences.days || "WEEKDAYS",
				startTime: userPreferences.startTime || parseInt(startTimes[0].value),
				endTime: userPreferences.endTime || parseInt(endTimes[0].value),
				emailEnabled: userPreferences.emailNotificationsToggled || false,
				slackEnabled: userPreferences.slackNotificationsToggled || false,
			};

			setDays(initialValues.days);
			setStartTime(initialValues.startTime);
			setEndTime(initialValues.endTime);
			setEmailEnabled(initialValues.emailEnabled);
			setSlackEnabled(initialValues.slackEnabled);
			setOriginalValues(initialValues);
		}
	}, [userPreferences]);

	useEffect(() => {
		const currentValues = {
			days,
			startTime,
			endTime,
			emailEnabled,
			slackEnabled,
		};

		const changed = Object.keys(originalValues).some(
			(key) =>
				originalValues[key as keyof typeof originalValues] !==
				currentValues[key as keyof typeof currentValues],
		);

		setHasChanges(changed);
	}, [days, startTime, endTime, emailEnabled, slackEnabled, originalValues]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		try {
			await updateUserPreferences({
				notification: {
					days,
					start_time: startTime,
					end_time: endTime,
					email_notifications: emailEnabled,
					slack_notifications: slackEnabled,
					push_notifications: false,
					web_notifications: false,
					// Using 24 for daily digest when email is enabled, 0 otherwise
					frequency: emailEnabled ? 24 : 0,
				},
			}).unwrap();

			// Update original values to match current state
			setOriginalValues({
				days,
				startTime,
				endTime,
				emailEnabled,
				slackEnabled,
			});

			setToastData({
				title: "User Notifications",
				status: "success",
				description: "Preferences updated successfully",
			});
			setOpenToast(true);
		} catch (error) {
			console.error("Error submitting form:", error);
			setToastData({
				title: "User Notifications",
				status: "error",
				description: "Error Updating Preferences",
			});
			setOpenToast(true);
		}
	};

	return (
		<UserNotificationContainer>
			<form onSubmit={handleSubmit}>
				{!isMobile && <SectionTitle>User Notifications</SectionTitle>}
				<Description
					style={{ paddingBottom: 20, borderBottom: "1px solid #ccc" }}
				>
					MightyBot extension only sends notifications for work ready to review,
					tasks it will do for you and reminders. Find them in the Home tab and
					update settings as needed.
				</Description>

				{/* Email Alerts Section */}
				<div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
					<SubSectionTitle>Email Alerts</SubSectionTitle>
					<NotificationItem
						style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
					>
						<Checkbox
							checked={emailEnabled}
							onCheckedChange={(checked) => {
								const newValue = checked === true;
								setEmailEnabled(newValue);
							}}
						/>
						<div>
							<NotificationTitle>
								Daily Digest (Start and End of the day)
							</NotificationTitle>
						</div>
					</NotificationItem>
					<NotificationItem
						style={{ borderBottom: "1px solid #ccc", paddingBottom: "20px" }}
					>
						<Checkbox
							checked={!emailEnabled}
							onCheckedChange={(checked) => {
								const newValue = checked !== true;
								setEmailEnabled(newValue);
							}}
						/>
						<div>
							<NotificationTitle>Turn off all emails</NotificationTitle>
						</div>
					</NotificationItem>
				</div>

				{/* Slack Alerts Section */}
				<div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
					<SubSectionTitle>Slackbot Alerts</SubSectionTitle>
					<NotificationItem
						style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
					>
						<Checkbox
							checked={slackEnabled}
							onCheckedChange={(checked) => {
								const newValue = checked === true;
								setSlackEnabled(newValue);
							}}
						/>
						<div>
							<NotificationTitle>Meeting Summary (Pre/Post)</NotificationTitle>
						</div>
					</NotificationItem>
					<NotificationItem
						style={{ borderBottom: "1px solid #ccc", paddingBottom: "20px" }}
					>
						<Checkbox
							checked={!slackEnabled}
							onCheckedChange={(checked) => {
								const newValue = checked !== true;
								setSlackEnabled(newValue);
							}}
						/>
						<div>
							<NotificationTitle>
								Turn off all Slackbot alerts
							</NotificationTitle>
						</div>
					</NotificationItem>
				</div>

				{/* Weekly Schedule */}
				<div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
					<SubSectionTitle>Weekly schedule</SubSectionTitle>
					<SubDescription>
						You'll only receive notifications in the hours you choose. Outside
						of those times, notifications will be paused.
					</SubDescription>
					<SelectContainer
						style={{
							marginBottom: "20px",
							borderBottom: "1px solid #ccc",
							paddingBottom: "20px",
						}}
					>
						<Select.Root value={days} onValueChange={(value) => setDays(value)}>
							<Select.Trigger
								placeholder="Select days..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{notificationDays.map((option) => (
									<Select.Item key={option.value} value={option.value}>
										{option.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>

						<Select.Root
							value={startTime.toString()}
							onValueChange={(value) => setStartTime(parseInt(value))}
						>
							<Select.Trigger
								placeholder="Select start time..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{startTimes.map((time) => (
									<Select.Item key={time.value} value={time.value}>
										{time.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>
						<Text>to</Text>

						<Select.Root
							value={endTime.toString()}
							onValueChange={(value) => setEndTime(parseInt(value))}
						>
							<Select.Trigger
								placeholder="Select end time..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{endTimes.map((time) => (
									<Select.Item key={time.value} value={time.value}>
										{time.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>
					</SelectContainer>
				</div>

				{/* Submit Button */}
				<Button
					style={{ marginLeft: "20px", marginBottom: "20px" }}
					type="submit"
					disabled={!hasChanges}
				>
					Save
				</Button>
				<Toast
					open={openToast}
					setOpen={setOpenToast}
					title={toastData.title || "Notifications"}
					type={toastData.status}
					description={toastData.description || ""}
				/>
			</form>
		</UserNotificationContainer>
	);
};

export default UserNotification;
