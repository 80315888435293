import React, { useEffect, useState } from "react";
import {
	GoToEndButton,
	GoToEndButtonContainer,
	MeetingContentContainer,
} from "./styled";
import MeetingContentScroller from "./MeetingContentScroller";
import MeetingContentPrompt from "./MeetingContentPrompt";
import { Flex, Icon, Tabs } from "@mightybot/web-ui";
import { MeetingChatProvider } from "../../contexts/MeetingChatContext";
import { MeetingContentHoverProvider } from "../../contexts/MeetingContentHoverContext";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { useParams } from "react-router-dom";
import MeetingTranscriptSidebar from "../MeetingTranscriptSidebar";
import { ToggleButton } from "../MeetingTranscriptSidebar/MeetingTranscriptSidebar";
import { cn } from "../../utils/classNames";

interface MeetingContentProps {
	isSidebarOpen?: boolean;
}

const MeetingContent: React.FC<MeetingContentProps> = ({ isSidebarOpen }) => {
	const { view } = useParams();

	const [showGoToEnd, setShowGoToEnd] = useState(false);
	const { containerRef, endOfContentRef, scrollerRef } =
		useMeetingContentNavigation();

	const [isTranscriptSidebarOpen, setIsTranscriptSidebarOpen] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
				const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100; // 100px threshold

				setShowGoToEnd(!isAtBottom);
			}
		};

		const container = containerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			window.addEventListener("resize", handleScroll);

			return () => {
				container.removeEventListener("scroll", handleScroll);
				window.removeEventListener("resize", handleScroll);
			};
		}
	}, [containerRef]);

	const scrollToEnd = () => {
		if (endOfContentRef.current) {
			endOfContentRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const toggleTranscriptSidebar = () => {
		setIsTranscriptSidebarOpen(!isTranscriptSidebarOpen);
	};

	return (
		<MeetingChatProvider>
			<MeetingContentHoverProvider>
				<div
					className={cn(
						"h-full overflow-hidden flex flex-col w-full pb-[10px]",
						isSidebarOpen &&
							"border-x-1 border-border-primary border-solid border-y-0",
					)}
				>
					<Tabs.Root defaultValue="summary" className="h-full flex flex-col">
						<Tabs.List size="1" className="h-[61px] relative">
							<Tabs.Trigger value="summary" className=" py-[30px]">
								<div className="flex items-center gap-2 text-lg">
									<Icon.FileText />
									Summary
								</div>
							</Tabs.Trigger>

							<Tabs.Trigger value="smart_clips" className=" py-[30px]">
								<div className="flex items-center gap-2 text-lg">
									<Icon.Crop />
									Smart Clips
								</div>
							</Tabs.Trigger>
							<ToggleButton
								onClick={toggleTranscriptSidebar}
								isExpanded={isTranscriptSidebarOpen}
							/>
						</Tabs.List>
						<Tabs.Content value="summary" className="flex-1 overflow-hidden">
							<MeetingContentContainer ref={containerRef}>
								<Flex
									direction="column"
									ref={scrollerRef}
									style={{ width: "100%", flex: 1 }}
								>
									<MeetingContentScroller endOfContentRef={endOfContentRef} />
								</Flex>
								<Flex
									direction="column"
									style={{
										width: "100%",
										position: "sticky",
										bottom: 60,
									}}
								>
									<GoToEndButtonContainer
										className={showGoToEnd ? "visible" : ""}
									>
										<GoToEndButton onClick={scrollToEnd}>
											<Icon.ArrowDown
												fill="#333"
												style={{ width: "20px", height: "20px", flexShrink: 0 }}
											/>
										</GoToEndButton>
									</GoToEndButtonContainer>
									{/* TODO: add back in */}
									{view === "details" && <MeetingContentPrompt />}
								</Flex>
							</MeetingContentContainer>
						</Tabs.Content>
						<Tabs.Content
							value="smart_clips"
							className="h-full overflow-hidden"
						>
							<div className="flex items-center justify-center h-full">
								Feature Coming Soon
							</div>
						</Tabs.Content>
					</Tabs.Root>
				</div>
			</MeetingContentHoverProvider>
			<MeetingTranscriptSidebar isOpen={isTranscriptSidebarOpen} />
		</MeetingChatProvider>
	);
};

export default MeetingContent;
