import {
	Meeting,
	useAgentSearch,
	useMeetingsListBidirectional,
	useSearchSelection,
	useGetMeetingsMetastoreIds,
  useFavorites,
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { Flex, Text, Icon, Checkbox } from "@mightybot/web-ui";
import { InfiniteScroll } from "./InfiniteScroll";
import {
	TableHeaderContainer,
	ActionButton,
	CategoryOption,
	TableContainer,
} from "./styled";
import PlugNPlaySearchBar from "../Search/PlugNPlaySearchBar";
import { MeetingsListFilters } from "./MeetingsListFilters";
import { JoinMeetingDialog } from "../JoinMeetingDialog/JoinMeetingDialog";
import { MeetingsListTable } from "./MeetingsListTable";
import { UUID } from "crypto";
import { useNavigate } from "react-router-dom";

type BulkActionsProps = {
	selectedCount: number;
	onRemove: () => void;
	onStar: () => void;
	onAddTag: () => void;
	onChatWithMeetings: () => void;
	onReset: () => void;
};

const TableHeader = ({
	onSelectAll,
	areAllSelected,
}: {
	onSelectAll: (checked: boolean) => void;
	areAllSelected: boolean;
}) => {
	return (
		<TableHeaderContainer
			p="8px 16px"
			align="center"
			width="100%"
			id="meetings-list-table-header"
			gap="10px"
		>
			<Flex>
				<Checkbox
					checked={areAllSelected}
					onCheckedChange={(checked) => onSelectAll(checked as boolean)}
				/>
			</Flex>
			<Flex style={{ flex: 0.2, fontSize: "13px" }}>When</Flex>
			<Flex style={{ flex: 0.6, fontSize: "13px" }}>Meeting Name</Flex>
			<Flex style={{ flex: 0.3, fontSize: "13px" }}>Category</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>Type & Tags</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>
				<Text style={{ marginLeft: "-5px" }}>Participants</Text>
			</Flex>
			{/* <Flex style={{ flex: 0.8 }}>
				<Text weight="light" style={{ fontSize: "13px" }}>
					MightyBot Joins
				</Text>
				<Tooltip content="Meeting organizer can turn off MightyBot for everyone.">
					<Icon.Info size={16} />
				</Tooltip>
			</Flex> */}
		</TableHeaderContainer>
	);
};

const BulkActions = ({
	selectedCount,
	onRemove,
	onStar,
	onAddTag,
	onChatWithMeetings,
	onReset,
}: BulkActionsProps) => (
	<Flex align="center" gap="16px">
		<Text size="3" weight="bold">
			{selectedCount} Meetings Selected
		</Text>
		<ActionButton onClick={onReset}>
			<Icon.X size={16} />
			<Text>Reset Selection</Text>
		</ActionButton>
		{/* <ActionButton onClick={onRemove}>
			<Icon.Trash size={16} />
			<Text>Remove</Text>
		</ActionButton> */}
		<ActionButton onClick={onStar}>
			<Icon.Star size={16} />
			<Text>Star Selected</Text>
		</ActionButton>
		{/* <ActionButton onClick={onAddTag}>
			<Icon.Tag size={16} />
			<Text>Add Tag</Text>
		</ActionButton> */}
		<ActionButton onClick={onChatWithMeetings}>
			<Icon.ChatTeardropText size={16} />
			<Text>Chat with Meetings</Text>
		</ActionButton>
	</Flex>
);

type CategoryType =
	| "All"
	| "Customers"
	| "Prospects"
	| "Partners"
	| "Team"
	| "Others";

const MeetingsList = () => {
	const navigate = useNavigate();
	const { searchCounts } = useAgentSearch();
	const {
		data: meetings,
		isLoading,
		error,
		hasNextPage,
		hasPreviousPage,
		fetchNextPage,
		fetchPreviousPage,
	} = useMeetingsListBidirectional({
		initialCursor: new Date().toISOString(),
		perPage: 50,
	});
	const meetingsCount = searchCounts?.counts?.["Meetings"] ?? 0;
	const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>({});
	const [selectedCategory, setSelectedCategory] = useState<CategoryType>("All");
	const [isStarred, setIsStarred] = useState(false);
	const todayGroupRef = useRef<HTMLDivElement>(null);
	const [selectedMeetings, setSelectedMeetings] = useState<Set<string>>(
		new Set(),
	);
	const { setInitialSelectedResults } = useSearchSelection();
	const { getMeetingsMetastoreIds } = useGetMeetingsMetastoreIds();
	const { favorites, isFavoritesLoading, toggleFavorite, toggleBulkFavorites } =
		useFavorites();

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETINGS_LIST_VIEWED, {
			[ANALYTICS_PROPERTIES.MEETINGS_COUNT]: meetings?.length || 0,
		});
	}, [meetings?.length]);

	const handleDateChange = useCallback((range: { from?: Date; to?: Date }) => {
		setDateFilter(range);
	}, []);

	const scrollToToday = () => {
		const today = new Date().toDateString();
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);

		// Try to find and scroll to today's group
		const todayElement = document.getElementById(`group-Today`);
		const yesterdayElement = document.getElementById(`group-Yesterday`);

		if (todayElement) {
			todayElement.scrollIntoView({ behavior: "smooth" });
		} else if (yesterdayElement) {
			yesterdayElement.scrollIntoView({ behavior: "smooth" });
		}
	};

	const categoryOptions: CategoryType[] = [
		"All",
		"Customers",
		"Prospects",
		"Partners",
		"Team",
		"Others",
	];

	// Filter meetings based on isStarred
	const filteredMeetings = useMemo(() => {
		if (!meetings) return [];

		let filtered = [...meetings];

		// Filter by starred status
		if (isStarred) {
			filtered = filtered.filter((meeting) => favorites.includes(meeting.id));
		}

		// Filter by category if needed
		// if (selectedCategory !== "All") {
		// 	filtered = filtered.filter(
		// 		(meeting) => meeting.category === selectedCategory,
		// 	);
		// }

		return filtered;
	}, [meetings, isStarred, favorites, selectedCategory]);

	// Update handleSelectAll to use filteredMeetings
	const handleSelectAll = useCallback(
		(checked: boolean) => {
			Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
				[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: checked
					? "select_all"
					: "deselect_all",
				[ANALYTICS_PROPERTIES.SELECTED_COUNT]: checked ? meetings?.length : 0,
			});
			if (checked) {
				const allMeetingIds = new Set(filteredMeetings?.map((m) => m.id));
				setSelectedMeetings(allMeetingIds);
			} else {
				setSelectedMeetings(new Set());
			}
		},
		[filteredMeetings],
	);

	const handleSelectChange = useCallback((id: string, checked: boolean) => {
		if (checked) {
			Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_SELECTED, {
				meeting_id: id,
			});
		}
		setSelectedMeetings((prev) => {
			const newSelected = new Set(prev);
			if (checked) {
				newSelected.add(id);
			} else {
				newSelected.delete(id);
			}
			return newSelected;
		});
	}, []);

	const handleBulkRemove = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "remove",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		// Implement bulk remove logic
		console.log("Removing selected meetings:", selectedMeetings);
	};

	const handleBulkStar = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "star",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		// Implement bulk star logic
		toggleBulkFavorites(Array.from(selectedMeetings));
	};

	const handleBulkAddTag = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "add_tag",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		// Implement bulk tag logic
		console.log("Adding tags to selected meetings:", selectedMeetings);
	};

	const handleChatWithMeetings = async () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_CHAT_STARTED, {
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		const selectedMeetingsList = meetings?.filter((m) =>
			selectedMeetings.has(m.id),
		) as Meeting[];

		const meetingIdsArray = selectedMeetingsList.map((m) => m.id);

		const metastoreIds = await getMeetingsMetastoreIds(meetingIdsArray);

		const selectedResults = selectedMeetingsList.map((m) => ({
			id: metastoreIds.find((id) => id.meeting_id === m.id)
				?.metastore_id as UUID,
			app: "Meetings",
			title: m.title,
			time: m.start_time,
		}));

		// Filter out any results that don't have an id
		const filteredResults = selectedResults.filter((result) => result.id);
		setInitialSelectedResults(filteredResults);
		navigate("/chats");
	};

	return (
		<Flex
			direction="column"
			p="20px 80px"
			gap="12px"
			style={{ height: "100vh" }}
		>
			<PlugNPlaySearchBar
				placeholder="Search Meetings"
				presetFilters={{ appFilter: ["meetings"] }}
			/>
			<Flex
				justify="between"
				align="center"
				width="100%"
				id="meetings-list-filters"
			>
				<MeetingsListFilters onDateChange={handleDateChange} />
				<JoinMeetingDialog
					isSidebarOpen={true}
					radius="medium"
					triggerStyles={{ height: "34px" }}
				/>
			</Flex>
			<Flex align="center" gap="8px" id="meetings-list-actions">
				{selectedMeetings.size > 0 ? (
					<BulkActions
						selectedCount={selectedMeetings.size}
						onRemove={handleBulkRemove}
						onStar={handleBulkStar}
						onAddTag={handleBulkAddTag}
						onChatWithMeetings={handleChatWithMeetings}
						onReset={() => setSelectedMeetings(new Set())}
					/>
				) : (
					<>
						<Text size="3" weight="bold">
							Meetings
						</Text>
						<Text size="1" weight="light">
							{meetingsCount ?? 0}
						</Text>
						<Flex align="center" gap="16px">
							{/* Today scroll button */}
							<ActionButton onClick={scrollToToday}>
								<Icon.CalendarBlank size={16} />
								<Text>Today</Text>
							</ActionButton>

							{/* Category options */}
							{/* <Flex
								align="center"
								style={{
									borderRadius: "var(--radius-pill)",
									border: "1px solid var(--mb-gray-5)",
									height: "28px",
								}}
							>
								{categoryOptions.map((category) => (
									<CategoryOption
										key={category}
										active={selectedCategory === category ? "true" : "false"}
										onClick={() => setSelectedCategory(category)}
									>
										{category}
									</CategoryOption>
								))}
							</Flex> */}

							{/* Starred meetings toggle */}
							<ActionButton
								data-active={isStarred}
								onClick={() => setIsStarred(!isStarred)}
							>
								<Icon.Star size={16} weight={isStarred ? "fill" : "regular"} />
								<Text>Starred</Text>
							</ActionButton>
						</Flex>
					</>
				)}
			</Flex>

			<TableContainer direction="column" id="meetings-list-table">
				<TableHeader
					onSelectAll={handleSelectAll}
					areAllSelected={
						selectedMeetings.size === filteredMeetings?.length &&
						filteredMeetings?.length > 0
					}
				/>
				<InfiniteScroll
					data={filteredMeetings}
					isLoading={isLoading || isFavoritesLoading}
					error={error}
					hasNextPage={hasNextPage}
					hasPreviousPage={hasPreviousPage}
					onFetchNextPage={fetchNextPage}
					onFetchPreviousPage={fetchPreviousPage}
					renderItems={(meetings: Meeting[] = []) => (
						<MeetingsListTable
							meetings={meetings}
							selectedMeetings={selectedMeetings}
							onSelectChange={handleSelectChange}
						/>
					)}
					enablePastScroll={true}
					initialPosition="group-Today"
					showNoMoreItems={false}
				/>
			</TableContainer>
		</Flex>
	);
};

export default React.memo(MeetingsList);
