import React, { useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
	AddressElement,
} from "@stripe/react-stripe-js";
import { Text, Flex } from "@mightybot/web-ui";
import { useSubscribeMutation } from "@mightybot/core";
import { SubmitButton } from "./styled";
import { ERROR_MESSAGES } from "./errors";
import Toast from "../../../components/Toast";

interface PaymentFormProps {
	planId: string;
	billingCycle: "monthly" | "yearly";
	amount: number;
	onSuccess: () => void;
	onClose: () => void;
	onFailure: () => void;
	clientSecret: string;
	seats: number;
}

export const PaymentForm = ({
	planId,
	billingCycle,
	amount,
	onSuccess,
	onClose,
	onFailure,
	clientSecret,
	seats,
}: PaymentFormProps) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [isToastOpen, setIsToastOpen] = useState(false);

	const [subscribe] = useSubscribeMutation();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError(undefined);
		setSuccess(undefined);

		if (!stripe || !elements) {
			setError("Payment processing not available");
			return;
		}

		setLoading(true);

		try {
			// 1. Submit the Payment Element before confirming setup
			const { error: submitError } = await elements.submit();
			if (submitError) {
				handlePaymentError(submitError.code || "unknown_error");
				onFailure();
				return;
			}

			// 2. Confirm Setup Intent with Payment Element
			const { setupIntent, error: setupError } = await stripe.confirmSetup({
				elements,
				clientSecret,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: "if_required",
			});

			if (setupError) {
				handlePaymentError(setupError.code || "unknown_error");
				onFailure();
				return;
			}

			if (setupIntent?.status === "succeeded") {
				// 3. Create Subscription with seats
				await subscribe({
					plan_id: planId,
					payment_method_id: setupIntent.payment_method as string,
					billing_cycle: billingCycle,
					seats: seats,
				}).unwrap();

				setSuccess("Payment successful! Your subscription is now active.");
				setTimeout(() => {
					onSuccess();
					window.location.reload(); // Reload to update subscription status
				}, 2000);
			}
		} catch (err) {
			console.error("Payment process failed:", err);
			setError(ERROR_MESSAGES.default);
		} finally {
			setLoading(false);
			onFailure();
		}
	};

	const handlePaymentError = (code: string) => {
		setError(
			ERROR_MESSAGES[code as keyof typeof ERROR_MESSAGES] ||
				ERROR_MESSAGES.default,
		);
		onFailure();
	};

	return (
		<form onSubmit={handleSubmit}>
			<Flex direction="column" gap="4">
				{error && (
					<Toast
						open={isToastOpen}
						setOpen={setIsToastOpen}
						title="Error"
						description={error}
					/>
				)}

				{success && <Text color="green">{success}</Text>}

				<div>
					<Text size="2" weight="medium" mb="2">
						Contact & Billing Information
					</Text>
					<div
						style={{
							padding: "16px",
							border: "1px solid var(--mb-gray-4)",
							borderRadius: "8px",
							background: "white",
							marginBottom: "16px",
						}}
					>
						<AddressElement
							options={{
								mode: "billing",
								fields: {
									phone: "never",
								},
							}}
						/>
					</div>
				</div>

				<div>
					<Text size="2" weight="medium" mb="2">
						Card information
					</Text>
					<div
						style={{
							padding: "16px",
							border: "1px solid var(--mb-gray-4)",
							borderRadius: "8px",
							background: "white",
							marginBottom: "16px",
						}}
					>
						<PaymentElement
							options={{
								layout: "tabs",
							}}
							onChange={(event) => {
								if (event.complete) {
									setError(undefined);
								}
							}}
						/>
					</div>
				</div>

				<SubmitButton type="submit" disabled={!stripe || loading}>
					{loading ? "Processing..." : `Pay $${amount * seats}/${billingCycle}`}
				</SubmitButton>
			</Flex>
		</form>
	);
};
