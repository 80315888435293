import React, { useEffect, useState } from "react";
import { Text } from "@mightybot/web-ui";
import { PlanTiles } from "../PlanTiles/ PlanTiles";
import {
	HeaderTitle,
	HeaderSubtitle,
	ContactLink,
	OverlayContent,
	StyledContentWrapper,
	Footer,
	OverlayContainer,
} from "./styled";
import { useBilling, useMe, useSetupPaymentMutation } from "@mightybot/core";
import { PaymentModal } from "../PaymentModal";
import Toast from "../../Toast";
import { useLocation } from "react-router-dom";

interface SubscriptionOverlayProps {}

export const SubscriptionOverlay: React.FC<SubscriptionOverlayProps> = () => {
	const { data: user } = useMe();
	const [billingCycle] = useState<"monthly" | "yearly">("monthly");
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [clientSecret, setClientSecret] = useState<string>("");
	const [isCreatingIntent, setIsCreatingIntent] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error" | "info",
	});
	const [isVisible, setIsVisible] = useState(false);
	const { pathname } = useLocation();

	const { plan, subscription, isSubscribed } = useBilling();
	const [createSetupIntent] = useSetupPaymentMutation();

	const handlePaymentSuccess = () => {
		setShowPaymentForm(false);
	};

	const handlePaymentFailure = () => {
		setShowPaymentForm(false);
	};

	const handleOpenPaymentModal = async () => {
		try {
			setIsCreatingIntent(true);
			const { client_secret } = await createSetupIntent().unwrap();
			setClientSecret(client_secret);
			setShowPaymentForm(true);
		} catch (error) {
			console.error("Failed to create Setup Intent:", error);
			setToastData({
				title: "Payment Setup Failed",
				description: "Failed to initialize payment setup. Please try again.",
				status: "error",
			});
			setOpenToast(true);
		} finally {
			setIsCreatingIntent(false);
		}
	};

	const type = subscription ? "subscription_expired" : "trial_expired";

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 100);

		return () => clearTimeout(timer);
	}, []);

	if (
		isSubscribed ||
		pathname.includes("/settings") ||
		!user?.onboarding_completed
	)
		return null;

	return (
		<OverlayContainer style={{ opacity: isVisible ? 1 : 0 }}>
			<OverlayContent
				style={{ transform: isVisible ? "translateY(0)" : "translateY(100%)" }}
			>
				<HeaderTitle>
					<Text as="span">
						{type === "trial_expired"
							? "Your 14-days free trial has ended"
							: "Your subscription has ended"}
					</Text>
				</HeaderTitle>
				<HeaderSubtitle>
					<Text as="span">
						Upgrade now to continue enjoying seamless automation and
						productivity boosts.
					</Text>
				</HeaderSubtitle>

				<StyledContentWrapper>
					<div>
						<PlanTiles
							plan={plan}
							billingCycle={billingCycle}
							onSubscribe={handleOpenPaymentModal}
							isCreatingIntent={isCreatingIntent}
							showPricing={true}
						/>
					</div>
				</StyledContentWrapper>

				<Footer>
					<Text size="2" color="gray">
						Have any questions? We're here to help.{" "}
						<ContactLink
							as="a"
							href="https://www.mightybot.ai/get-a-demo"
							style={{ fontWeight: 500 }}
						>
							Contact us
						</ContactLink>
					</Text>
				</Footer>
			</OverlayContent>
			{showPaymentForm && clientSecret && (
				<PaymentModal
					isOpen={true}
					onClose={handlePaymentFailure}
					planId={plan?.id || ""}
					planName={plan?.name || ""}
					billingCycle="monthly"
					amount={plan?.prices?.monthly || 0}
					clientSecret={clientSecret}
					seats={1}
					onSuccess={handlePaymentSuccess}
					onFailure={handlePaymentFailure}
				/>
			)}
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</OverlayContainer>
	);
};
