import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate, useParams } from "react-router-dom";

import MeetingPreview from "../components/MeetingPreview";
import MeetingDetails from "../components/MeetingDetails";
import MeetingDetailsV2 from "../components/MeetingDetailsV2";
import {
	EXAMPLE_MEETINGS_KEY,
	ExampleMeetingData,
	NEW_MEETINGS_LIST_PAGE_FEATURE,
} from "@mightybot/core";
import { useContentful } from "react-contentful";
import localforage from "localforage";

import { NEW_MEETING_DETAIL_PAGE_FEATURE } from "@mightybot/core";
import { useFeatureFlag } from "@mightybot/core";
import MeetingsList from "../components/MeetingsList";

export default function MeetingsDetailRoute() {
	let { view, meetingId } = useParams();
	const navigate = useNavigate();
	let {
		data: exampleMeetingData,
		error,
		fetched,
		loading,
	} = useContentful({
		contentType: "exampleMeetingData",
		id: "1HgyfwQm1jFZ7Lcb0nK727",
	});

	useEffect(() => {
		if (exampleMeetingData) {
			localforage.setItem(EXAMPLE_MEETINGS_KEY, {
				meetings:
					(exampleMeetingData as ExampleMeetingData)?.fields?.meetings ?? [],
				meetingContent:
					(exampleMeetingData as ExampleMeetingData)?.fields?.meetingContent ??
					[],
			});
		}
	}, [exampleMeetingData]);

	if (!view && meetingId) {
		navigate(`/meetings/${meetingId}/preview`);
	}

	const isNewMeetingDetailsPageEnabled =
		useFeatureFlag(NEW_MEETING_DETAIL_PAGE_FEATURE) || false;
	const isNewMeetingsListPage = useFeatureFlag(NEW_MEETINGS_LIST_PAGE_FEATURE);

	return (
		<Layout>
			{view === "details" ? (
				isNewMeetingDetailsPageEnabled ? (
					<MeetingDetailsV2 />
				) : (
					<MeetingDetails />
				)
			) : isNewMeetingsListPage ? (
				<MeetingsList />
			) : (
				<MeetingPreview />
			)}
		</Layout>
	);
}
