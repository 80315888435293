import { Meeting } from "@mightybot/core";
import React, { useRef, useMemo } from "react";
import { Flex, Text } from "@mightybot/web-ui";
import { uniqBy } from "lodash";
import { formatMeetingDateTime } from "../../utils/helpers";
import { MeetingsListRow } from "./MeetingsListRow";

type MeetingsListTableProps = {
	meetings: Meeting[];
	selectedMeetings: Set<string>;
	onSelectChange: (id: string, checked: boolean) => void;
};
export const MeetingsListTable = React.memo(
	({ meetings, selectedMeetings, onSelectChange }: MeetingsListTableProps) => {
		const uniqueMeetings = useMemo(
			() => uniqBy(meetings || [], "id"),
			[meetings],
		);
		const todayGroupRef = useRef<HTMLDivElement>(null);

		const groupedAndSortedMeetings = useMemo(() => {
			const groups: { [key: string]: Meeting[] } = {};
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const yesterday = new Date(today);
			yesterday.setDate(yesterday.getDate() - 1);
			const tomorrow = new Date(today);
			tomorrow.setDate(tomorrow.getDate() + 1);

			uniqueMeetings.forEach((meeting: Meeting) => {
				const meetingDate = new Date(meeting.start_time);
				let key: string;

				if (meetingDate.toDateString() === yesterday.toDateString()) {
					key = "Yesterday";
				} else if (meetingDate.toDateString() === today.toDateString()) {
					key = "Today";
				} else if (meetingDate.toDateString() === tomorrow.toDateString()) {
					key = "Tomorrow";
				} else {
					key = meetingDate.toDateString();
				}

				if (!groups[key]) {
					groups[key] = [];
				}
				groups[key].push(meeting);
			});

			const specialOrder = ["Tomorrow", "Today", "Yesterday"];
			return Object.entries(groups).sort(([dateA], [dateB]) => {
				const indexA = specialOrder.indexOf(dateA);
				const indexB = specialOrder.indexOf(dateB);
				const dateObjA = new Date(dateA);
				const dateObjB = new Date(dateB);

				// If both are special dates, use the special order
				if (indexA !== -1 && indexB !== -1) {
					return indexA - indexB;
				}

				// If one is a special date and the other isn't
				if (indexA !== -1) {
					return dateObjB > tomorrow ? 1 : -1;
				}
				if (indexB !== -1) {
					return dateObjA > tomorrow ? -1 : 1;
				}

				// For non-special dates
				if (dateObjA > tomorrow && dateObjB > tomorrow) {
					// Both are future dates, sort in descending order
					return dateObjB.getTime() - dateObjA.getTime();
				}
				if (dateObjA < yesterday && dateObjB < yesterday) {
					// Both are past dates, sort in descending order
					return dateObjB.getTime() - dateObjA.getTime();
				}

				// If one is future and one is past
				if (dateObjA > tomorrow) return -1;
				if (dateObjB > tomorrow) return 1;

				// This case should not happen, but just in case
				return dateObjB.getTime() - dateObjA.getTime();
			});
		}, [uniqueMeetings]);

		return (
			<Flex direction="column" style={{ position: "relative", width: "100%" }}>
				{groupedAndSortedMeetings.map(([date, meetings], index, array) => (
					<Flex
						direction="column"
						id={`group-${date}`}
						key={`group-${date}`}
						ref={date === "Today" ? todayGroupRef : null}
					>
						<Text
							id={formatMeetingDateTime(date).dayDate}
							style={{
								padding: "6px 6px 4px 16px",
								color:
									date === "Today" ? "var(--mb-blue-8)" : "var(--mb-gray-11)",
								fontWeight: "bold",
								background: "var(--mb-gray-3)",
								fontSize: "16px",
							}}
						>
							{date}
						</Text>
						{meetings.map((meeting) => (
							<MeetingsListRow
								meeting={meeting}
								index={index}
								key={meeting.id}
								isSelected={selectedMeetings.has(meeting.id)}
								onSelectChange={onSelectChange}
							/>
						))}
					</Flex>
				))}
			</Flex>
		);
	},
);
