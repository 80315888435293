import styled from "styled-components";
import { Flex, mediaQueries } from "@mightybot/web-ui";

export const LayoutContainer = styled(Flex)`
	flex-direction: row;
	height: 100vh;
	width: 100%;
	position: relative;
`;

export const ContentContainer = styled(Flex)`
	flex: 1;
	justify-content: center;
	overflow-y: auto;
	z-index: 1;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 85px;
	background-color: var(--gray-1);
	box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	display: flex;
	flex-direction: column;
	${mediaQueries.tablet} {
		position: relative;
		left: 0;
		margin-top: 50px;
		border-radius: 0px;
		box-shadow: none;
	}
`;

export const InnerContentContainer = styled(Flex)`
	flex: 1;
	width: 100%;
	min-height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const ScrollableContent = styled.div`
	flex: 1;
	overflow-y: hidden;
`;
