import { Flex, Accordion, Icon, mediaQueries } from "@mightybot/web-ui";
import styled from "styled-components";
import IndexingProgress from "../IndexingProgress/IndexingProgress";

export const HomeContainer = styled(Flex)`
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	position: relative;
`;

export const HomeInnerContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	overflow: scroll;
`;

export const HomeHeaderContainer = styled(Flex)`
	position: sticky;
	top: 0;
	z-index: 10;
	padding: 10px;
	max-width: 870px;
	width: 100%;
	background: rgba(var(--mb-gray-1-rgb), 0.6);
	backdrop-filter: blur(8px);
	webkit-backdrop-filter: blur(8);
	${mediaQueries.tablet} {
		padding: 0px 10px;
	}
`;

export const HomeHeaderInnerContainer = styled(Flex)`
	max-width: 870px;
	width: 100%;
`;

export const TagContainer = styled(Flex)`
	border-radius: 100px;
	height: 24px;
	padding: 0px 12px;
`;

export const FeedContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	border-radius: 30px;
	width: 100%;
	padding: 20px 30px;
	gap: 5px;
	background: linear-gradient(
			180deg,
			var(--mb-blue-2) 0%,
			rgba(220, 232, 255, 0.2) 100%
		),
		var(--mb-gray-1);
	max-width: 870px;
	${mediaQueries.tablet} {
		border-radius: 0px;
		padding: 10px;
		background: none;
	}
`;

export const FeedCardContainer = styled(Flex)`
	flex-direction: column;
	justify-content: center;
	width: 100%;
	border-radius: 12px;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	padding: 10px 9px 6px 9px;
	border-width: 0px;
	background: var(--mb-gray-1);
	gap: 4px;
	margin-bottom: 5px;
	max-height: 140px;
	${mediaQueries.tablet} {
		box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
		background: var(--mb-gray-1);
		border: 1px solid var(--mb-gray-4);
	}
`;

export const FeedTileContainer = styled(Flex)`
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 12px;
	border-width: 0px;
	background: var(--mb-gray-1);
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	max-width: 263px;
	width: 100%;
	height: 180px;
	padding: 10px 9px 6px 9px;
	gap: 5px;
`;

export const HeaderItemContainer = styled(Flex)`
	gap: 6px;
	border-radius: 4px;
	padding: 0px 8px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	transition: background-color 0.3s ease;
	background-color: var(--mb-blue-1);
`;

export const FilterContainer = styled(Flex)`
	flex-direction: column;
	background: var(--mb-gray-1);
	padding: 6px 0px;
	border-radius: 4px;
	gap: 10px;
`;

export const CategoryFilterContainer = styled(Flex)`
	flex-direction: column;
	background: var(--mb-gray-1);
	padding: 0px 0px;
	border-radius: 4px;
	gap: 10px;
`;

export const FilterTabsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
	max-width: 870px;
	width: 100%;
	${mediaQueries.tablet} {
		padding: 0px 10px;
	}
`;

export const FilterTab = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	background-color: var(--mb-blue-1);
	border-radius: 4px;
	padding: 4px 8px;
	cursor: pointer;

	&:hover {
		background-color: var(--mb-blue-2);
	}

	svg {
		cursor: pointer;
		&:hover {
			color: var(--mb-red-9);
		}
	}
`;

export const customMarkdownStyles = {
	heading: {
		fontSize: "14px",
		margin: "0px",
	},
	content: {
		userSelect: "text",
		overflow: "auto",
		whiteSpace: "pre-wrap",
		fontSize: "14px",
		scrollbarWidth: "none",
		msOverflowStyle: "none",
		WebkitScrollbar: {
			display: "none",
		},
	},
	paragraph: {
		marginBottom: "0",
		fontSize: "14px",
	},
	link: {
		color: "var(--mb-blue-8)",
		textDecoration: "underline",
		fontSize: "14px",
		cursor: "pointer",
	},
};

export const HomeFeedContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	overflow: hidden;
	margin: auto;
`;

export const StyledIndexingProgress = styled(IndexingProgress)`
	max-width: 870px;
	width: 100%;
`;

export const Cube = styled.div<{ color: string }>`
	width: 10px;
	height: 10px;
	background-color: ${({ color }) => color};
	margin-right: 6px;
	border-radius: 2px;
`;

export const AccordionContainer = styled(Accordion.Root)`
	width: 100%;
	gap: 5px;
	display: flex;
	flex-direction: column;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
	background: none;
	border: none;
	box-shadow: none;
	padding: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 5px;
	&:hover {
		background: none;
	}
`;

export const AccordionContent = styled(Accordion.Content)`
	overflow: hidden;
	// Remove any existing animation properties
	background: none;
	&[data-state="open"] {
		animation: slideDown 300ms ease-out;
	}
	&[data-state="closed"] {
		animation: slideUp 300ms ease-out;
	}
	@keyframes slideDown {
		from {
			height: 0;
		}
		to {
			height: var(--radix-accordion-content-height);
		}
	}
	@keyframes slideUp {
		from {
			height: var(--radix-accordion-content-height);
		}
		to {
			height: 0;
		}
	}
`;

export const AccordionItem = styled(Accordion.Item)`
	background: none;
`;

export const AccordionHeader = styled(Accordion.Header)`
	background: none;
	box-shadow: none;
	margin: 0px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const AccordionChevron = styled(Icon.CaretRight)`
	transition: transform 0.3s ease;
	[data-state="open"] & {
		transform: rotate(90deg);
	}
`;

export const DropdownChevron = styled(Icon.CaretRight)`
	transition: transform 0.3s ease;
	[data-state="open"] & {
		transform: rotate(90deg);
	}
`;
