import React, { ReactNode } from "react";
import { Popover, Button } from "@mightybot/web-ui";
import { PopoverContent, FilterContent, ActionButtons } from "./styled";

interface FilterPopoverProps extends React.PropsWithChildren {
	trigger: ReactNode;
	onApply: () => void;
	onCancel: () => void;
	isOpen?: boolean;
	onOpenChange?: (open: boolean) => void;
	showActionButtons?: boolean;
}

const FilterPopover: React.FC<FilterPopoverProps> = ({
	trigger,
	children,
	onApply,
	onCancel,
	isOpen,
	onOpenChange,
	showActionButtons = true,
}) => {
	return (
		<Popover.Root open={isOpen} onOpenChange={onOpenChange}>
			<Popover.Trigger>{trigger}</Popover.Trigger>
			<PopoverContent>
				<FilterContent>{children}</FilterContent>
				{showActionButtons && (
					<ActionButtons>
						<Button variant="white-bg" onClick={onCancel}>
							Cancel
						</Button>
						<Button onClick={onApply}>Apply</Button>
					</ActionButtons>
				)}
			</PopoverContent>
		</Popover.Root>
	);
};

export default FilterPopover;
