import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	MeetingPresencePreferences as MeetingPresencePreferencesType,
	useMe,
	useUserPreferences,
	useEnterprisePreferences,
	useSaveEnterprisePreferences,
	SaveEnterprisePreferencesRequest,
	User,
	EnterprisePreferences,
} from "@mightybot/core";
import type { UserPreferencesResponse as UserPreferences } from "@mightybot/core";
import {
	Button,
	Flex,
	TextArea,
	Text,
	Spinner,
	Switch,
	Separator,
	Skeleton,
} from "@mightybot/web-ui";
import { SettingsOptionItem } from "../styled";
import { getHostDomain } from "../../../utils/email";
import Toast from "../../Toast";
import {
	MeetingsAndSummariesContainer,
	MeetingsAndSummariesHeader,
	MeetingsAndSummariesContent,
	OptionItem,
} from "./styled";

const validateExcludedDomains = (
	domains: string,
	internal_domain: string,
): [boolean, string] => {
	if (!domains.trim()) return [true, ""]; // Empty input is valid
	if (domains.includes(internal_domain))
		return [
			false,
			`Internal domain ${internal_domain} is already excluded. Please remove it from the list.`,
		];
	const domainRegex =
		/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
	return [
		domains.split(",").every((domain) => domainRegex.test(domain.trim())),
		"",
	];
};

interface MeetingsAndSummariesProps {
	userPreferences: UserPreferences;
	enterprisePreferences: EnterprisePreferences | null;
}

function MeetingsAndSummaries() {
	const { userPreferences, updateUserPreferences, isLoadingUserPreferences } =
		useUserPreferences();
	const { data: user } = useMe();
	const {
		data: enterprisePreferences,
		isLoading: isLoadingEnterprisePreferences,
	} = useEnterprisePreferences(user?.enterprise_id ?? "");
	const [savePreferences] = useSaveEnterprisePreferences();
	const hostDomain = getHostDomain(user?.email ?? "");

	const [excludedParticipantDomains, setExcludedParticipantDomains] =
		useState<string>(
			userPreferences?.excludedParticipantDomains?.join(",") ?? "",
		);

	const [internalMeetingBotEnabled, setInternalMeetingBotEnabled] =
		useState<boolean>(userPreferences?.internalMeetingBotToggled ?? false);

	const [externalMeetingBotEnabled, setExternalMeetingBotEnabled] =
		useState<boolean>(userPreferences?.externalMeetingBotToggled ?? false);

	const [generateDocTranscript, setGenerateDocTranscript] = useState<boolean>(
		enterprisePreferences?.meeting_recording_preferences
			?.generate_doc_transcript ?? true,
	);

	const [getMeetingRecordingInDrive, setGetMeetingRecordingInDrive] =
		useState<boolean>(
			enterprisePreferences?.meeting_recording_preferences
				?.get_meeting_recording_in_drive ?? true,
		);

	const [isValidDomains, setIsValidDomains] = useState<boolean>(true);
	const [toastOpen, setToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isAdmin = useMemo(() => {
		return user?.role === "ADMIN" || user?.role === "OWNER";
	}, [user?.role]);

	const isPrefencesChanged = useMemo(() => {
		if (!userPreferences) return false;
		return (
			internalMeetingBotEnabled !== userPreferences.internalMeetingBotToggled ||
			externalMeetingBotEnabled !== userPreferences.externalMeetingBotToggled ||
			excludedParticipantDomains !==
				userPreferences.excludedParticipantDomains?.join(",")
		);
	}, [
		internalMeetingBotEnabled,
		externalMeetingBotEnabled,
		excludedParticipantDomains,
		userPreferences,
	]);

	const isEnterprisePreferencesChanged = useMemo(() => {
		if (!enterprisePreferences || !isAdmin) return false;
		return (
			generateDocTranscript !==
				enterprisePreferences?.meeting_recording_preferences
					?.generate_doc_transcript ||
			getMeetingRecordingInDrive !==
				enterprisePreferences?.meeting_recording_preferences
					?.get_meeting_recording_in_drive
		);
	}, [
		generateDocTranscript,
		getMeetingRecordingInDrive,
		enterprisePreferences,
		isAdmin,
	]);

	useEffect(() => {
		if (!isLoadingUserPreferences && userPreferences) {
			setInternalMeetingBotEnabled(
				userPreferences.internalMeetingBotToggled ?? false,
			);
			setExternalMeetingBotEnabled(
				userPreferences.externalMeetingBotToggled ?? false,
			);
			setExcludedParticipantDomains(
				userPreferences.excludedParticipantDomains?.join(",") ?? "",
			);
		}
	}, [userPreferences, isLoadingUserPreferences]);

	useEffect(() => {
		if (!isLoadingEnterprisePreferences && enterprisePreferences && isAdmin) {
			setGenerateDocTranscript(
				enterprisePreferences.meeting_recording_preferences
					?.generate_doc_transcript ?? true,
			);
			setGetMeetingRecordingInDrive(
				enterprisePreferences.meeting_recording_preferences
					?.get_meeting_recording_in_drive ?? true,
			);
		}
	}, [enterprisePreferences, isLoadingEnterprisePreferences, isAdmin]);

	const updatePreferences = useCallback(
		async (preferences: Partial<MeetingPresencePreferencesType>) => {
			try {
				await updateUserPreferences({
					meeting_presence: preferences,
				}).unwrap();
				setToastMessage("Preferences updated successfully");
				setToastOpen(true);
			} catch (error) {
				setToastMessage("Failed to update preferences");
				setToastOpen(true);
				console.error(error);
			}
		},
		[updateUserPreferences],
	);

	const handleInternalMeetingBotToggle = useCallback(() => {
		setInternalMeetingBotEnabled(!internalMeetingBotEnabled);
	}, [internalMeetingBotEnabled]);

	const handleExternalMeetingBotToggle = useCallback(() => {
		setExternalMeetingBotEnabled(!externalMeetingBotEnabled);
	}, [externalMeetingBotEnabled]);

	const handleGenerateDocTranscriptToggle = useCallback(() => {
		setGenerateDocTranscript(!generateDocTranscript);
	}, [generateDocTranscript]);

	const handleGetMeetingRecordingInDriveToggle = useCallback(() => {
		setGetMeetingRecordingInDrive(!getMeetingRecordingInDrive);
	}, [getMeetingRecordingInDrive]);

	const handleExcludedParticipantDomainsChange = (value: string) => {
		setExcludedParticipantDomains(value);
	};

	const handleSave = async () => {
		setIsLoading(true);
		const [isValid, message] = validateExcludedDomains(
			excludedParticipantDomains,
			hostDomain ?? "",
		);
		setIsValidDomains(isValid);
		setToastMessage(message);
		setToastOpen(true);

		try {
			if (isValid && isPrefencesChanged) {
				const newDomains = excludedParticipantDomains
					.split(",")
					.map((domain: string) => domain.trim())
					.filter((domain: string) => domain !== null && domain !== "");

				await updatePreferences({
					internal_meeting_bot_enabled: internalMeetingBotEnabled,
					external_meeting_bot_enabled: externalMeetingBotEnabled,
					excluded_participant_domains: newDomains,
				});
			}

			if (isEnterprisePreferencesChanged && user?.enterprise_id && isAdmin) {
				await savePreferences({
					enterprise_id: user.enterprise_id,
					preferences: {
						meeting_recording_preferences: {
							generate_doc_transcript: generateDocTranscript,
							get_meeting_recording_in_drive: getMeetingRecordingInDrive,
						},
						apps_config: enterprisePreferences?.apps_config,
					},
				}).unwrap();
			}

			setToastMessage("Settings saved successfully");
			setToastOpen(true);
		} catch (error) {
			setToastMessage("Failed to save settings");
			setToastOpen(true);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const renderToggle = (
		label: string,
		toggled: boolean,
		onToggle: () => void,
		disabled?: boolean,
	) => (
		<Flex direction="column" gap="1" style={{ width: "100%" }}>
			<Flex justify="between" align="center" style={{ width: "100%" }}>
				<Text weight="medium">{label}</Text>
				<Switch
					checked={toggled}
					onCheckedChange={onToggle}
					disabled={disabled}
				/>
			</Flex>
		</Flex>
	);

	if (isLoadingUserPreferences || isLoadingEnterprisePreferences) {
		return (
			<MeetingsAndSummariesContainer>
				<MeetingsAndSummariesHeader>
					<Skeleton width="200px" height="24px" />
				</MeetingsAndSummariesHeader>
				<MeetingsAndSummariesContent>
					<Flex direction="column" gap="3">
						<Skeleton width="120px" height="20px" />
						<SettingsOptionItem>
							<Skeleton width="100%" height="32px" />
						</SettingsOptionItem>
						<SettingsOptionItem>
							<Skeleton width="100%" height="32px" />
						</SettingsOptionItem>
					</Flex>
					<Separator orientation="horizontal" />
					<Flex direction="column" gap="2">
						<Skeleton width="160px" height="20px" />
						<Skeleton width="100%" height="133px" />
					</Flex>
				</MeetingsAndSummariesContent>
			</MeetingsAndSummariesContainer>
		);
	}

	return (
		<MeetingsAndSummariesContainer>
			<MeetingsAndSummariesHeader>
				<Text weight="medium" size="4">
					Meetings & Summaries
				</Text>
			</MeetingsAndSummariesHeader>
			<MeetingsAndSummariesContent>
				<Flex direction="column" gap="3">
					<Text weight="medium" size="2">
						MightyBot joins meeting
					</Text>
					<OptionItem>
						{renderToggle(
							"Join Internal Meetings",
							internalMeetingBotEnabled,
							handleInternalMeetingBotToggle,
						)}
					</OptionItem>
					<OptionItem>
						{renderToggle(
							"Join External Meetings",
							externalMeetingBotEnabled,
							handleExternalMeetingBotToggle,
						)}
					</OptionItem>
				</Flex>
				<Flex direction="column" gap="2">
					<Text weight="medium" size="2">
						Exclude external domains to join meeting
					</Text>
					<TextArea
						style={{
							width: "100%",
							borderColor: !isValidDomains ? "red" : undefined,
							borderWidth: !isValidDomains ? 1 : undefined,
							borderRadius: !isValidDomains ? 6 : undefined,
							fontSize: "13px",
							height: "133px",
						}}
						placeholder="Add multiple domains by adding comma (,) separator"
						value={excludedParticipantDomains}
						onChange={(e) => {
							setIsValidDomains(true);
							handleExcludedParticipantDomainsChange(e.target.value);
						}}
						size="3"
					/>
				</Flex>

				{isAdmin && (
					<>
						<Flex direction="column" gap="3">
							<Text weight="medium" size="2">
								Meeting transcription (For everyone at your enterprise){" "}
							</Text>
							<OptionItem>
								{renderToggle(
									"Generate Google doc transcription",
									generateDocTranscript,
									handleGenerateDocTranscriptToggle,
								)}
							</OptionItem>
							<OptionItem>
								{renderToggle(
									"Generate meeting recording in Google Drive",
									getMeetingRecordingInDrive,
									handleGetMeetingRecordingInDriveToggle,
								)}
							</OptionItem>
						</Flex>
					</>
				)}

				<Button
					disabled={
						(!isValidDomains || !isPrefencesChanged) &&
						!isEnterprisePreferencesChanged
					}
					onClick={handleSave}
					style={{ cursor: "pointer" }}
				>
					{isLoading ? "Saving..." : "Save"}
				</Button>
				<Toast
					open={toastOpen}
					setOpen={setToastOpen}
					title={isValidDomains ? "Success" : "Error"}
					description={toastMessage}
					type={isValidDomains ? "success" : "error"}
				/>
			</MeetingsAndSummariesContent>
		</MeetingsAndSummariesContainer>
	);
}

export default MeetingsAndSummaries;
