import React, { useState } from "react";
import { Text, Dialog, Button } from "@mightybot/web-ui";
import {
	StyledDialogContent,
	StyledDialogTitle,
	StyledTextArea,
	ButtonContainer,
} from "./styled";

interface AddMemberModalProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onSubmit: (emails: string[]) => void;
}

export const AddMemberModal: React.FC<AddMemberModalProps> = ({
	open,
	onOpenChange,
	onSubmit,
}) => {
	const [emails, setEmails] = useState("");

	const handleSubmit = () => {
		const emailList = emails
			.split(",")
			.map((email) => email.trim())
			.filter(Boolean);
		onSubmit(emailList);
		onOpenChange(false);
	};

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<StyledDialogContent>
				<StyledDialogTitle>
					<Text
						size="3"
						style={{ color: "var(--mb-gray-12)", fontWeight: "500" }}
					>
						Add member by email
					</Text>
				</StyledDialogTitle>
				<StyledTextArea
					value={emails}
					onChange={(e) => setEmails(e.target.value)}
					placeholder="Separate email with a comma(,)"
				/>
				<ButtonContainer>
					<Dialog.Close>
						<Button
							variant="white-bg"
							style={{ width: "75px", cursor: "pointer" }}
							onClick={() => onOpenChange(false)}
						>
							Cancel
						</Button>
					</Dialog.Close>
					<Dialog.Close>
						<Button
							onClick={handleSubmit}
							style={{ width: "103px", cursor: "pointer" }}
						>
							Send Invite
						</Button>
					</Dialog.Close>
				</ButtonContainer>
			</StyledDialogContent>
		</Dialog.Root>
	);
};
