import React, { useMemo, useEffect } from "react";
import {
	Flex,
	Text,
	Icon,
	Skeleton,
	Button,
	IconButton,
} from "@mightybot/web-ui";
import { useMeetingContent } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { useLocation } from "react-router-dom";
import { cn } from "../../utils/classNames";

type MeetingOutlineProps = {
	toggleSidebar: () => void;
	isSidebarOpen: boolean;
	isMobile: boolean;
};

const MeetingOutline = ({
	toggleSidebar,
	isSidebarOpen,
	isMobile,
}: MeetingOutlineProps) => {
	const meetingId = useParams().meetingId;
	const { meetingOutline, isLoading } = useMeetingContent(meetingId ?? "");
	const { activeItem, handleMeetingOutlineItemClick, updateUrlHash } =
		useMeetingContentNavigation();
	const location = useLocation();

	useEffect(() => {
		const hash = location.hash.slice(1);
		if (hash && meetingOutline) {
			const matchingItem = meetingOutline.find(
				(item) => item.title.toLowerCase().replace(/\s+/g, "-") === hash,
			);
			if (matchingItem) {
				handleMeetingOutlineItemClick(matchingItem.id, matchingItem.title);
			}
		}
	}, [location.hash, meetingOutline, handleMeetingOutlineItemClick]);

	const meetingOutlineItems = useMemo(
		() =>
			meetingOutline?.map((content: any) => ({
				text: content.title,
				id: content.id,
			})) || [],
		[meetingOutline],
	);

	const renderMeetingOutlineItems = () => {
		if (isLoading) {
			return (
				<div className="flex flex-col gap-[2px]">
					{[...Array(5)].map((_, index) => (
						<div
							key={index}
							className="flex items-center justify-between p-[8px_16px]"
						>
							<Skeleton width="80%" height="20px" />
							<Skeleton width="16px" height="16px" />
						</div>
					))}
				</div>
			);
		}

		return (
			<div
				className={cn(
					"flex gap-[2px] overflow-x-auto pl-5 pr-[14px] py-4",
					isMobile ? "flex-row" : "flex-col",
				)}
			>
				{meetingOutlineItems.map((item, index) => (
					<Button
						key={`${item.id}-${index}`}
						variant="ghost"
						onClick={() => handleMeetingOutlineItemClick(item.id, item.text)}
						className={cn(
							"flex justify-between items-center w-full px-2 rounded my-1 transition-colors bg-white cursor-pointer",
							activeItem === item.id
								? "text-light-blue-8"
								: "text-text-primary hover:bg-gray-50",
						)}
					>
						<div className="flex items-center gap-2 flex-shrink-0 min-w-0 flex-1">
							<Icon.FileText
								className={cn(
									"w-4 h-4 flex-shrink-0",
									activeItem === item.id
										? "text-light-blue-8"
										: "text-text-primary",
								)}
							/>
							<span className="truncate font-medium w-full text-left">
								{item.text}
							</span>
						</div>
						{!isMobile && (
							<div
								className={cn(
									"w-2 h-2 rounded-sm flex-shrink-0 ml-2",
									activeItem === item.id ? "bg-blue-eight" : "bg-white",
								)}
							/>
						)}
					</Button>
				))}
			</div>
		);
	};

	if (isMobile) {
		return (
			<>
				<Button
					onClick={toggleSidebar}
					className={cn(
						"w-full flex items-center justify-between p-4",
						isSidebarOpen && "border-b border-gray-200",
					)}
				>
					<Text size="3" weight="medium">
						Outline
					</Text>
					<Icon.CaretDown
						className={cn(
							"transform transition-transform duration-300",
							isSidebarOpen ? "rotate-180" : "rotate-0",
						)}
					/>
				</Button>
				<div
					className={cn(
						"transition-all duration-300 overflow-hidden",
						isSidebarOpen ? "max-h-screen" : "max-h-0",
					)}
				>
					{renderMeetingOutlineItems()}
				</div>
			</>
		);
	}

	return (
		<div
			className={cn(
				"transition-all duration-300 ",
				isSidebarOpen ? "w-[270px]" : "w-[0px]",
			)}
		>
			{isSidebarOpen ? (
				<div className="flex flex-col w-full overflow-hidden">
					<div className="flex items-center justify-between py-4 pl-5 pr-[10px] border-b border-solid border-gray-200 border-x-0 border-t-0">
						<span className="text-lg font-medium flex-1">Outline</span>
						<Icon.ArrowLineLeft
							className="cursor-pointer"
							onClick={toggleSidebar}
						/>
					</div>
					<div className="flex flex-col w-full border-b border-solid border-gray-200 border-x-0 border-t-0">
						{renderMeetingOutlineItems()}
					</div>
				</div>
			) : (
				<div className="pt-3 pl-2 absolute top-[150px] z-10">
					<IconButton
						onClick={toggleSidebar}
						className="bg-blue-one rounded-full cursor-pointer"
					>
						<Icon.ListBullets className="h-5 w-5 text-text-primary" />
					</IconButton>
				</div>
			)}
		</div>
	);
};

export default MeetingOutline;
