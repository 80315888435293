import styled from "styled-components";
import { Flex, Text } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	width: 100%;
	height: 100%;
	background: var(--mb-color-light-gray-1);
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid var(--mb-color-light-gray-5);
	flex-direction: column;
`;

export const Header = styled(Flex)`
	padding: 24px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	align-items: center;
`;

export const HeaderTitle = styled(Text)`
	color: var(--mb-color-light-gray-11);
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
`;

export const Content = styled(Flex)`
	flex: 1;
	flex-direction: column;
	padding: 10px 20px 10px 20px;
`;

export const NavTabs = styled(Flex)`
	align-items: center;
	padding: 0 24px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	gap: 32px;
	background: var(--mb-color-light-gray-1);
`;

export const TabItem = styled(Flex)<{ active?: boolean }>`
	align-items: center;
	padding: 16px 18px;
	cursor: pointer;
	position: relative;
	gap: 8px;
	color: ${(props) =>
		props.active
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-gray-8)"};
	font-size: 14px;
	font-weight: 500;

	&::after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: -18px;
		right: -18px;
		height: 2px;
		background: ${(props) =>
			props.active ? "var(--mb-color-light-blue-8)" : "transparent"};
		transition: background-color 0.2s ease;
	}

	&:hover {
		color: var(--mb-color-light-blue-8);
	}
`;

export const LogoWrapper = styled(Flex)`
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: center;

	svg {
		width: 100%;
		height: 100%;
	}
`;

export const AppContent = styled.div`
	padding: 20px 0;
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
`;

export const MessageContainer = styled.div`
	align-self: stretch;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
`;

export const MessageInner = styled.div`
	flex: 1;
	padding-bottom: 10px;
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
`;

export const MessageHeader = styled.div`
	align-self: stretch;
	height: 22px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
`;

export const MessageText = styled.div`
	flex: 1;
	color: var(--mb-color-light-gray-11);
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	word-wrap: break-word;
`;

export const InfoText = styled.div`
	align-self: stretch;
	color: var(--mb-color-light-gray-9);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-wrap: break-word;
`;

export const AutomationToggle = styled.div`
	width: 591px;
	padding: 10px 12px 10px 8px;
	background: var(--mb-color-light-blue-1);
	border-radius: 12px;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;

export const ToggleText = styled.div`
	flex: 1;
	color: var(--mb-color-light-gray-11);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-wrap: break-word;
`;

export const Divider = styled.div`
	align-self: stretch;
	padding: 10px 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

export const DividerLine = styled.div`
	flex: 1;
	height: 0;
	border: 1px var(--mb-color-light-gray-6) solid;
`;

export const DividerText = styled.div`
	color: var(--mb-color-light-gray-9);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-wrap: break-word;
`;

export const AdminTitle = styled.div`
	color: var(--mb-color-light-gray-11);
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	word-wrap: break-word;
`;
export const AppIcon = styled(Flex)`
	width: 22px;
	height: 22px;
	position: relative;
	border-radius: 1000px;
`;

export const ToggleButton = styled.div<{ $active?: boolean }>`
	width: 44px;
	height: 24px;
	background: ${(props) =>
		props.$active
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-gray-7)"};
	border-radius: 12px;
	padding: 2px;
	cursor: pointer;
	transition: background-color 0.2s ease;
`;

export const ToggleButtonInner = styled.div<{ $active?: boolean }>`
	width: 20px;
	height: 20px;
	background: var(--mb-color-light-gray-1);
	border-radius: 50%;
	transform: translateX(${(props) => (props.$active ? "20px" : "0")});
	transition: transform 0.2s ease;
`;

export const ErrorMessage = styled.div`
	color: var(--mb-color-light-red-4);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	margin-top: 4px;
`;
